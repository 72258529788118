import React, { Component } from 'react';
import { Menu, Dropdown, Icon, Tabs, Button, Card, Avatar, Row, Col, Select, Input } from 'antd';
import pdpa from '../../../../../../assets/images/pdpa.pdf'
import manual from '../../../../../../assets/images/manual.pdf'
import logo from '../../../../../images/hk.png';
import ice from '../../../../../images/logo.png';
import API from '../../../../api'
import { withRouter, Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './index.css'

const { Meta } = Card;

class Partner extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isEditMode: false,
      user: [],
      agents: [],
      agent_states: [],
      selected_state: '',
      search_names: ''
    }
  }

  componentDidMount() {
    const that = this;

    API.get('./api/v1/users/details')
      .then(function (response) {
        console.log(response, '------------user details res');
        that.setState({
          user: response.data,
        })
      })
      .catch(function (error) {
        console.log(error);
      })
      .then(function () {
        // always executed
      });

    API.get('./api/v1/users/agents').then(function(res){
        console.log(res.data)
        that.setState({
          agents: res.data.agents,
          agent_states: [...new Set(res.data.agents.map((agent) => { return agent.state}))]
        })
      });
  }

  action_list(item){
    var list = [];
    if(item.whatsapp_no != ""){
      list.push(<a href={'https://api.whatsapp.com/send?phone='+item.whatsapp_no+'text='}><FontAwesomeIcon fixedWidth icon={['fab', 'whatsapp']} /></a>)
    }
    if(item.phone != ""){
      list.push(<a href={'tel:'+item.phone}><FontAwesomeIcon fixedWidth icon='phone' /></a>)
    }
    if(item.email != ""){
      list.push(<a href={'mailto:'+item.email}><FontAwesomeIcon fixedWidth icon='envelope' /></a>)
    }
    return list
  }

  handleChange = (value) => {
    console.log("select handle change value state", value);
    this.setState({
      selected_state: value === undefined ? '' : value
    });
  }

  handleChangeName = (event) => {
    console.log("select handle change value name", event.target.value);
    this.setState({
      search_names: event.target.value === undefined ? '' : event.target.value.toUpperCase()
    });
  }

  render() {
    // const { currentTab, mobileTab } = this.state;
    const menu = (
      <Menu>
        <Menu.Item disabled={true}>Credit: {this.state.user.credits}</Menu.Item>
        <Menu.Divider></Menu.Divider>
        <Menu.Item><Link to="/bookings/series">My Bookings</Link></Menu.Item>
        {
          homepage_new.suka === 'false' ?
            <Menu.Item><Link to="/users/credit_usage">Credit Usage</Link></Menu.Item>
          : null
        }
        <Menu.Item><a href={homepage_new.pdpa} target="_blank">PDPA</a></Menu.Item>
        <Menu.Item><Link to="/users/profile"><b>Edit Profile</b></Link></Menu.Item>
        <Menu.Item><a href="./user/edit">Change Password</a></Menu.Item>
        <Menu.Item><a href="./users/sign_out" >Logout</a></Menu.Item>
      </Menu>
    );

    return (
      <div id="partner">
        <div className="user">
          <div className="logo_top">
            <Link to="/">
              <div className="logo_warp">
                <img src={homepage_new.app_logo} alt="ice" />
                <span>{homepage_new.suka === 'false' ? homepage_new.app_name : ''}</span>
              </div>
            </Link>
          </div>
          <div className="btn_user">
            {
              is_logged_in ? (
                <Dropdown overlay={menu} trigger={['click']}>
                  <a className="ant-dropdown-link drop_btn" href="#">
                    {this.state.user.username} <Icon type="down" />
                  </a>
                </Dropdown>
              ) : (
                <div className="nav_warp_div">
                </div>
              )
            }
          </div>
          <div className="clear"></div>
        </div>

        <div className="content_user">
          <div>
            <h1 className="title">{is_logged_in ? '' : [ homepage_new.suka === 'false' ? 'Contact Agents' : 'Hubungi Ejen']}</h1>
            <div className="filter">
              <Input onSearch allowClear onChange={this.handleChangeName} type='text' value={this.state.search_names} placeholder={homepage_new.suka === 'false' ? "Search Agents" : "Cari Ejen"} style={{width: '300px', 'margin-right': '10px'}}/>
              {
                this.state.agent_states != [] &&
                (
                  <Select onSearch allowClear onChange={this.handleChange} placeholder={homepage_new.suka === 'false' ? "Select State" : "Pilih Negeri"} style={{width: '300px'}}>
                      {
                        this.state.agent_states.map((state, i) => {
                          return (
                            <Option key={i} value={state}>{state}</Option>
                          ) })
                      }
                    </Select>
                )
              }

            </div>
          </div>
          {
            homepage_new.suka === 'true' ? 
              this.state.agents.filter((agent) => {
                return this.state.selected_state === '' || this.state.selected_state === agent.state
                } 
              ).filter((agent) => {
                return this.state.search_names === '' || agent.full_name.includes(this.state.search_names)
              }).map((agent) => {
                return(
                  <Card
                    // style={{ width: 330 }}
                    className="agent_card"
                    cover={
                      <img className='agent_img'
                        alt={agent.full_name}
                        src={agent.image}
                      />
                    }
                    actions={this.action_list(agent)}
                  >
                    <p className="agent_name">{agent.full_name}</p>
                    <p>
                      <FontAwesomeIcon icon='map-marker-alt' className="address" />&nbsp;&nbsp;{agent.address}<br/>
                      {agent.city}<br/>
                      {agent.state}
                    </p>
                    {
                      agent.phone != "" && <p><FontAwesomeIcon icon='phone' className="phone" />&nbsp;&nbsp;{agent.phone}</p>
                    }
                    {
                      agent.whatsapp_no != "" && <p><FontAwesomeIcon icon={['fab', 'whatsapp']} />&nbsp;&nbsp;{agent.whatsapp_no}</p>
                    }
                    {
                      agent.email != "" && <p><FontAwesomeIcon icon='envelope' className="email" />&nbsp;&nbsp;{agent.email}</p>
                    }
                    
                    
                  </Card>
                )
              })
            : <p>No agents available.</p>
          }
        </div>
      </div>
    );
  }
}

export default withRouter(Partner);
