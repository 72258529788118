import React, { Component } from 'react';
import LeftPanel from './leftPanel';
import RightPanel from './rightPanel';
import MenuBooking from '../menu';
import './index.css'
import API from '../../../../api';
import qs from 'qs';

class DuePayment extends Component {

  constructor(props) {
    super(props);

    this.state = {
      user: { username: '', role: '', credits: 0 },
      booking_info: [],
      booking_ids: [],
      service_fee: 0,
      total: 0,
      payment_type: '',
      credit_disabled: true,
    }

    this.handlePaymentType = this.handlePaymentType.bind(this);
  }

  componentDidMount() {
    this.userFetch();
  }

  userFetch() {
    let url = './api/v1/users/details';
    var that = this;
    // console.log(params);

    API.get(url)
      .then(function (response) {
        that.setState({
          user: { role: response.data.role, username: response.data.username, credits: response.data.credits },
          currentTab: that.state.prevTab,
          isLoading: false,
        })
      })
      .catch(function (error) {
        console.log(error);
      })
      .then(function () {
        // always executed
      }); 
  }

  handlePaymentType(value) {
    this.setState({
      payment_type: value
    })
  }
  
  onClickRemoveData = (event) => {
    // If user clicked on selected data, remove it from state
    let found_booking_info = this.state.booking_info.find(x => x.id === event)
    this.state.booking_info = this.state.booking_info.filter(function(booking_info) { return booking_info !== found_booking_info })
    let updated_ids = this.state.booking_info.map((booking) => booking.key);

    this.setState({
      booking_ids: updated_ids,
      booking_info: this.state.booking_info,
      total: this.state.booking_info.total,
    })
  }

  handleUserCredits = () => {
    if (this.state.user.credits && this.state.user.credits >= this.state.total) {
      this.setState({
        credit_disabled: false
      })
    }
    else {
      this.setState({
        credit_disabled: true
      })
    }
  }

  handlePurchaseInfo = (data) => {
    // console.log(data, "====index data")
    let total = 0;
    let service_fee = 0;
    let booking_data = data !== undefined ? data : [];

    if (booking_data.length > 0) { 
      booking_data.map((booking) => {
        var round_service_fee = (booking.cc_rate * booking.amount).toFixed(2);
        total += booking.amount;
        service_fee += parseFloat(round_service_fee);
      })
    }

    this.setState({
      total: total,
      service_fee: service_fee,
      booking_info: booking_data,
      booking_ids: booking_data.length > 0 ? booking_data.map((booking) => booking.key) : [],
    }, () => { this.handleUserCredits(); })
  }

  render() {
    return (
      <div id="DuePayment">
        <MenuBooking selected="due_payment" />
        <div className="content">
          <div className="warppage">
            <LeftPanel credits={this.state.user.credits} credit_disabled={this.state.credit_disabled} handlePurchaseInfo={this.handlePurchaseInfo} handlePaymentType={this.handlePaymentType} bookingTotal={this.state.total} bookingServiceFee={this.state.service_fee} booking_info={this.state.booking_info} booking_ids={this.state.booking_ids} onClickRemoveData={this.onClickRemoveData} />
            <RightPanel getBookingInfo={this.state.booking_info} bookingTotal={this.state.total} bookingServiceFee={this.state.service_fee} payment_type={this.state.payment_type} onClickRemoveData={this.onClickRemoveData} />
          </div>
        </div>
      </div>

    );
  }
}

export default DuePayment;