import React, { forwardRef } from "react";
import {
  Table,
  Divider,
  Tag,
  Typography,
  Modal,
  Dropdown,
  Button,
  Menu,
  Form,
  Input,
  InputNumber,
  Radio,
  Select,
  DatePicker,
  Upload,
  Icon,
  Checkbox,
  message,
  Tooltip,
  Col,
  Row,
  Statistic,
  Card,
  Skeleton,
  Descriptions,
} from "antd";
import "./index.css";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const { Paragraph } = Typography;
const { Option } = Select;
const dateFormat = "YYYY-MM-DD";

function onChange(value) {
  console.log(`selected ${value}`);
}

export const OrderDetailsTable = React.forwardRef((props, ref) => {
  const { getFieldDecorator } = props.form;
  const documentType =
    props.data.tour_category == "GD Domestic" ? "IC" : "Passport";
  const uploadButton = (
    <p className="ant-upload-drag-icon photo_p">
      <Icon type="inbox" />
      <small>Click or drag file to this area to upload</small>
    </p>
  );
  const flight_columns = [
    {
      title: "Departure",
      dataIndex: "departure",
      key: "departure",
    },
    {
      title: "Arrival",
      dataIndex: "arrival",
      key: "arrival",
    },
    {
      title: "From To",
      dataIndex: "from_to",
      key: "from_to",
    },
    {
      title: "Flight No",
      dataIndex: "flight_no",
      key: "flight_no",
    },
    {
      title: "Internal Remark",
      dataIndex: "internal_remark",
      key: "internal_remark",
    },
  ];

  return (
    <div
      ref={ref}
      className={
        homepage_new.suka === "false" ? "table_order" : "table_order_suka"
      }
    >
      <img src={homepage_new.header_image_path} alt="img" className="table-header" />
      <h2>Order Information</h2>
      <br />
      <Descriptions
        title="Order Details"
        bordered
        column={{ sm: 2, xs: 1 }}
        size={"small"}
      >
        <Descriptions.Item label="Ref No.">
          {props.data.ref_no}
        </Descriptions.Item>
        <Descriptions.Item label="Name">
          {props.data.agent_name}
        </Descriptions.Item>
        <Descriptions.Item label="Tourcode">
          {props.data.code}&nbsp;
          {props.data.guaranteed_indicator === "" ? null : (
            <Tag color={"green"} key={0}>
              {props.data.guaranteed_indicator}
            </Tag>
          )}
        </Descriptions.Item>
        <Descriptions.Item label="Destination">
          {props.data.destination}
        </Descriptions.Item>
        <Descriptions.Item label="Package Name" span={2}>
          {props.data.caption}
        </Descriptions.Item>
        <Descriptions.Item label="Nett Fare">
          {props.data.total_price}
        </Descriptions.Item>
        <Descriptions.Item label="Tour Fare">
          {props.data.selling_price}
        </Descriptions.Item>
        <Descriptions.Item label="Nett Fare Details">
          {props.data.prices_text}
        </Descriptions.Item>
        <Descriptions.Item label="Tour Fare Details">
          {props.data.selling_prices_text}
        </Descriptions.Item>
        <Descriptions.Item label="Add On(s)">
          <span>{props.data.total_balance_revision}</span>
          &nbsp;
          <Tooltip title={props.data.balance_revision_text}>
            <span>
              <FontAwesomeIcon color="#91d5ff" icon="info-circle" />
            </span>
          </Tooltip>
        </Descriptions.Item>
        <Descriptions.Item label="Subtraction">
          <span>{props.data.subtraction_amount}</span>
          &nbsp;
          <Tooltip title={props.data.subtraction_text}>
            <span>
              <FontAwesomeIcon color="#91d5ff" icon="info-circle" />
            </span>
          </Tooltip>
        </Descriptions.Item>
        <Descriptions.Item label="Transaction Fee">
          {props.data.total_service_fee}
        </Descriptions.Item>
        <Descriptions.Item label="Paid Amount">
          {props.data.paid_amount}
        </Descriptions.Item>
        <Descriptions.Item label="Balance Amount">
          {props.data.balance_amount}
        </Descriptions.Item>
        <Descriptions.Item label="Status">
          <Tag
            color={
              props.data.payment_status == "Paid " || props.data.payment_status == 'Paid (Due)'
                ? "green"
                : props.data.payment_status == "Partial Paid " || props.data.payment_status == 'Partial Paid (Due)'
                ? "blue"
                : props.data.payment_status == "Awaiting"
                ? "geekblue"
                : props.data.payment_status == "Pending"
                ? "orange"
                : props.data.payment_status == "Refunded" || props.data.payment_status == "Cancelled"
                ? ""
                : ""
            }
            key={0}
          >
            {props.data.payment_status}
          </Tag>
        </Descriptions.Item>
        <Descriptions.Item label="Sales Person" span={2}>
          {props.data.sub_sale_rep}
        </Descriptions.Item>
      </Descriptions>

      <div className="responsive_sroll">
        {props.data.flights != undefined && props.data.flights.length > 0 ? (
          <div className="booking_modal_table_warp">
            <h3>
              Flight Details{" "}
              <small>
                <i>*Flight schedule subject airlines</i>
              </small>
            </h3>
            <Table
              columns={flight_columns}
              dataSource={props.data.flights}
              pagination={false}
              bordered
              className="table_warp flight"
            />
          </div>
        ) : null}
        <div className="booking_modal_table_warp">
          {/* <h3>Guest(s) Details</h3>
          <Table
            columns={columns_order_information}
            dataSource={props.data.bookings}
            pagination={false}
            expandedRowRender={expandedRowRender}
            // scroll={{ x: 'max-content' }}
            className="table_warp" 
          /> */}
          <Form {...props.formItemLayout} onSubmit={props.handleSubmit}>
            {/************************************* Receiver's email ***************************************/}
            {props.data ? (
              <React.Fragment>
                {props.data.insurance_available == true && (
                  <div className="formItemWarp_top">
                    <Form.Item label="Receiver's email address">
                      {getFieldDecorator(
                        `insurance_receiver_${props.data.id}`,
                        {
                          rules: [
                            {
                              type: "email",
                              message: "The input is not valid E-mail!",
                            },
                            {
                              required: true,
                              message: "Please input your E-mail!",
                            },
                          ],
                          initialValue: props.data.insurance_receiver,
                        }
                      )(
                        <Input
                          placeholder="Insurance Receiver"
                          onChange={(e) =>
                            props.handleBookingGroupDataChange(
                              "insurance_receiver",
                              e.target.value
                            )
                          }
                          disabled={!props.edit_mode}
                        />
                      )}
                    </Form.Item>
                  </div>
                )}
                <div className="clear"></div>
                {props.data.bookings != undefined
                  ? props.data.bookings.map((booking, index) => {
                      {
                        /************************************* Guest ***************************************/
                      }
                      return (
                        <div className="guest">
                          <p className="title">
                            Guest {index + 1}, ref: {booking.id}
                          </p>
                          <p className="line"></p>
                          <div className="input_warp">
                            {/*************************** Price ***************************/}
                            <div className="formItemWarp50">
                              <Form.Item label="Price">
                                <Input
                                  disabled
                                  placeholder="Price"
                                  value={booking.total_price}
                                />
                              </Form.Item>
                            </div>

                            {/*************************** Price ***************************/}
                            <div className="formItemWarp50">
                              <Form.Item label="Price type">
                                {getFieldDecorator("price_type" + booking.id, {
                                  initialValue: booking.price_type,
                                })(
                                  <Select
                                    showSearch
                                    placeholder="Select Price type"
                                    onChange={onChange}
                                    disabled
                                  >
                                    {[
                                      "normal",
                                      "early_bird",
                                      "specialoffer",
                                      "specialdeal",
                                      "superpromo",
                                      "promo",
                                    ].map((Price_type) => {
                                      return (
                                        <Option key={Price_type}>
                                          {Price_type}
                                        </Option>
                                      );
                                    })}
                                  </Select>
                                )}
                              </Form.Item>
                            </div>
                          </div>

                          <div className="input_warp">
                            {/*************************** Category ***************************/}
                            <div className="formItemWarp50">
                              <Form.Item label="Category">
                                {getFieldDecorator("Category" + booking.id, {
                                  initialValue: booking.category,
                                })(
                                  <Select
                                    showSearch
                                    placeholder="Select Category"
                                    onChange={(value) =>
                                      props.handleBookingDataChange(
                                        index,
                                        "category",
                                        value
                                      )
                                    }
                                    disabled={
                                      !props.edit_mode ||
                                      props.data.booked_from !== "iceb2b" ||
                                      booking.insurance_exported
                                    }
                                  >
                                    {[
                                      "adult",
                                      "child_twin",
                                      "child_with_bed",
                                      "child_no_bed",
                                      "single_supplement",
                                    ].map((Category) => {
                                      return (
                                        <Option key={Category} value={Category}>
                                          {Category.replace(/_/g, " ").replace(
                                            /^[a-z]/g,
                                            function (first) {
                                              return first.toUpperCase();
                                            }
                                          )}
                                        </Option>
                                      );
                                    })}
                                  </Select>
                                )}
                              </Form.Item>
                            </div>

                            {/*************************** Designation ***************************/}
                            <div className="formItemWarp50">
                              <Form.Item label="Designation">
                                {getFieldDecorator("designation" + booking.id, {
                                  initialValue: booking.designation,
                                })(
                                  <Select
                                    showSearch
                                    onChange={(value) =>
                                      props.handleBookingDataChange(
                                        index,
                                        "designation",
                                        value
                                      )
                                    }
                                    placeholder="Select"
                                    disabled={
                                      !props.edit_mode ||
                                      booking.insurance_exported
                                    }
                                  >
                                    {["Mr", "Miss", "Ms", "Mstr"].map(
                                      (designation) => {
                                        return (
                                          <Option key={designation}>
                                            {designation}
                                          </Option>
                                        );
                                      }
                                    )}
                                  </Select>
                                )}
                              </Form.Item>
                            </div>
                          </div>

                          <div className="input_warp">
                            {/*************************** Full name ***************************/}
                            <div className="formItemWarp50">
                              <Form.Item label="Full name">
                                {getFieldDecorator("name" + booking.id, {
                                  initialValue: booking.name,
                                })(
                                  <Input
                                    onChange={(e) =>
                                      props.handleBookingDataChange(
                                        index,
                                        "name",
                                        e.target.value
                                      )
                                    }
                                    placeholder="Full name"
                                    disabled={
                                      !props.edit_mode ||
                                      booking.insurance_exported
                                    }
                                  />
                                )}
                              </Form.Item>
                            </div>

                            {/*************************** Date of birth ***************************/}
                            <div className="formItemWarp50 date_sty">
                              <Form.Item label="Date of birth">
                                {getFieldDecorator("Date_birth" + booking.id, {
                                  initialValue:
                                    booking.date_of_birth !== ""
                                      ? moment(booking.date_of_birth)
                                      : "",
                                })(
                                  <DatePicker
                                    onChange={(date, dateString) =>
                                      props.handleBookingDataChange(
                                        index,
                                        "date_of_birth",
                                        dateString
                                      )
                                    }
                                    disabledDate={(current) => {
                                      return (
                                        current >
                                          moment()
                                            .add(-2, "year")
                                            .startOf("day") ||
                                        current <
                                          moment()
                                            .add(-100, "year")
                                            .startOf("day") ||
                                        current >
                                          moment(
                                            props.data && props.data.departure,
                                            "DD/MM/YYYY"
                                          ).toDate()
                                      );
                                    }}
                                    disabled={
                                      !props.edit_mode ||
                                      booking.insurance_exported
                                    }
                                  />
                                )}
                              </Form.Item>
                            </div>
                          </div>
                          {/** 13 June 2024 commented out by Stefan, to be reopened once RSVN ready */
                          /** ===================================================== */}
                          {/* <div className="input_warp">
                            {/*************************** NRIC ***************************/}
                          {/* <div className="formItemWarp50">
                              <Form.Item label="Is Malaysian?">
                                {getFieldDecorator(
                                  "is_malaysian" + booking.id,
                                  {
                                    rules: [
                                      {
                                        required: true,
                                      },
                                    ],
                                    initialValue:
                                      booking.nric != "" ? true : false,
                                  }
                                )(
                                  <Select
                                    disabled={
                                      !props.edit_mode ||
                                      booking.insurance_exported
                                    }
                                    initialValue={false}
                                    onChange={(value) =>
                                      props.handleBookingDataChange(
                                        index,
                                        "is_malaysian",
                                        value
                                      )
                                    }
                                  >
                                    <Option value={false}>Not Malaysian</Option>
                                    <Option value={true}>Malaysian</Option>
                                  </Select>
                                )}
                              </Form.Item>
                            </div>
                            <div className="formItemWarp50">
                              <Form.Item label="NRIC">
                                {getFieldDecorator("nric" + booking.id, {
                                  rules: [
                                    {
                                      required: booking.is_malaysian,
                                      message: "Please fill in your NRIC",
                                    },
                                  ],
                                  initialValue: booking.nric,
                                })(
                                  <div>
                                    <Input
                                      value={booking.nric}
                                      maxLength={12}
                                      disabled={
                                        !props.edit_mode ||
                                        booking.insurance_exported ||
                                        !booking.is_malaysian
                                      }
                                      setFieldsValue={booking.nric}
                                      onChange={(e) =>
                                        props.handleBookingDataChange(
                                          index,
                                          "nric",
                                          e.target.value
                                        )
                                      }
                                      placeholder="XXXXXXXXXXXXX 12 digits"
                                    />
                                  </div>
                                )}
                              </Form.Item>
                            </div>

                            
                          </div> */}
                          {/** ===================================================== */}
                          <div className="input_warp">
                            {/*************************** Mobile ***************************/}
                            <div className="formItemWarp50">
                              <Form.Item label="Mobile">
                                {getFieldDecorator("Mobile" + booking.id, {
                                  initialValue: booking.mobile,
                                })(
                                  <Input
                                    onChange={(e) =>
                                      props.handleBookingDataChange(
                                        index,
                                        "mobile",
                                        e.target.value
                                      )
                                    }
                                    placeholder="Mobile"
                                    disabled={
                                      !props.edit_mode ||
                                      booking.insurance_exported
                                    }
                                  />
                                )}
                              </Form.Item>
                            </div>

                            {/*************************** Passport number ***************************/}
                            <div className="formItemWarp50">
                              <Form.Item label={documentType + " number"}>
                                {getFieldDecorator(
                                  "passport_number" + booking.id,
                                  {
                                    initialValue: booking.passport_number,
                                  }
                                )(
                                  <Input
                                    onChange={(e) =>
                                      props.handleBookingDataChange(
                                        index,
                                        "passport_number",
                                        e.target.value
                                      )
                                    }
                                    placeholder={documentType + " number"}
                                    disabled={
                                      !props.edit_mode ||
                                      booking.insurance_exported
                                    }
                                  />
                                )}
                              </Form.Item>
                            </div>
                          </div>

                          <div className="input_warp">
                            {/*************************** Passport expiry date ***************************/}
                            {documentType == "Passport" ? (
                              <div className="formItemWarp50 date_sty">
                                <Form.Item label="Passport expiry date">
                                  {getFieldDecorator(
                                    "passport_expiry_date" + booking.id,
                                    {
                                      initialValue:
                                        booking.passport_expiry_date !== ""
                                          ? moment(booking.passport_expiry_date)
                                          : "",
                                      //rules: [
                                      //  {
                                      //    required: booking.passport_expiry_required,
                                      //    message: 'Please select your expiry date',
                                      //  },
                                      //],
                                    }
                                  )(
                                    <DatePicker
                                      onChange={(date, dateString) =>
                                        props.handleBookingDataChange(
                                          index,
                                          "passport_expiry_date",
                                          dateString
                                        )
                                      }
                                      disabled={
                                        !props.edit_mode ||
                                        booking.insurance_exported
                                      }
                                    />
                                  )}
                                </Form.Item>
                              </div>
                            ) : (
                              ""
                            )}

                            {/*************************** Passport photocopy ***************************/}
                            <div className="formItemWarp50 photo_upload">
                              <Form.Item label={<div>{documentType} photocopy <label style={{ color: "red" }}>(format allowed: .jpg .jpeg .gif .png .pdf .jfif)</label></div>}>
                                {getFieldDecorator(
                                  "passport_photocopy" + booking.id,
                                  {
                                    valuePropName: "fileList",
                                    getValueFromEvent: props.normFile,
                                  }
                                )(
                                  <Upload.Dragger
                                    // action="/upload.do"
                                    beforeUpload={props.beforeUpload}
                                    onChange={(value) =>
                                      props.handleBookingUploadDataChange(
                                        index,
                                        "passport_photocopy",
                                        value.fileList[0].originFileObj
                                      )
                                    }
                                    disabled={
                                      !props.edit_mode ||
                                      booking.insurance_exported
                                    }
                                  >
                                    {booking.passport_photocopy_image !== "" ? (
                                      booking.passport_photocopy_image.includes(
                                        "pdf"
                                      ) ? (
                                        <a
                                          href={
                                            booking.passport_photocopy_image
                                          }
                                          target="_blank"
                                        >
                                          View passport
                                        </a>
                                      ) : (
                                        <a
                                          href={
                                            booking.passport_photocopy_image
                                          }
                                          target="_blank"
                                        >
                                          <img
                                            className="image-display-photocopy"
                                            src={
                                              booking.passport_photocopy_image
                                            }
                                            alt="avatar"
                                          />
                                        </a>
                                      )
                                    ) : (
                                      uploadButton
                                    )}
                                  </Upload.Dragger>
                                )}
                              </Form.Item>
                            </div>
                          </div>

                          <div className="input_warp">
                            {/*************************** Room type ***************************/}
                            {/*<div className="formItemWarp50">
                              <Form.Item label="Room type">
                                {getFieldDecorator('room_type' + booking.id, {
                                  initialValue: booking.room_type,
                                  rules: [{ required: true, message: '*Please select the Room', }],
                                })(
                                  <Select showSearch placeholder="Select Room type" onChange={(value) => props.handleBookingDataChange(index, 'room_type', value)} disabled={!props.edit_mode}>
                                    {
                                      ['Single', 'Double Bed', 'Triple Sharing', 'Twin Sharing', 'Child No Bed', 'Child With Bed', 'Child Twin Share'].map((Room_type) => {
                                        return (
                                          <Option key={Room_type} value={Room_type}>{Room_type}</Option>
                                        )
                                      })
                                    }
                                  </Select>
                                )}
                              </Form.Item>
                            </div>*/}

                            {/*************************** Agent remark ***************************/}
                            <div className="formItemWarp50">
                              <Form.Item label="Agent remark">
                                {getFieldDecorator("remark" + booking.id, {
                                  initialValue: booking.remark,
                                })(
                                  <Input
                                    placeholder="Agent remark"
                                    onChange={(e) =>
                                      props.handleBookingDataChange(
                                        index,
                                        "remark",
                                        e.target.value
                                      )
                                    }
                                    disabled={
                                      !props.edit_mode ||
                                      booking.insurance_exported
                                    }
                                  />
                                )}
                              </Form.Item>
                            </div>

                            {/*************************** Price ***************************/}
                            <div className="formItemWarp50">
                              <Form.Item
                                label={
                                  homepage_new.suka === "false"
                                    ? "GD remark"
                                    : "Remark"
                                }
                              >
                                <Input disabled value={booking.admin_remark} />
                              </Form.Item>
                            </div>
                          </div>
                          <div className="input_warp">
                            <div className="formItemWarp50">
                              <Form.Item label="Rooming remark">
                                {getFieldDecorator(
                                  "operator_remark" + booking.id,
                                  {
                                    initialValue: booking.operator_remark,
                                  }
                                )(
                                  <Input
                                    placeholder="Rooming remark"
                                    onChange={(e) =>
                                      props.handleBookingDataChange(
                                        index,
                                        "operator_remark",
                                        e.target.value
                                      )
                                    }
                                    disabled={
                                      !props.edit_mode ||
                                      booking.insurance_exported
                                    }
                                  />
                                )}
                              </Form.Item>
                            </div>
                            <div className="formItemWarp50">
                              <Form.Item label="Insurance Certificate">
                                {booking.insurance_cert !== "" ? (
                                  <a
                                    href={booking.insurance_cert}
                                    target="_blank"
                                  >
                                    {booking.policy_number}
                                  </a>
                                ) : null}
                              </Form.Item>
                            </div>
                          </div>
                          {booking.insurance_available == false ? null : (
                            <div className="insuran_detail">
                              {/**************************** Free Chubb Travel Insurance **************************************/}
                              <div className="free_chubb">
                                <h3 className="title2">
                                  Free Chubb Travel Insurance
                                </h3>
                                <p>
                                  Our packages bundle up with Chubb Executive
                                  travel insurance that is underwritten by Chubb
                                  Insurance Malaysia Berhad. Please choose your
                                  option below.
                                </p>
                                <p>
                                  Click{" "}
                                  <a
                                    href={homepage_new.chubb_terms}
                                    target="_blank"
                                  >
                                    here
                                  </a>{" "}
                                  to view the benefits and policy wording
                                </p>
                                <p>
                                  Click{" "}
                                  <a
                                    href={homepage_new.chubb_terms_add1}
                                    target="_blank"
                                  >
                                    here
                                  </a>{" "}
                                  to view endorsement 1
                                </p>
                                <p>
                                  Click{" "}
                                  <a
                                    href={homepage_new.chubb_terms_add2}
                                    target="_blank"
                                  >
                                    here
                                  </a>{" "}
                                  to view endorsement 2
                                </p>
                                {getFieldDecorator(
                                  "insurance_confirmation" + booking.id,
                                  {
                                    initialValue:
                                      booking.insurance_confirmation,
                                  }
                                )(
                                  <Radio.Group
                                    onChange={(e) =>
                                      props.handleBookingDataChange(
                                        index,
                                        "insurance_confirmation",
                                        e.target.value
                                      )
                                    }
                                    disabled={
                                      !props.edit_mode ||
                                      booking.insurance_exported
                                    }
                                  >
                                    <Radio value={true}>
                                      Yes, please include with no additional
                                      charge
                                    </Radio>
                                    <Radio value={false}>
                                      No, I don't want free insurance and
                                      understand the cost remain the same
                                    </Radio>
                                  </Radio.Group>
                                )}
                                {booking.insurance_confirmation == true ? (
                                  <small className="notice_insuran">
                                    "Certificate will only be issued to the
                                    customer after clicking the Confirm
                                    Insurance"
                                  </small>
                                ) : null}
                              </div>

                              {/************************************** Nationality *****************************************/}
                              {booking.nationality_visible == true ? (
                                <div className="free_chubb national">
                                  <h3 className="title2">Nationality</h3>
                                  {getFieldDecorator(
                                    "nationality" + booking.id,
                                    {
                                      initialValue: booking.nationality,
                                    }
                                  )(
                                    <Radio.Group
                                      onChange={(e) =>
                                        props.handleBookingDataChange(
                                          index,
                                          "nationality",
                                          e.target.value
                                        )
                                      }
                                      disabled={
                                        !props.edit_mode ||
                                        booking.insurance_exported
                                      }
                                    >
                                      <Radio
                                        value="malaysian"
                                        className="label_1"
                                      >
                                        I am a Malaysian, Malaysian Permanent
                                        Resident or holder of a valid working
                                        permit in Malaysia, dependent pass, long
                                        term social visit pass or student pass,
                                        and will commence journey from Malaysia.
                                      </Radio>
                                      <Radio value="non-malaysian">
                                        No. Sorry, you are not eligible for this
                                        cover.
                                      </Radio>
                                    </Radio.Group>
                                  )}
                                </div>
                              ) : null}

                              <div className="note">
                                {getFieldDecorator(
                                  "insurance_nomination_flag" + booking.id,
                                  {}
                                )(
                                  <Checkbox
                                    onChange={(e) =>
                                      props.handleBookingDataChange(
                                        index,
                                        "insurance_nomination_flag",
                                        e.target.checked
                                      )
                                    }
                                    defaultChecked={
                                      booking.insurance_nomination_flag !==
                                        "" || 0
                                        ? booking.insurance_nomination_flag
                                        : true
                                    }
                                    disabled={
                                      !props.edit_mode ||
                                      booking.insurance_exported
                                    }
                                  >
                                    Note: Death benefit will be paid to insured
                                    Person's estate. Please uncheck if the
                                    insured person wishes to make a
                                    NOMINEE/NOMINEES.
                                  </Checkbox>
                                )}
                              </div>

                              {/*************************** Guardian ***************************/}
                              {booking.over_18 == false ? (
                                <div className="input_warp">
                                  <div className="formItemWarp50">
                                    <Form.Item label="Select Guardian">
                                      {getFieldDecorator(
                                        "guardian_id" + booking.id,
                                        {
                                          initialValue: booking.guardian_id,
                                        }
                                      )(
                                        <Select
                                          showSearch
                                          onChange={(value) =>
                                            props.handleBookingDataChange(
                                              index,
                                              "guardian_id",
                                              value
                                            )
                                          }
                                          placeholder="Select"
                                          disabled={
                                            !props.edit_mode ||
                                            booking.insurance_exported
                                          }
                                        >
                                          {booking.guardian_list.map(
                                            (guardian) => {
                                              return (
                                                <Option
                                                  key={guardian[1]}
                                                  value={guardian[1]}
                                                >
                                                  {guardian[0]}
                                                </Option>
                                              );
                                            }
                                          )}
                                        </Select>
                                      )}
                                    </Form.Item>
                                  </div>
                                </div>
                              ) : null}

                              {booking.over_18 == false &&
                              booking.guardian_id != null &&
                              booking.guardian_id == 0 ? (
                                <div className="guardian">
                                  {/******************************* Guardian ************************************/}
                                  <h4 className="title3">Guardian</h4>
                                  <div className="input_warp">
                                    {/*************************** Designation ***************************/}
                                    <div className="formItemWarp50 ">
                                      <Form.Item label="Designation">
                                        {getFieldDecorator(
                                          booking.id +
                                            "guardian_designation" +
                                            index,
                                          {
                                            initialValue:
                                              booking.ph_designation,
                                          }
                                        )(
                                          <Select
                                            showSearch
                                            onChange={(value) =>
                                              props.handleBookingDataChange(
                                                index,
                                                "ph_designation",
                                                value
                                              )
                                            }
                                            placeholder="Select"
                                            disabled={
                                              !props.edit_mode ||
                                              booking.insurance_exported
                                            }
                                          >
                                            {[
                                              "Mr",
                                              "Mrs",
                                              "Ms",
                                              "Mstr",
                                              "Mdm",
                                            ].map((designation) => {
                                              return (
                                                <Option
                                                  key={designation}
                                                  value={designation}
                                                >
                                                  {designation}
                                                </Option>
                                              );
                                            })}
                                          </Select>
                                        )}
                                      </Form.Item>
                                    </div>

                                    {/*************************** Full Name ***************************/}
                                    <div className="formItemWarp50 ">
                                      <Form.Item label="Full Name">
                                        {getFieldDecorator(
                                          booking.id + "guardian_name" + index,
                                          {
                                            initialValue: booking.ph_name,
                                          }
                                        )(
                                          <Input
                                            onChange={(e) =>
                                              props.handleBookingDataChange(
                                                index,
                                                "ph_name",
                                                e.target.value
                                              )
                                            }
                                            placeholder="Full name"
                                            disabled={
                                              !props.edit_mode ||
                                              booking.insurance_exported
                                            }
                                          />
                                        )}
                                      </Form.Item>
                                    </div>
                                  </div>

                                  <div className="input_warp">
                                    {/*************************** Date of Birth ***************************/}
                                    <div className="formItemWarp50 date_sty">
                                      <Form.Item label="Date of Birth">
                                        {getFieldDecorator(
                                          booking.id + "guardian_dob" + index,
                                          {
                                            initialValue:
                                              booking.ph_dob !== ""
                                                ? moment(booking.ph_dob)
                                                : "",
                                          }
                                        )(
                                          <DatePicker
                                            onChange={(date, dateString) =>
                                              props.handleBookingDataChange(
                                                index,
                                                "ph_dob",
                                                dateString
                                              )
                                            }
                                            disabled={
                                              !props.edit_mode ||
                                              booking.insurance_exported
                                            }
                                          />
                                        )}
                                      </Form.Item>
                                    </div>

                                    {/*************************** Passport Number ***************************/}
                                    <div className="formItemWarp50 ">
                                      <Form.Item label="Passport Number">
                                        {getFieldDecorator(
                                          booking.id + "guardian_id_no" + index,
                                          {
                                            initialValue: booking.ph_id_number,
                                          }
                                        )(
                                          <Input
                                            onChange={(e) =>
                                              props.handleBookingDataChange(
                                                index,
                                                "ph_id_number",
                                                e.target.value
                                              )
                                            }
                                            placeholder="Passport Number"
                                            disabled={
                                              !props.edit_mode ||
                                              booking.insurance_exported
                                            }
                                          />
                                        )}
                                      </Form.Item>
                                    </div>
                                  </div>
                                </div>
                              ) : null}

                              {/******************************** Nominee Details  *******************************************/}
                              {booking.insurance_nomination_flag == false ? (
                                /********************************* nominee 1 **********************************************/
                                <div>
                                  {booking.insurance_nomination.map(
                                    (insurance, nominee_index) => {
                                      return (
                                        <div className="nominee">
                                          <h4 className="title3">
                                            Nominee Details {nominee_index + 1}
                                          </h4>
                                          <div className="input_warp">
                                            {/*************************** Surname ***************************/}
                                            <div className="formItemWarp33 ">
                                              <Form.Item label="Surname">
                                                {getFieldDecorator(
                                                  booking.id +
                                                    "nominee_surname" +
                                                    nominee_index,
                                                  {
                                                    // rules: [
                                                    //   {
                                                    //     required: true,
                                                    //     message: 'Please input your Surname!',
                                                    //   },
                                                    // ],
                                                    initialValue:
                                                      insurance.nominee_surname,
                                                  }
                                                )(
                                                  <Input
                                                    placeholder="Surname"
                                                    onChange={(e) =>
                                                      props.handleBookingInsuranceDataChange(
                                                        index,
                                                        nominee_index,
                                                        "nominee_surname",
                                                        e.target.value
                                                      )
                                                    }
                                                    disabled={
                                                      !props.edit_mode ||
                                                      booking.insurance_exported
                                                    }
                                                  />
                                                )}
                                              </Form.Item>
                                            </div>

                                            {/*************************** Given Name ***************************/}
                                            <div className="formItemWarp33 ">
                                              <Form.Item label="Given Name">
                                                {getFieldDecorator(
                                                  booking.id +
                                                    "nominee_given_name" +
                                                    nominee_index,
                                                  {
                                                    // rules: [
                                                    //   {
                                                    //     required: true,
                                                    //     message: 'Please input your Given Name!',
                                                    //   },
                                                    // ],
                                                    initialValue:
                                                      insurance.nominee_given_name,
                                                  }
                                                )(
                                                  <Input
                                                    placeholder="Given Name"
                                                    onChange={(e) =>
                                                      props.handleBookingInsuranceDataChange(
                                                        index,
                                                        nominee_index,
                                                        "nominee_given_name",
                                                        e.target.value
                                                      )
                                                    }
                                                    disabled={
                                                      !props.edit_mode ||
                                                      booking.insurance_exported
                                                    }
                                                  />
                                                )}
                                              </Form.Item>
                                            </div>

                                            {/*************************** Passport/NRIC Number ***************************/}
                                            <div className="formItemWarp33 ">
                                              <Form.Item label="Passport/NRIC Number">
                                                {getFieldDecorator(
                                                  booking.id +
                                                    "nominee_passport_number" +
                                                    nominee_index,
                                                  {
                                                    // rules: [
                                                    //   {
                                                    //     required: true,
                                                    //     message: 'Please input your Passport/NRIC Number!',
                                                    //   },
                                                    // ],
                                                    initialValue:
                                                      insurance.nominee_passport_number,
                                                  }
                                                )(
                                                  <Input
                                                    placeholder="Passport/NRIC Number"
                                                    onChange={(e) =>
                                                      props.handleBookingInsuranceDataChange(
                                                        index,
                                                        nominee_index,
                                                        "nominee_passport_number",
                                                        e.target.value
                                                      )
                                                    }
                                                    disabled={
                                                      !props.edit_mode ||
                                                      booking.insurance_exported
                                                    }
                                                  />
                                                )}
                                              </Form.Item>
                                            </div>
                                          </div>
                                          <div className="input_warp">
                                            {/*************************** Date of Birth ***************************/}
                                            <div className="formItemWarp33 date_sty">
                                              <Form.Item label="Date of Birth">
                                                {getFieldDecorator(
                                                  booking.id +
                                                    "nominee_date_of_birth" +
                                                    nominee_index,
                                                  {
                                                    // rules: [
                                                    //   {
                                                    //     required: true,
                                                    //     message: 'Please select your Date of Birth!',
                                                    //   },
                                                    // ],
                                                    initialValue:
                                                      insurance.nominee_date_of_birth !==
                                                      ""
                                                        ? moment(
                                                            insurance.nominee_date_of_birth
                                                          )
                                                        : "",
                                                  }
                                                )(
                                                  <DatePicker
                                                    onChange={(
                                                      date,
                                                      dateString
                                                    ) =>
                                                      props.handleBookingInsuranceDataChange(
                                                        index,
                                                        nominee_index,
                                                        "nominee_date_of_birth",
                                                        dateString
                                                      )
                                                    }
                                                    disabled={
                                                      !props.edit_mode ||
                                                      booking.insurance_exported
                                                    }
                                                  />
                                                )}
                                              </Form.Item>
                                            </div>

                                            {/*************************** Relationship ***************************/}
                                            <div className="formItemWarp33 ">
                                              <Form.Item label="Relationship">
                                                {getFieldDecorator(
                                                  booking.id +
                                                    "nominee_relationship" +
                                                    nominee_index,
                                                  {
                                                    // rules: [
                                                    //   {
                                                    //     required: true,
                                                    //     message: 'Please select your Relationship!',
                                                    //   },
                                                    // ],
                                                    initialValue:
                                                      insurance.nominee_relationship,
                                                  }
                                                )(
                                                  <Select
                                                    showSearch
                                                    placeholder="Select"
                                                    onChange={(value) =>
                                                      props.handleBookingInsuranceDataChange(
                                                        index,
                                                        nominee_index,
                                                        "nominee_relationship",
                                                        value
                                                      )
                                                    }
                                                    disabled={
                                                      !props.edit_mode ||
                                                      booking.insurance_exported
                                                    }
                                                  >
                                                    {[
                                                      "Spouse",
                                                      "Father",
                                                      "Mother",
                                                      "Brother",
                                                      "Daughter",
                                                      "Sister",
                                                      "Son",
                                                      "Grandmother",
                                                      "Grandfather",
                                                      "Grandson",
                                                      "Granddaughter",
                                                      "Friend",
                                                      "Others",
                                                    ].map((Relationship) => {
                                                      return (
                                                        <Option
                                                          key={Relationship}
                                                          value={Relationship}
                                                        >
                                                          {Relationship}
                                                        </Option>
                                                      );
                                                    })}
                                                  </Select>
                                                )}
                                              </Form.Item>
                                            </div>

                                            {/*************************** Share ***************************/}
                                            <div className="formItemWarp33 ">
                                              <Form.Item label="Share">
                                                {getFieldDecorator(
                                                  booking.id +
                                                    "nominee_share" +
                                                    nominee_index,
                                                  {
                                                    // rules: [
                                                    //   {
                                                    //     required: true,
                                                    //     message: 'Please select your Share!',
                                                    //   },
                                                    // ],
                                                    initialValue:
                                                      insurance.nominee_share,
                                                  }
                                                )(
                                                  <Select
                                                    showSearch
                                                    placeholder="Select"
                                                    onChange={(value) =>
                                                      props.handleBookingInsuranceDataChange(
                                                        index,
                                                        nominee_index,
                                                        "nominee_share",
                                                        value
                                                      )
                                                    }
                                                    disabled={
                                                      !props.edit_mode ||
                                                      booking.insurance_exported
                                                    }
                                                  >
                                                    {[
                                                      "0",
                                                      "25",
                                                      "50",
                                                      "75",
                                                      "100",
                                                    ].map((Share) => {
                                                      return (
                                                        <Option
                                                          key={Share}
                                                          value={Share}
                                                        >
                                                          {Share}
                                                        </Option>
                                                      );
                                                    })}
                                                  </Select>
                                                )}
                                              </Form.Item>
                                            </div>
                                          </div>
                                        </div>
                                      );
                                    }
                                  )}

                                  {/*************************** Witness Details *******************************************/}
                                  <div className="Witness_Details">
                                    <h4 className="title3">Witness Details</h4>
                                    <div className="input_warp">
                                      {/*************************** Surname ***************************/}
                                      <div className="formItemWarp33 ">
                                        <Form.Item label="Surname">
                                          {getFieldDecorator(
                                            "witness_surname" + booking.id,
                                            {
                                              rules: [
                                                {
                                                  required: true,
                                                  message:
                                                    "Please input your Surname!",
                                                },
                                              ],
                                              initialValue:
                                                booking.witness_surname,
                                            }
                                          )(
                                            <Input
                                              placeholder="Surname"
                                              onChange={(e) =>
                                                props.handleBookingDataChange(
                                                  index,
                                                  "witness_surname",
                                                  e.target.value
                                                )
                                              }
                                              disabled={
                                                !props.edit_mode ||
                                                booking.insurance_exported
                                              }
                                            />
                                          )}
                                        </Form.Item>
                                      </div>
                                      {/*************************** Given Name ***************************/}
                                      <div className="formItemWarp33 ">
                                        <Form.Item label="Given Name">
                                          {getFieldDecorator(
                                            "witness_given_name" + booking.id,
                                            {
                                              rules: [
                                                {
                                                  required: true,
                                                  message:
                                                    "Please input your Given Name!",
                                                },
                                              ],
                                              initialValue:
                                                booking.witness_given_name,
                                            }
                                          )(
                                            <Input
                                              placeholder="Given Name"
                                              onChange={(e) =>
                                                props.handleBookingDataChange(
                                                  index,
                                                  "witness_given_name",
                                                  e.target.value
                                                )
                                              }
                                              disabled={
                                                !props.edit_mode ||
                                                booking.insurance_exported
                                              }
                                            />
                                          )}
                                        </Form.Item>
                                      </div>

                                      {/*************************** Passport/NRIC Number ***************************/}
                                      <div className="formItemWarp33 ">
                                        <Form.Item label="Passport/NRIC Number">
                                          {getFieldDecorator(
                                            "witness_passport_number" +
                                              booking.id,
                                            {
                                              rules: [
                                                {
                                                  required: true,
                                                  message:
                                                    "Please input your Passport/NRIC Number!",
                                                },
                                              ],
                                              initialValue:
                                                booking.witness_passport_number,
                                            }
                                          )(
                                            <Input
                                              placeholder="Passport/NRIC Number"
                                              onChange={(e) =>
                                                props.handleBookingDataChange(
                                                  index,
                                                  "witness_passport_number",
                                                  e.target.value
                                                )
                                              }
                                              disabled={
                                                !props.edit_mode ||
                                                booking.insurance_exported
                                              }
                                            />
                                          )}
                                        </Form.Item>
                                      </div>
                                    </div>
                                  </div>

                                  {/***************************** notice_infor *******************************************/}
                                  <div className="notice_infor">
                                    <small>
                                      By typing in my name and Passport/NRIC
                                      No., I confirm that:
                                      <ol>
                                        <li>
                                          I am of sound mind and have attained
                                          the age of 18 years old;
                                        </li>
                                        <li>
                                          I am not a nominee named by the
                                          Insured Person(s);
                                        </li>
                                        <li>
                                          I am the witness to the nomination(s)
                                          made, as the nomination(s) was(were)
                                          made to me by the respective Insured
                                          Person(s); and
                                        </li>
                                        <li>
                                          I have informed the Insured Person(s)
                                          that if the nomination does not create
                                          a trust policy, and he/she intends
                                          his/her nominee(s) to receive the
                                          policy benefits beneficially and not
                                          as an executor, he/she has to assign
                                          the policy benefits to his/her
                                          nominee(s).
                                        </li>
                                      </ol>
                                    </small>
                                  </div>
                                </div>
                              ) : null}
                            </div>
                          )}
                          {/*
                            homepage_new.suka === 'true' &&
                            <div className="insuran_detail">
                              <div className="free_chubb">
                                <h3 className="title2">Travel Insurance</h3>
                                <p>
                                  Travel insurance is mandatory as advised by Malaysian Travel & Tour Association (MATTA). Suka Travel & Tours strongly advise all Tour Members to purchase their travel insurance coverage for the duration of their tour. Our employees would be more than happy to assist in offering the available plans for your selection.
                                </p>
                                {
                                  getFieldDecorator('insurance_confirmation' + booking.id, {
                                    initialValue: booking.insurance_confirmation,
                                  })(
                                  <Radio.Group onChange={(e) => props.handleBookingDataChange(index, 'insurance_confirmation', e.target.value)} disabled={!props.edit_mode}>
                                    <Radio value={true}>Yes, we would like to buy the travel insurance from Suka Travel.</Radio>
                                    <Radio value={false}>No, we will buy the travel insurance ourselves and indemnify Suka Travel & Tours for any costs incurred by them due to our failure to take out adequate insurance cover.</Radio>
                                  </Radio.Group>
                                  )
                                }
                              </div>
                            </div>
                            */}
                          <div className="clear"></div>

                          {booking.infant_bookings_attributes.length > 0 ? (
                            <div>
                              {booking.infant_bookings_attributes.map(
                                (infant, infant_index) => {
                                  return (
                                    <div className="guest">
                                      <p className="title">
                                        Infant {infant_index + 1}, ref:{" "}
                                        {infant.id}
                                      </p>
                                      <p className="line"></p>
                                      <div className="input_warp">
                                        {/*************************** Price ***************************/}
                                        <div className="formItemWarp50">
                                          <Form.Item label="Price">
                                            <Input
                                              disabled
                                              placeholder="Price"
                                              value={infant.total_price}
                                            />
                                          </Form.Item>
                                        </div>

                                        {/*************************** Price ***************************/}
                                        <div className="formItemWarp50">
                                          <Form.Item label="Price type">
                                            {getFieldDecorator(
                                              booking.id +
                                                "price_type" +
                                                infant_index,
                                              {
                                                initialValue:
                                                  booking.price_type,
                                              }
                                            )(
                                              <Select
                                                showSearch
                                                placeholder="Select Price type"
                                                onChange={onChange}
                                                disabled
                                              >
                                                {[
                                                  "normal",
                                                  "early_bird",
                                                  "specialoffer",
                                                  "specialdeal",
                                                  "superpromo",
                                                  "promo",
                                                ].map((Price_type) => {
                                                  return (
                                                    <Option key={Price_type}>
                                                      {Price_type}
                                                    </Option>
                                                  );
                                                })}
                                              </Select>
                                            )}
                                          </Form.Item>
                                        </div>
                                      </div>

                                      <div className="input_warp">
                                        {/*************************** Full name ***************************/}
                                        <div className="formItemWarp50">
                                          <Form.Item label="Full name">
                                            {getFieldDecorator(
                                              booking.id +
                                                "name" +
                                                infant_index,
                                              {
                                                initialValue: infant.name,
                                              }
                                            )(
                                              <Input
                                                onChange={(e) =>
                                                  props.handleInfantDataChange(
                                                    index,
                                                    infant_index,
                                                    "name",
                                                    e.target.value
                                                  )
                                                }
                                                placeholder="Full name"
                                                disabled={
                                                  !props.edit_mode ||
                                                  infant.insurance_exported
                                                }
                                              />
                                            )}
                                          </Form.Item>
                                        </div>

                                        {/*************************** Date of birth ***************************/}
                                        <div className="formItemWarp50 date_sty">
                                          <Form.Item label="Date of birth">
                                            {getFieldDecorator(
                                              booking.id +
                                                "Date_birth" +
                                                infant_index,
                                              {
                                                initialValue:
                                                  infant.date_of_birth !== ""
                                                    ? moment(
                                                        infant.date_of_birth
                                                      )
                                                    : "",
                                              }
                                            )(
                                              <DatePicker
                                                onChange={(date, dateString) =>
                                                  props.handleInfantDataChange(
                                                    index,
                                                    infant_index,
                                                    "date_of_birth",
                                                    dateString
                                                  )
                                                }
                                                disabledDate={(current) => {
                                                  return (
                                                    current >
                                                      moment().startOf("day") ||
                                                    current <
                                                      moment()
                                                        .add(-2, "year")
                                                        .startOf("day") ||
                                                    current >
                                                      moment(
                                                        props.data &&
                                                          props.data.departure,
                                                        "DD/MM/YYYY"
                                                      ).toDate()
                                                  );
                                                }}
                                                disabled={
                                                  !props.edit_mode ||
                                                  infant.insurance_exported
                                                }
                                              />
                                            )}
                                          </Form.Item>
                                        </div>
                                      </div>
                                      {/** 13 June 2024 commented out by Stefan, to be reopened once RSVN ready */
                                      /** ===================================================== */}
                                      {/* <div className="input_warp">*/}
                                      {/*************************** NRIC ***************************/}
                                      {/* <div className="formItemWarp50">
                                          <Form.Item label="Is Malaysian?">
                                            {getFieldDecorator(
                                              booking.id +
                                                "is_malaysian" +
                                                infant_index,
                                              {
                                                rules: [
                                                  {
                                                    required: true,
                                                  },
                                                ],
                                                initialValue:
                                                  infant.nric != ""
                                                    ? true
                                                    : false,
                                              }
                                            )(
                                              <Select
                                                disabled={
                                                  !props.edit_mode ||
                                                  infant.insurance_exported
                                                }
                                                initialValue={false}
                                                onChange={(value) =>
                                                  props.handleInfantDataChange(
                                                    index,
                                                    infant_index,
                                                    "is_malaysian",
                                                    value
                                                  )
                                                }
                                              >
                                                <Option value={false}>
                                                  Not Malaysian
                                                </Option>
                                                <Option value={true}>
                                                  Malaysian
                                                </Option>
                                              </Select>
                                            )}
                                          </Form.Item>
                                        </div>
                                        <div className="formItemWarp50">
                                          <Form.Item label="NRIC">
                                            {getFieldDecorator(
                                              booking.id +
                                                "nric" +
                                                infant_index,
                                              {
                                                rules: [
                                                  {
                                                    required:
                                                      infant.is_malaysian,
                                                    message:
                                                      "Please fill in your NRIC",
                                                  },
                                                ],
                                                initialValue: infant.nric,
                                              }
                                            )(
                                              <div>
                                                <Input
                                                  value={infant.nric}
                                                  maxLength={12}
                                                  disabled={
                                                    !props.edit_mode ||
                                                    infant.insurance_exported ||
                                                    !infant.is_malaysian
                                                  }
                                                  setFieldsValue={infant.nric}
                                                  onChange={(e) =>
                                                    props.handleInfantDataChange(
                                                      index,
                                                      infant_index,
                                                      "nric",
                                                      e.target.value
                                                    )
                                                  }
                                                  placeholder="XXXXXXXXXXXXX 12 digits"
                                                />
                                              </div>
                                            )}
                                          </Form.Item>
                                        </div>
                                      </div> */}
                                      {/** ===================================================== */}

                                      <div className="input_warp">
                                        {/*************************** Passport number ***************************/}
                                        <div className="formItemWarp50">
                                          <Form.Item label="Passport number">
                                            {getFieldDecorator(
                                              booking.id +
                                                "passport_number" +
                                                infant_index,
                                              {
                                                initialValue:
                                                  infant.passport_number,
                                              }
                                            )(
                                              <Input
                                                onChange={(e) =>
                                                  props.handleInfantDataChange(
                                                    index,
                                                    infant_index,
                                                    "passport_number",
                                                    e.target.value
                                                  )
                                                }
                                                placeholder="Passport number"
                                                disabled={
                                                  !props.edit_mode ||
                                                  infant.insurance_exported
                                                }
                                              />
                                            )}
                                          </Form.Item>
                                        </div>

                                        {/*************************** Passport expiry date ***************************/}
                                        <div className="formItemWarp50 date_sty">
                                          <Form.Item label="Passport expiry date">
                                            {getFieldDecorator(
                                              booking.id +
                                                "passport_expiry_date" +
                                                infant_index,
                                              {
                                                initialValue:
                                                  infant.passport_expiry_date !==
                                                  ""
                                                    ? moment(
                                                        infant.passport_expiry_date
                                                      )
                                                    : "",
                                                rules: [
                                                  {
                                                    required:
                                                      infant.passport_expiry_required,
                                                    message:
                                                      "Please select your expiry date",
                                                  },
                                                ],
                                              }
                                            )(
                                              <DatePicker
                                                onChange={(date, dateString) =>
                                                  props.handleInfantDataChange(
                                                    index,
                                                    infant_index,
                                                    "passport_expiry_date",
                                                    dateString
                                                  )
                                                }
                                                disabled={
                                                  !props.edit_mode ||
                                                  infant.insurance_exported
                                                }
                                              />
                                            )}
                                          </Form.Item>
                                        </div>
                                      </div>

                                      <div className="input_warp">
                                        {/*************************** Passport photocopy ***************************/}
                                        <div className="formItemWarp50 photo_upload">
                                          <Form.Item label={<div>Passport photocopy <label style={{ color: "red" }}>(format allowed: .jpg .jpeg .gif .png .pdf .jfif)</label></div>}>
                                            {getFieldDecorator(
                                              booking.id +
                                                "passport_photocopy" +
                                                infant_index,
                                              {
                                                valuePropName: "fileList",
                                                getValueFromEvent:
                                                  props.normFile,
                                              }
                                            )(
                                              <Upload.Dragger
                                                // action="/upload.do"
                                                beforeUpload={
                                                  props.beforeUpload
                                                }
                                                onChange={(value) =>
                                                  props.handleInfantUploadDataChange(
                                                    index,
                                                    infant_index,
                                                    "passport_photocopy",
                                                    value.fileList[0]
                                                      .originFileObj
                                                  )
                                                }
                                                disabled={
                                                  !props.edit_mode ||
                                                  infant.insurance_exported
                                                }
                                              >
                                                {infant.passport_photocopy_image !==
                                                "" ? (
                                                  infant.passport_photocopy_image.includes(
                                                    "pdf"
                                                  ) ? (
                                                    <a
                                                      href={
                                                        infant.passport_photocopy_image
                                                      }
                                                      target="_blank"
                                                    >
                                                      View passport
                                                    </a>
                                                  ) : (
                                                    <a
                                                      href={
                                                        booking.passport_photocopy_image
                                                      }
                                                      target="_blank"
                                                    >
                                                      <img
                                                        className="image-display-photocopy"
                                                        src={
                                                          infant.passport_photocopy_image
                                                        }
                                                        alt="avatar"
                                                      />
                                                    </a>
                                                  )
                                                ) : (
                                                  uploadButton
                                                )}
                                              </Upload.Dragger>
                                            )}
                                          </Form.Item>
                                        </div>

                                        {/*************************** Infant remark ***************************/}
                                        <div className="formItemWarp50">
                                          <Form.Item label="Infant remark">
                                            {getFieldDecorator(
                                              booking.id +
                                                "remark" +
                                                infant_index,
                                              {
                                                initialValue: infant.remark,
                                              }
                                            )(
                                              <Input
                                                placeholder="Infant remark"
                                                onChange={(e) =>
                                                  props.handleInfantDataChange(
                                                    index,
                                                    infant_index,
                                                    "remark",
                                                    e.target.value
                                                  )
                                                }
                                                disabled={
                                                  !props.edit_mode ||
                                                  infant.insurance_exported
                                                }
                                              />
                                            )}
                                          </Form.Item>
                                        </div>
                                      </div>
                                      <div className="input_warp">
                                        {/*************************** Infant insurance cert ***************************/}
                                        <div className="formItemWarp50">
                                          <Form.Item label="Insurance Certificate">
                                            {infant.insurance_cert !== "" ? (
                                              <a
                                                href={infant.insurance_cert}
                                                target="_blank"
                                              >
                                                {infant.policy_number}
                                              </a>
                                            ) : null}
                                          </Form.Item>
                                        </div>
                                      </div>

                                      <div className="insuran_detail">
                                        {/**************************** Free Chubb Travel Insurance *************************/}
                                        <div className="free_chubb">
                                          <h3 className="title2">
                                            Free Chubb Travel Insurance
                                          </h3>
                                          <p>
                                            Our packages bundle up with Chubb
                                            Executive travel insurance that is
                                            underwritten by Chubb Insurance
                                            Malaysia Berhad. Please choose your
                                            option below.
                                          </p>
                                          <p>
                                            Click{" "}
                                            <a
                                              href={homepage_new.chubb_terms}
                                              target="_blank"
                                            >
                                              here
                                            </a>{" "}
                                            to view the benefits and policy
                                            wording
                                          </p>
                                          {getFieldDecorator(
                                            booking.id +
                                              "insurance_confirmation" +
                                              infant_index,
                                            {
                                              initialValue:
                                                infant.insurance_confirmation,
                                            }
                                          )(
                                            <Radio.Group
                                              onChange={(e) =>
                                                props.handleInfantDataChange(
                                                  index,
                                                  infant_index,
                                                  "insurance_confirmation",
                                                  e.target.value
                                                )
                                              }
                                              disabled={
                                                !props.edit_mode ||
                                                infant.insurance_exported
                                              }
                                            >
                                              <Radio value={true}>
                                                Yes, please include with no
                                                additional charge
                                              </Radio>
                                              <Radio value={false}>
                                                No, I don't want free insurance
                                                and understand the cost remain
                                                the same
                                              </Radio>
                                            </Radio.Group>
                                          )}
                                          {infant.insurance_confirmation ==
                                          true ? (
                                            <small className="notice_insuran">
                                              "Certificate will only be issued
                                              to the customer after clicking the
                                              Confirm Insurance"
                                            </small>
                                          ) : null}
                                        </div>

                                        {/***************************** Nationality ****************************************/}
                                        {infant.nationality_visible == true ? (
                                          <div className="free_chubb national">
                                            <h3 className="title2">
                                              Nationality
                                            </h3>
                                            {getFieldDecorator(
                                              booking.id +
                                                "nationality" +
                                                infant_index,
                                              {
                                                initialValue:
                                                  infant.nationality,
                                              }
                                            )(
                                              <Radio.Group
                                                onChange={(e) =>
                                                  props.handleInfantDataChange(
                                                    index,
                                                    infant_index,
                                                    "nationality",
                                                    e.target.value
                                                  )
                                                }
                                                disabled={
                                                  !props.edit_mode ||
                                                  infant.insurance_exported
                                                }
                                              >
                                                <Radio
                                                  value="malaysian"
                                                  className="label_1"
                                                >
                                                  I am a Malaysian, Malaysian
                                                  Permanent Resident or holder
                                                  of a valid working permit in
                                                  Malaysia, dependent pass, long
                                                  term social visit pass or
                                                  student pass, and will
                                                  commence journey from
                                                  Malaysia.
                                                </Radio>
                                                <Radio value="non-malaysian">
                                                  No. Sorry, you are not
                                                  eligible for this cover.
                                                </Radio>
                                              </Radio.Group>
                                            )}
                                          </div>
                                        ) : null}

                                        <div className="note">
                                          {getFieldDecorator(
                                            booking.id +
                                              "insurance_nomination_flag" +
                                              infant_index,
                                            {}
                                          )(
                                            <Checkbox
                                              onChange={(e) =>
                                                props.handleInfantDataChange(
                                                  index,
                                                  infant_index,
                                                  "insurance_nomination_flag",
                                                  e.target.checked
                                                )
                                              }
                                              defaultChecked={
                                                infant.insurance_nomination_flag !==
                                                  "" || 0
                                                  ? infant.insurance_nomination_flag
                                                  : true
                                              }
                                              disabled={
                                                !props.edit_mode ||
                                                infant.insurance_exported
                                              }
                                            >
                                              Note: Death benefit will be paid
                                              to insured Person's estate. Please
                                              uncheck if the insured person
                                              wishes to make a NOMINEE/NOMINEES.
                                            </Checkbox>
                                          )}
                                        </div>

                                        <div className="input_warp">
                                          {/*************************** Guardian ***************************/}
                                          <div className="formItemWarp25">
                                            <Form.Item label="Is Guest the parent?">
                                              {getFieldDecorator(
                                                "guardian" + infant.id,
                                                {
                                                  initialValue:
                                                    infant.parent_id,
                                                }
                                              )(
                                                <Radio.Group
                                                  onChange={(e) =>
                                                    props.handleInfantDataChange(
                                                      index,
                                                      infant_index,
                                                      "parent_id",
                                                      e.target.value
                                                    )
                                                  }
                                                  disabled={
                                                    !props.edit_mode ||
                                                    infant.insurance_exported
                                                  }
                                                >
                                                  <Radio value={booking.id}>
                                                    Yes
                                                  </Radio>
                                                  <Radio value={0}>No</Radio>
                                                </Radio.Group>
                                              )}
                                            </Form.Item>
                                          </div>
                                        </div>

                                        {infant.parent_id != null &&
                                        infant.parent_id == 0 ? (
                                          <div className="guardian">
                                            {/******************************* Guardian ***********************************/}
                                            <h4 className="title3">Guardian</h4>
                                            <div className="input_warp">
                                              {/*************************** Designation ***************************/}
                                              <div className="formItemWarp50 ">
                                                <Form.Item label="Designation">
                                                  {getFieldDecorator(
                                                    booking.id +
                                                      "guardian_designation" +
                                                      infant.id +
                                                      infant_index,
                                                    {
                                                      initialValue:
                                                        infant.ph_designation,
                                                    }
                                                  )(
                                                    <Select
                                                      showSearch
                                                      onChange={(value) =>
                                                        props.handleInfantDataChange(
                                                          index,
                                                          infant_index,
                                                          "ph_designation",
                                                          value
                                                        )
                                                      }
                                                      placeholder="Select"
                                                      disabled={
                                                        !props.edit_mode ||
                                                        infant.insurance_exported
                                                      }
                                                    >
                                                      {[
                                                        "Mr",
                                                        "Mrs",
                                                        "Ms",
                                                        "Mstr",
                                                        "Mdm",
                                                      ].map((designation) => {
                                                        return (
                                                          <Option
                                                            key={designation}
                                                            value={designation}
                                                          >
                                                            {designation}
                                                          </Option>
                                                        );
                                                      })}
                                                    </Select>
                                                  )}
                                                </Form.Item>
                                              </div>

                                              {/*************************** Full Name ***************************/}
                                              <div className="formItemWarp50 ">
                                                <Form.Item label="Full Name">
                                                  {getFieldDecorator(
                                                    booking.id +
                                                      "guardian_name" +
                                                      infant.id +
                                                      infant_index,
                                                    {
                                                      initialValue:
                                                        infant.ph_name,
                                                    }
                                                  )(
                                                    <Input
                                                      onChange={(e) =>
                                                        props.handleInfantDataChange(
                                                          index,
                                                          infant_index,
                                                          "ph_name",
                                                          e.target.value
                                                        )
                                                      }
                                                      placeholder="Full name"
                                                      disabled={
                                                        !props.edit_mode ||
                                                        infant.insurance_exported
                                                      }
                                                    />
                                                  )}
                                                </Form.Item>
                                              </div>
                                            </div>

                                            <div className="input_warp">
                                              {/*************************** Date of Birth ***************************/}
                                              <div className="formItemWarp50 date_sty">
                                                <Form.Item label="Date of Birth">
                                                  {getFieldDecorator(
                                                    booking.id +
                                                      "guardian_dob" +
                                                      infant.id +
                                                      infant_index,
                                                    {
                                                      initialValue:
                                                        infant.ph_dob !== ""
                                                          ? moment(
                                                              infant.ph_dob
                                                            )
                                                          : "",
                                                    }
                                                  )(
                                                    <DatePicker
                                                      onChange={(
                                                        date,
                                                        dateString
                                                      ) =>
                                                        props.handleInfantDataChange(
                                                          index,
                                                          infant_index,
                                                          "ph_dob",
                                                          dateString
                                                        )
                                                      }
                                                      disabled={
                                                        !props.edit_mode ||
                                                        infant.insurance_exported
                                                      }
                                                    />
                                                  )}
                                                </Form.Item>
                                              </div>

                                              {/*************************** Passport Number ***************************/}
                                              <div className="formItemWarp50 ">
                                                <Form.Item label="Passport Number">
                                                  {getFieldDecorator(
                                                    booking.id +
                                                      "guardian_id_no" +
                                                      infant.id +
                                                      infant_index,
                                                    {
                                                      initialValue:
                                                        infant.ph_id_number,
                                                    }
                                                  )(
                                                    <Input
                                                      onChange={(e) =>
                                                        props.handleInfantDataChange(
                                                          index,
                                                          infant_index,
                                                          "ph_id_number",
                                                          e.target.value
                                                        )
                                                      }
                                                      placeholder="Passport Number"
                                                      disabled={
                                                        !props.edit_mode ||
                                                        infant.insurance_exported
                                                      }
                                                    />
                                                  )}
                                                </Form.Item>
                                              </div>
                                            </div>
                                          </div>
                                        ) : null}

                                        {/****************************** Nominee Details ***********************************/}
                                        {infant.insurance_nomination_flag ==
                                        false ? (
                                          /************************** nominee 1 *******************************************/
                                          <div>
                                            {infant.insurance_nomination.map(
                                              (insurance, nominee_index) => {
                                                return (
                                                  <div className="nominee">
                                                    <h4 className="title3">
                                                      Nominee Details{" "}
                                                      {nominee_index + 1}
                                                    </h4>
                                                    <div className="input_warp">
                                                      {/*************************** Surname ***************************/}
                                                      <div className="formItemWarp33 ">
                                                        <Form.Item label="Surname">
                                                          {getFieldDecorator(
                                                            booking.id +
                                                              "nominee_surname" +
                                                              infant.id +
                                                              nominee_index,
                                                            {
                                                              // rules: [
                                                              //   {
                                                              //     required: true,
                                                              //     message: 'Please input your Surname!',
                                                              //   },
                                                              // ],
                                                              initialValue:
                                                                insurance.nominee_surname,
                                                            }
                                                          )(
                                                            <Input
                                                              placeholder="Surname"
                                                              onChange={(e) =>
                                                                props.handleInfantInsuranceDataChange(
                                                                  index,
                                                                  infant_index,
                                                                  nominee_index,
                                                                  "nominee_surname",
                                                                  e.target.value
                                                                )
                                                              }
                                                              disabled={
                                                                !props.edit_mode ||
                                                                infant.insurance_exported
                                                              }
                                                            />
                                                          )}
                                                        </Form.Item>
                                                      </div>

                                                      {/*************************** Given Name ***************************/}
                                                      <div className="formItemWarp33 ">
                                                        <Form.Item label="Given Name">
                                                          {getFieldDecorator(
                                                            booking.id +
                                                              "nominee_given_name" +
                                                              infant.id +
                                                              nominee_index,
                                                            {
                                                              // rules: [
                                                              //   {
                                                              //     required: true,
                                                              //     message: 'Please input your Given Name!',
                                                              //   },
                                                              // ],
                                                              initialValue:
                                                                insurance.nominee_given_name,
                                                            }
                                                          )(
                                                            <Input
                                                              placeholder="Given Name"
                                                              onChange={(e) =>
                                                                props.handleInfantInsuranceDataChange(
                                                                  index,
                                                                  infant_index,
                                                                  nominee_index,
                                                                  "nominee_given_name",
                                                                  e.target.value
                                                                )
                                                              }
                                                              disabled={
                                                                !props.edit_mode ||
                                                                infant.insurance_exported
                                                              }
                                                            />
                                                          )}
                                                        </Form.Item>
                                                      </div>

                                                      {/******************* Passport/NRIC Number *************************/}
                                                      <div className="formItemWarp33 ">
                                                        <Form.Item label="Passport/NRIC Number">
                                                          {getFieldDecorator(
                                                            booking.id +
                                                              "nominee_passport_number" +
                                                              infant.id +
                                                              nominee_index,
                                                            {
                                                              // rules: [
                                                              //   {
                                                              //     required: true,
                                                              //     message: 'Please input your Passport/NRIC Number!',
                                                              //   },
                                                              // ],
                                                              initialValue:
                                                                insurance.nominee_passport_number,
                                                            }
                                                          )(
                                                            <Input
                                                              placeholder="Passport/NRIC Number"
                                                              onChange={(e) =>
                                                                props.handleInfantInsuranceDataChange(
                                                                  index,
                                                                  infant_index,
                                                                  nominee_index,
                                                                  "nominee_passport_number",
                                                                  e.target.value
                                                                )
                                                              }
                                                              disabled={
                                                                !props.edit_mode ||
                                                                infant.insurance_exported
                                                              }
                                                            />
                                                          )}
                                                        </Form.Item>
                                                      </div>
                                                    </div>
                                                    <div className="input_warp">
                                                      {/*************************** Date of Birth ************************/}
                                                      <div className="formItemWarp33 date_sty">
                                                        <Form.Item label="Date of Birth">
                                                          {getFieldDecorator(
                                                            booking.id +
                                                              "nominee_date_of_birth" +
                                                              infant.id +
                                                              nominee_index,
                                                            {
                                                              // rules: [
                                                              //   {
                                                              //     required: true,
                                                              //     message: 'Please select your Date of Birth!',
                                                              //   },
                                                              // ],
                                                              initialValue:
                                                                insurance.nominee_date_of_birth !==
                                                                ""
                                                                  ? moment(
                                                                      insurance.nominee_date_of_birth
                                                                    )
                                                                  : "",
                                                            }
                                                          )(
                                                            <DatePicker
                                                              onChange={(
                                                                date,
                                                                dateString
                                                              ) =>
                                                                props.handleInfantInsuranceDataChange(
                                                                  index,
                                                                  infant_index,
                                                                  nominee_index,
                                                                  "nominee_date_of_birth",
                                                                  dateString
                                                                )
                                                              }
                                                              disabled={
                                                                !props.edit_mode ||
                                                                infant.insurance_exported
                                                              }
                                                            />
                                                          )}
                                                        </Form.Item>
                                                      </div>

                                                      {/*************************** Relationship ************************/}
                                                      <div className="formItemWarp33 ">
                                                        <Form.Item label="Relationship">
                                                          {getFieldDecorator(
                                                            booking.id +
                                                              "nominee_relationship" +
                                                              infant.id +
                                                              nominee_index,
                                                            {
                                                              // rules: [
                                                              //   {
                                                              //     required: true,
                                                              //     message: 'Please select your Relationship!',
                                                              //   },
                                                              // ],
                                                              initialValue:
                                                                insurance.nominee_relationship,
                                                            }
                                                          )(
                                                            <Select
                                                              showSearch
                                                              placeholder="Select"
                                                              onChange={(
                                                                value
                                                              ) =>
                                                                props.handleInfantInsuranceDataChange(
                                                                  index,
                                                                  infant_index,
                                                                  nominee_index,
                                                                  "nominee_relationship",
                                                                  value
                                                                )
                                                              }
                                                              disabled={
                                                                !props.edit_mode ||
                                                                infant.insurance_exported
                                                              }
                                                            >
                                                              {[
                                                                "Spouse",
                                                                "Father",
                                                                "Mother",
                                                                "Brother",
                                                                "Daughter",
                                                                "Sister",
                                                                "Son",
                                                                "Grandmother",
                                                                "Grandfather",
                                                                "Grandson",
                                                                "Granddaughter",
                                                                "Friend",
                                                                "Others",
                                                              ].map(
                                                                (
                                                                  Relationship
                                                                ) => {
                                                                  return (
                                                                    <Option
                                                                      key={
                                                                        Relationship
                                                                      }
                                                                      value={
                                                                        Relationship
                                                                      }
                                                                    >
                                                                      {
                                                                        Relationship
                                                                      }
                                                                    </Option>
                                                                  );
                                                                }
                                                              )}
                                                            </Select>
                                                          )}
                                                        </Form.Item>
                                                      </div>

                                                      {/*************************** Share ***************************/}
                                                      <div className="formItemWarp33 ">
                                                        <Form.Item label="Share">
                                                          {getFieldDecorator(
                                                            booking.id +
                                                              "nominee_share" +
                                                              infant.id +
                                                              nominee_index,
                                                            {
                                                              // rules: [
                                                              //   {
                                                              //     required: true,
                                                              //     message: 'Please select your Share!',
                                                              //   },
                                                              // ],
                                                              initialValue:
                                                                insurance.nominee_share,
                                                            }
                                                          )(
                                                            <Select
                                                              showSearch
                                                              placeholder="Select"
                                                              onChange={(
                                                                value
                                                              ) =>
                                                                props.handleInfantInsuranceDataChange(
                                                                  index,
                                                                  infant_index,
                                                                  nominee_index,
                                                                  "nominee_share",
                                                                  value
                                                                )
                                                              }
                                                              disabled={
                                                                !props.edit_mode ||
                                                                infant.insurance_exported
                                                              }
                                                            >
                                                              {[
                                                                "0",
                                                                "25",
                                                                "50",
                                                                "75",
                                                                "100",
                                                              ].map((Share) => {
                                                                return (
                                                                  <Option
                                                                    key={Share}
                                                                    value={
                                                                      Share
                                                                    }
                                                                  >
                                                                    {Share}
                                                                  </Option>
                                                                );
                                                              })}
                                                            </Select>
                                                          )}
                                                        </Form.Item>
                                                      </div>
                                                    </div>
                                                  </div>
                                                );
                                              }
                                            )}

                                            {/************************* Witness Details ***********************************/}
                                            <div className="Witness_Details">
                                              <h4 className="title3">
                                                Witness Details
                                              </h4>
                                              <div className="input_warp">
                                                {/*************************** Surname ***************************/}
                                                <div className="formItemWarp33 ">
                                                  <Form.Item label="Surname">
                                                    {getFieldDecorator(
                                                      booking.id +
                                                        "witness_surname" +
                                                        infant.id +
                                                        infant_index,
                                                      {
                                                        rules: [
                                                          {
                                                            required: true,
                                                            message:
                                                              "Please input your Surname!",
                                                          },
                                                        ],
                                                        initialValue:
                                                          infant.witness_surname,
                                                      }
                                                    )(
                                                      <Input
                                                        placeholder="Surname"
                                                        onChange={(e) =>
                                                          props.handleInfantDataChange(
                                                            index,
                                                            infant_index,
                                                            "witness_surname",
                                                            e.target.value
                                                          )
                                                        }
                                                        disabled={
                                                          !props.edit_mode ||
                                                          infant.insurance_exported
                                                        }
                                                      />
                                                    )}
                                                  </Form.Item>
                                                </div>
                                                {/*************************** Given Name ***************************/}
                                                <div className="formItemWarp33 ">
                                                  <Form.Item label="Given Name">
                                                    {getFieldDecorator(
                                                      booking.id +
                                                        "witness_given_name" +
                                                        infant.id +
                                                        infant_index,
                                                      {
                                                        rules: [
                                                          {
                                                            required: true,
                                                            message:
                                                              "Please input your Given Name!",
                                                          },
                                                        ],
                                                        initialValue:
                                                          infant.witness_given_name,
                                                      }
                                                    )(
                                                      <Input
                                                        placeholder="Given Name"
                                                        onChange={(e) =>
                                                          props.handleInfantDataChange(
                                                            index,
                                                            infant_index,
                                                            "witness_given_name",
                                                            e.target.value
                                                          )
                                                        }
                                                        disabled={
                                                          !props.edit_mode ||
                                                          infant.insurance_exported
                                                        }
                                                      />
                                                    )}
                                                  </Form.Item>
                                                </div>

                                                {/*************************** Passport/NRIC Number ***********************/}
                                                <div className="formItemWarp33 ">
                                                  <Form.Item label="Passport/NRIC Number">
                                                    {getFieldDecorator(
                                                      booking.id +
                                                        "witness_passport_number" +
                                                        infant.id +
                                                        infant_index,
                                                      {
                                                        rules: [
                                                          {
                                                            required: true,
                                                            message:
                                                              "Please input your Passport/NRIC Number!",
                                                          },
                                                        ],
                                                        initialValue:
                                                          infant.witness_passport_number,
                                                      }
                                                    )(
                                                      <Input
                                                        placeholder="Passport/NRIC Number"
                                                        onChange={(e) =>
                                                          props.handleInfantDataChange(
                                                            index,
                                                            infant_index,
                                                            "witness_passport_number",
                                                            e.target.value
                                                          )
                                                        }
                                                        disabled={
                                                          !props.edit_mode ||
                                                          infant.insurance_exported
                                                        }
                                                      />
                                                    )}
                                                  </Form.Item>
                                                </div>
                                              </div>
                                            </div>

                                            {/***************************** notice_infor *********************************/}
                                            <div className="notice_infor">
                                              <small>
                                                By typing in my name and
                                                Passport/NRIC No., I confirm
                                                that:
                                                <ol>
                                                  <li>
                                                    I am of sound mind and have
                                                    attained the age of 18 years
                                                    old;
                                                  </li>
                                                  <li>
                                                    I am not a nominee named by
                                                    the Insured Person(s);
                                                  </li>
                                                  <li>
                                                    I am the witness to the
                                                    nomination(s) made, as the
                                                    nomination(s) was(were) made
                                                    to me by the respective
                                                    Insured Person(s); and
                                                  </li>
                                                  <li>
                                                    I have informed the Insured
                                                    Person(s) that if the
                                                    nomination does not create a
                                                    trust policy, and he/she
                                                    intends his/her nominee(s)
                                                    to receive the policy
                                                    benefits beneficially and
                                                    not as an executor, he/she
                                                    has to assign the policy
                                                    benefits to his/her
                                                    nominee(s).
                                                  </li>
                                                </ol>
                                              </small>
                                            </div>
                                          </div>
                                        ) : null}
                                      </div>
                                      <div className="clear"></div>
                                    </div>
                                  );
                                }
                              )}
                            </div>
                          ) : null}
                        </div>
                      );
                    })
                  : null}
              </React.Fragment>
            ) : null}
          </Form>
        </div>

        {
          props.data.balance_amount_decimal > 0 ?
            <h3 className="due_payment_footer">Final Payment of {props.data.balance_amount} is due before {props.data.final_payment_date}</h3>
          : ""
        }
      </div>
    </div>
  );
});
