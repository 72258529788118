import React, { Component } from 'react';
import { Table, Form, Card, Row, Col, Slider, Divider, Button, notification, Tag, Tooltip } from 'antd';
import instant from '../../../../../images/payment/instant.png';
import PaymentMethod from '../../show/payment';
import { withRouter } from 'react-router-dom';
import API from '../../../../api';
import qs from 'qs';

const gridStyle = {
  width: '100%',
  // textAlign: 'center',
};

const compareArrays = (a, b) => {
  if (a.length !== b.length) return false;
  else {
    // Comparing each element of your array
    for (var i = 0; i < a.length; i++) {
      if (a[i] !== b[i]) {
        return false;
      }
    }
    return true;
  }
};

class LeftPanel extends Component {
  constructor(props) {
    super(props);

    const due_payment_params = qs.parse(this.props.location.search, { ignoreQueryPrefix: true })
    this.state = {
      selectedRowKeys: [],
      booking_ids: [],
      banklist: [],
      payment_type: '',
      payment_method: null,
      check_out_disabled: true,
      check_out_loading: false,
      banklist: [],
      dobw_banklist: [],
      pbb_banklist: [],
      fpxb2b_banklist: [],
      offline_banklist: '',
      b2b_offline_banklist: '',
      banklist_select: [],
      method_visible: false,
      validationErrors: [],
      agree: '',
      booking_details: [],
      due_bookings: [],
      loading: false,
      page: due_payment_params['page'] != undefined ? due_payment_params['page'] : '',
      pagination: {
        current: 1,
        pageSize: 20,
      },
      bank_type: null,
      redirect_url: null,
    }
  }

  priceFormat(value) {
    return value === undefined ? 0 : value.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') // this is for RM X,XXX.XX format
    // return value === undefined ? 0 : value.toFixed().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') // this is for RM X,XXX format
  }

  getUniqueArrayBy(arr, key) {
     return [...new Map(arr.map(item => [item[key], item])).values()]
  }

  componentDidMount() {
    const { pagination } = this.state;
    this.banklistFetch();
    this.bookingsFetch();
    this.fetch({ pagination });
  }

  componentDidUpdate(prevProps) {
    this.handleAfterRemove();
  }

  handleAfterRemove = () => {
    if (compareArrays(this.state.booking_ids.sort((a, b) => a.id - b.id), this.props.booking_ids.sort((a, b) => a.id - b.id)) === false){
      this.setState({
        selectedRowKeys: this.props.booking_ids,
        booking_ids: this.props.booking_ids,
        booking_details: this.props.booking_info,
      }, () => { this.handlePurchaseInfo(this.props.booking_info) })
    }
  }

  bookingsFetch() {
    let url = './api/v1/series/due_payment_bookings';
    var that = this;
    // console.log(params);

    API.get(url)
      .then(function (response) {
        that.setState({
          due_bookings: response.data,
          loading: false,
          pagination: {
            total: response.data.length > 0 ? response.data[0].total_record : 0,
          },
        })
      })
      .catch(function (error) {
        console.log(error);
      })
      .then(function () {
        // always executed
      }); 
  }

  handleTablePaginationChange = (pagination, filters, sorter) => {
    this.fetch({
      pagination,
      // sortField: sorter.field,
      // sortOrder: sorter.order,
      // ...filters,
    });
  };

  fetch = (params = {}) => {
    this.setState({ loading: true });
    let outputJson = {
      page: params.pagination.current,
    };

    this.props.history.push({
      pathname: '/bookings/due_payments',
      search: "?" + qs.stringify(outputJson)
    });  

    let url = './api/v1/series/due_payment_bookings?'+qs.stringify(outputJson);
    var that = this;
    API.get(url)
      .then(function (response) {
        console.log(response.data, '------------res data');
        that.setState({
          due_bookings: response.data,
          loading: false,
          // booking_details: [],
          // booking_ids: [],
          pagination: {
            ...params.pagination,
            total: response.data.length > 0 ? response.data[0].total_record : 0,
          },
        }, () => { that.handlePurchaseInfo(that.state.booking_details) })
      })
      .catch(function (error) {
        console.log(error);
      })
      .then(function () {
        // always executed
      });
  };

  banklistFetch() {
    let url = './api/v1/payments/payment_method';
    var that = this;
    // console.log(params);

    API.get(url)
      .then(function (response) {
        // console.log(response, '------------res banklist');
        that.setState({
          banklist: response.data.banklist,
          pbb_banklist: response.data.pbb_banklist,
          offline_banklist: response.data.offline_banklist,
          fpxb2b_banklist: response.data.fpxb2b_banklist,
          b2b_offline_banklist: response.data.b2b_offline_banklist,
          dobw_banklist: response.data.dobw_banklist,
          // credits: response.data.credits
        })
      })
      .catch(function (error) {
        console.log(error);
      })
      .then(function () {
        // always executed
      });
  }

  handlePurchaseInfo = (value) => {
    this.props.handlePurchaseInfo(value)
  }

  onClickRemoveData = (unchecked_ids) => {
    // console.log(unchecked_ids, "===onClickRemoveData unchecked_ids")
    if (unchecked_ids.length > 0) {
      unchecked_ids.map((id, index) => (
        this.props.onClickRemoveData(id)
      ))
    }
  }

  onChangeCheckbox(selectedRowKeys, selectedRows) {
    console.log(`onChangeCheckbox selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
    let unchecked_ids = this.state.selectedRowKeys.filter(number => !selectedRowKeys.includes(number))
    // console.log(unchecked_ids, "===unchecked_ids")

    // Append selectedRows instead of replacing it
    let all_booking_details = [...this.state.booking_details, selectedRows];
    this.state.booking_details = this.getUniqueArrayBy(all_booking_details.flat(), 'id');

    this.setState({
      booking_ids: selectedRowKeys,
      selectedRowKeys: selectedRowKeys,
      booking_details: this.state.booking_details.flat(),
      check_out_disabled: this.state.booking_details.flat().length > 0 ? false : true,
      payment_type: '',
      payment_method: null,
    }, () => { this.handlePurchaseInfo(this.state.booking_details.flat()), this.onClickRemoveData(unchecked_ids) })
  }

  handlePaymentChange(event) {
    console.log(event.target.value);
    if (event.target.value === 'public_bank') {
      this.setState({
        method_visible: true,
        banklist_select: this.state.pbb_banklist,
        payment_method: null
      })
    }
    else if (event.target.value === 'public_bank_2') {
      this.setState({
        method_visible: true,
        banklist_select: this.state.pbb_banklist,
        payment_method: null
      })
    }
    else if (event.target.value === "fpx_b2c") {
      this.banklistFetch();
      this.setState({
        method_visible: true,
        banklist_select: this.state.banklist,
        payment_method: null
      })
    }
    else if (event.target.value === "fpx_b2b") {
      this.banklistFetch();
      this.setState({
        method_visible: true,
        banklist_select: this.state.fpxb2b_banklist,
        payment_method: null
      })
    }
    else if (event.target.value === "dobw") {
      this.banklistFetch();
      this.setState({
        method_visible: true,
        banklist_select: this.state.dobw_banklist,
        payment_method: null,
      })
    }
    else /** if (event.target.value === 'credit_transaction') **/ {
      this.setState({
        banklist_select: [],
        method_visible: false,
        payment_method: null
      })
    }

    this.setState({
      payment_type: event.target.value
    })
    this.props.handlePaymentType(event.target.value);
  }

  handlePaymentMethodChange(value, option) {
    let payment_method = value;
    let bank_type = ""
    let redirect_url = ""

    if (this.state.payment_type === "dobw"){
      var found_bank = this.state.banklist_select.find((val) => val[1] === payment_method);
      redirect_url = found_bank[2]
      const splitString = payment_method.split(" - ");
      payment_method = splitString[0];
      bank_type = splitString[1];
    }

    this.setState({
      payment_method: payment_method,
      bank_type: bank_type,
      redirect_url: redirect_url
    })
    // console.log("card method",value)
  };

  onChangePaymentCheckBox(e) {
    let isChecked = e.target.checked === true ? "agree" : "";
    console.log(`checked = ${isChecked}`);
    this.setState({
      agree: isChecked
    })
  };

  openNotificationWithIcon = (type, error_name, error_message = "") => {
    if (type === "warning") {
      if (error_name === "payment_method_error") {
        notification[type]({
          message: 'Warning!',
          description:
            'Please select payment method before you continue.',
        });
      }
    }
    if (type === "error") {
      if (error_name === "payment_error") {
        notification[type]({
          message: 'Error!',
          description: error_message,
        });
      }
    }
  };

  submitForm = event => {
    event.preventDefault();

    const { booking_ids, payment_type, payment_method, agree, validationErrors, bank_type, redirect_url } = this.state;

    let outputJson = {
      booking_ids: booking_ids,
      total_amount: this.props.bookingTotal,
      payment: { gateway: payment_type, method: payment_method, bank_type: bank_type, redirect_url: redirect_url },
      agree: agree
    };
    console.log("This is the outputJson", outputJson);

    this.props.form.validateFieldsAndScroll((err, values) => {
      if(payment_type === "" || agree === "" || !err === false) {
        if (!err) {
          console.log('Received values of form: ', values);
        }
        if (payment_type === "") {
            this.openNotificationWithIcon('warning','payment_method_error');
        }
        if (payment_method === null) {
            validationErrors.payment_method = "*Please select a payment method";
            this.setState({ validationErrors: validationErrors })
        } else {
            validationErrors.payment_method = null;
            this.setState({ validationErrors: validationErrors })
        }
        if (agree === "") {
            validationErrors.agree = "*Please agreed the Terms of Use and Privacy Statement";
            this.setState({ validationErrors: validationErrors, agree: false })
        } else {
            validationErrors.agree = "";
            this.setState({ validationErrors: validationErrors })
        }
      }
      else {
        this.setState({
          check_out_disabled: true,
          check_out_loading: true,
        })

        let url = './api/v1/series/create_due_bookings_payment';
        let that = this;

        API.post(url, outputJson)
          .then(function (response) {
            console.log(response, '------------res');
            console.log(response.data.redirect_to, '------------redirect');
            if (response.data.redirect_to) {
              window.location = response.data.redirect_to
            }
            else {
              that.openNotificationWithIcon('error','payment_error', response.data.error);
              that.setState({
                check_out_disabled: false,
                check_out_loading: false
              })
            }
            that.setState({
              loading: false
            })
          })
          .catch(function (error) {
            console.log(error);
            that.setState({
              visible: true,
            })
          })
          .then(function () {
            // always executed
          });
      }
    });
    
  }
  

  render() {
    const { inputValue, selected, credits, payment_type, payment_method, method_visible, validationErrors, banklist_select, offline_banklist, selectedRowKeys } = this.state;

    const columns = [
      {
        title: 'Ref No.',
        width: 150,
        dataIndex: 'ref_no',
        key: 'ref_no',
        render: (text, record) => (
          <span>
            {
              record.payable !== 'Payable' ? (
                <Tooltip title={record.payable}>{record.ref_no}</Tooltip>
              ) : (record.ref_no)
            }
          </span>
        ),
      },
      {
        title: 'Name',
        width: 150,
        dataIndex: 'agent_name',
        key: 'agent_name',
      },
      {
        title: 'Product',
        width: 250,
        dataIndex: 'product',
        key: 'product',
      },
      {
        title: 'Payment Type',
        width: 120,
        dataIndex: 'payment_type',
        key: 'payment_type',
        fixed: 'right',
      },
      {
        title: 'Amount',
        width: 150,
        dataIndex: 'amount_text',
        key: 'amount_text',
        fixed: 'right',
      },
    ];

    const rowSelection = {
      selectedRowKeys,
      onChange: (selectedRowKeys, selectedRows) => {
        this.onChangeCheckbox(selectedRowKeys, selectedRows);
      },
      getCheckboxProps: record => ({
        disabled: record.payable !== 'Payable', // Column configuration not to be checked
        name: record.payable,
      }),
    };

    return (
      <div className="LeftPanel">
        {/************************************************ Form ************************************************/}
        <Form onSubmit={this.submitForm} className="form_sty_select" >
          <div className="credit_purchase">
            <div className="header_title">
              <h2>Select Bookings</h2>
            </div>
            <Table
              rowSelection={rowSelection} 
              columns={columns}
              dataSource={this.state.due_bookings}
              pagination={this.state.pagination}
              loading={this.state.loading}
              onChange={this.handleTablePaginationChange}
              scroll={{ x: 'max-content' }}
              className="table_warp" />
          </div>

          {/************************************************ Payment Method ************************************************/}
          <div className="header_title">
            <h2>Your Payment Method</h2>
          </div>

          <PaymentMethod handlePaymentChange={this.handlePaymentChange.bind(this)} no_credit_payment={false} credit_disabled={this.props.credit_disabled} fpx_disabled={false} invoice_payment={true}
            payment_type={payment_type} payment_method={payment_method} credits={this.props.credits} method_visible={method_visible} validationErrors={validationErrors} banklist_select={banklist_select}
            offline_banklist={offline_banklist} handlePaymentMethodChange={(value, option) => this.handlePaymentMethodChange(value, option)}
            onChangePaymentCheckBox={(e) => this.onChangePaymentCheckBox(e)} pageName="DuePaymentShowPage" allow_fpxb2c_overlimit={false} final_price={this.props.bookingTotal} service_fee={this.props.invoiceServiceFee} bank_type={this.state.bank_type}
          /> 

          <div className="after_select">
            <Button type="primary" htmlType="submit" disabled={this.state.booking_details.length == 0 || this.state.check_out_disabled } loading={this.state.check_out_loading}>CHECK OUT</Button>
          </div>

        </Form>
      </div>
    );
  }
}

const DuePaymentShowPage = Form.create({ name: 'due_payment' })(LeftPanel);
export default withRouter(DuePaymentShowPage);