import React, { Component } from 'react';
import { Table, Divider, Tag, Typography, Modal, Dropdown, Button, Menu, Form, Input, Radio, Select, DatePicker, Upload, Icon, Checkbox, message} from 'antd';
import MenuBooking from '../menu';
import { Link, withRouter} from 'react-router-dom';
import API from '../../../../api'
import './index.css';
import moment from 'moment';
import jsonToFormData from 'json-form-data';

const { Paragraph } = Typography;
const { Option } = Select;
const dateFormat = 'YYYY-MM-DD';

function onChange(value) {
  console.log(`selected ${value}`);
}

const expandedRowRender = (record) => {
  const columns1 = [
    { title: 'Name', dataIndex: 'name', key: 'name' },
    { title: 'Mobile', dataIndex: 'mobile', key: 'mobile' },
    { title: 'Nationality', dataIndex: 'nationality', key: 'nationality' },
    // { title: 'Passport Expirty Date', dataIndex: 'passport_expiry_date', key: 'passport_expiry_date' },
    { title: 'Date of Birth', dataIndex: 'date_of_birth', key: 'date_of_birth' },
    { title: 'Category', dataIndex: 'category', key: 'category' },
    { title: 'Price', dataIndex: 'total_price', key: 'total_price' },
    {
      title: 'Remark',
      dataIndex: 'remark',
      key: 'remark',
      width: 200,
      render: remark =>(
        <Paragraph ellipsis={{ rows: 3, expandable: true }}>
          {remark}
        </Paragraph>
      ),
    },
  ]
  return <div>
    <Table columns={columns1} dataSource={record.bookings} pagination={false} />
  </div>;

}

class LandTourBooking extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      loading: false,
      moadlVisible: false,
      visible: false,
      modalData: [],
      confirmInsuranceModalVisible: false,
      viewOrderModalVisible: false,
      confirmDirty: false,
      autoCompleteResult: [],
      selectValueNational: 1,
      toggle: false,
      noteClick: true,
      categories: '',
      designations: '',
      fileList: [],
      formDataState: null,
      isEditMode: false
    }
  }

  showModal(record) {
    console.log('record', record)
    this.setState({
      visible: true,
      modalData: this.state.data.filter((element) => {
        return element.id == record
      })
    });
  };

  handleOk = e => {
    this.setState({
      visible: false,
    });
  };

  handleCancel = e => {
    console.log(e);
    this.setState({
      visible: false,
    });
  };

  componentDidMount() {
    this.apiFetch();
    this.designationFetch();
    this.categoryFetch();
  }

  apiFetch(params = '') {
    let url = './api/v1/land_tours/bookings';
    var that = this;
    console.log(params);

    API.get(url + params)
      .then(function (response) {
        console.log(response, '------------res');
        that.setState({
          data: response.data,
          isLoading: false
        })

      })
      .catch(function (error) {
        console.log(error);
      })
      .then(function () {
        // always executed
      });
  }

  designationFetch() {
    let url = './api/v1/land_tours/designation_list';
    var that = this;

    API.get(url)
      .then(function (response) {
        that.setState({
          designations: response.data.designations
        })
      })
      .catch(function (error) {
          console.log(error);
      })
      .then(function () {
          // always executed
      });
  };

  categoryFetch() {
    let url = './api/v1/land_tours/category_list';
    var that = this;

    API.get(url)
      .then(function (response) {
        that.setState({
          categories: response.data.categories
        })
      })
      .catch(function (error) {
          console.log(error);
      })
      .then(function () {
          // always executed
      });
  };

  /************************************* isEditMode ***************************************/
  isEditMode = () => {
    this.setState(prevState => ({
      isEditMode: !prevState.isEditMode
    }))
  };

  handleBookingGroupDataChange = (type, value) => {
    this.state.modalData[0][type] = value;
    console.log('this is selected data', this.state.modalData);
    this.setState({
      modalData: this.state.modalData,
    })
  };

  handleBookingDataChange = (index, type, value) => {
    console.log(index, 'index')
    this.state.modalData[0].bookings[index][type] = value
    console.log('this is selected data', this.state.selectedData)
    this.setState({
      modalData: this.state.modalData,
    })
  };

  updateBooking = e => {
    console.log("Clicked!")
    e.preventDefault();
    const form = this.props.form;
    this.props.form.validateFieldsAndScroll((err, values) => {
      console.log('check !err', !err)
      console.log('check error values', values)
      if (!err) {
      // console.log('Received values of form: ', values);
      let outputJson = {
        land_tour_booking_group:{
          flight_departure_details: this.state.modalData[0].flight_departure_details,
          flight_return_details: this.state.modalData[0].flight_return_details,
          land_tour_bookings_attributes: this.state.modalData[0].bookings
        }
      };
      console.log("This is the outputJson", outputJson);
      var formData = jsonToFormData(outputJson);

      let url = './api/v1/land_tours/' + this.state.modalData[0].id + '/update_bookings';
      var that = this

      API.patch(url, formData)
        .then(function (response) {
          console.log(response, '------------res');
          that.setState({
            isLoading: false,
          })
          if (response.data.message == 'success'){
            that.setState({
              isEditMode: false
            })
            that.apiFetch();
            message.success('Updated');
          }else{
            message.error(response.data.error);
          }
        })
        .catch(function (error) {
          console.log(error);
          message.error('Error while updating...');
        })
        .then(function () {
          // always executed
        });
      }
    });
  };

  sendConfirmation = e => {
    e.preventDefault();

    let url = './api/v1/land_tours/' + this.state.modalData[0].id + '/confirmation';
    var that = this

    API.get(url)
      .then(function (response) {
        console.log(response, '------------res');
        that.setState({
          isLoading: false,
        })
        if (response.data.message == 'success'){
          that.apiFetch();
          message.success('Confirmation email sent!');
        }else{
          message.error(response.data.error);
        }
      })
      .catch(function (error) {
        console.log(error);
        message.error('Error while sending confirmation email...');
      })
      .then(function () {
        // always executed
      });
  };

  render() {
    const { data, modalData } = this.state;
    const { getFieldDecorator } = this.props.form;
    const landData = modalData[0];
    console.log(modalData[0], "=======modalData")

    const formItemLayout = {
      labelCol: { xl: { span: 24 }, xl: { span: 24 }, },
      wrapperCol: { xl: { span: 24 }, xl: { span: 24 }, },
    };

    const columns = [
      {
        title: 'Booking Date',
        width: 120,
        dataIndex: 'created_at',
        key: 'created_at',
      },
      {
        title: 'Order No.',
        width: 140,
        dataIndex: 'order_no',
        key: 'order_no',
      },
      {
        title: 'Ref No.',
        width: 140,
        dataIndex: 'ref_no',
        key: 'ref_no',
      },
      {
        title: 'Name',
        width: 170,
        dataIndex: 'agent_name',
        key: 'agent_name',
      },
      {
        title: 'Package',
        dataIndex: 'code',
        key: 'code',
        width: 190,
      },
      // {
      //   title: 'Price',
      //   width: 150,
      //   dataIndex: 'total_price',
      //   key: 'total_price',
      // },  
      // {
      //   title: 'Quantity',
      //   width: 150,
      //   dataIndex: 'total_quantity',
      //   key: 'total_quantity',
      // },
      // {
      //   title: 'Destination',
      //   width: 200,
      //   dataIndex: 'destination',
      //   key: 'destination',
      // },
      {
        title: 'Status',
        key: 'payment_status',
        dataIndex: 'payment_status',
        width: 80,
        render: (payment_status) => (
          <span>
            <Tag 
              color={
                payment_status == 'Paid' ? 'green' : 
                payment_status == 'Partial Paid' ? 'blue' : 
                payment_status == 'Awaiting' ? 'geekblue' : 
                payment_status == 'Pending' ? 'orange' :
                payment_status == 'Refunded' || payment_status == 'Cancelled' ? '' : 
                payment_status == 'Failed' ? 'red' : 
                ''
              } 
              key={0}>
              {payment_status}
            </Tag>
          </span>
        ),
      },
      {
        title: 'Action',
        width: 50,
        key: 'action',
        dataIndex: 'id',
        render: (record) => (
          <Button type="link" onClick={() => this.showModal(record)}>View</Button>
        ),
      },
    ];

    return (
      <div id="LandTourContainer">
        <MenuBooking selected="land_tour" />
        <div className="content">
          <p className="title">Ground Tour Bookings</p>
          <Table 
            columns={columns}
            dataSource={this.state.data}
            pagination={false}
            // expandedRowRender={expandedRowRender}
            scroll={{ x: 'max-content' }}
            className="table_warp" />
        </div>

        <Modal
          // title="Order Information"
          visible={this.state.visible}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          className="bookingModal_sty"
          okText="DOWNLOAD INVOICE"
          cancelText="RESEND CONFIRMATION"
          // cancelButtonProps={{ style: { display: 'none' } }}
          footer={
            modalData.length === 0 ? null :
              <span>
                { 
                  this.state.isEditMode ? 
                    <Button type="primary" onClick={() => { this.isEditMode() }} >CANCEL</Button> : '' 
                }
                { 
                  this.state.isEditMode ? 
                    <Button type="primary" onClick={this.updateBooking} >SAVE</Button> : '' 
                }
                { 
                  !this.state.isEditMode ? 
                    <Button type="primary" onClick={this.sendConfirmation}>DOWNLOAD CONFIRMATION</Button> : ''
                }
                { 
                  !this.state.isEditMode && landData.editable ? 
                    <Button type="primary" onClick={() => { this.isEditMode() }} >EDIT</Button> : '' 
                }
                { 
                  !this.state.isEditMode && landData.payable ? 
                    <Button type="primary"><Link to={{pathname: "/bookings/land_tours/"+landData.id+"/payment"}}>PAY NOW</Link></Button> : '' 
                }
                { 
                  !this.state.isEditMode && landData.voucher_url !== undefined ?
                    landData.voucher_url != "" ? 
                      <Button type="primary"><a href={landData.voucher_url} target="_blank">DOWNLOAD VOUCHER</a></Button> : ''
                  : ''
                }
                {
                  !this.state.isEditMode && landData.invoices !== undefined && landData.invoices.length > 0 ? 
                    landData.invoices.length > 1 ?
                      <Dropdown overlay={
                        <Menu>
                        {
                          landData.invoices.map((invoice, index) => {
                            return(
                              <Menu.Item key={index}>
                                <a href={invoice.url} target="_blank">Invoice {index + 1}</a>
                              </Menu.Item>
                            )
                          })
                        }
                        </Menu>
                      }>
                        <Button type="primary">
                          <Icon type="down"/>DOWNLOAD INVOICES
                        </Button>
                      </Dropdown> : 
                      <Button type="primary">
                        <a href={landData.invoices[0].url} target="_blank">DOWNLOAD INVOICE</a>
                      </Button> 
                  : ''
                }
                {
                  !this.state.isEditMode && landData.receipts !== undefined && landData.receipts.length > 0 ? 
                    landData.receipts.length > 1 ?
                      <Dropdown overlay={
                        <Menu>
                        {
                          landData.receipts.map((receipt, index) => {
                            return(
                              <Menu.Item key={index}>
                                <a href={receipt.url} target="_blank">Receipt {index + 1}</a>
                              </Menu.Item>
                            )
                          })
                        }
                        </Menu>
                      }>
                        <Button type="primary">
                          <Icon type="down"/>DOWNLOAD RECEIPTS
                        </Button>
                      </Dropdown> : 
                      <Button type="primary">
                        <a href={landData.receipts[0].url} target="_blank">DOWNLOAD RECEIPT</a>
                      </Button> 
                  : ''
                }
                {
                  !this.state.isEditMode && landData.credit_notes !== undefined && landData.credit_notes.length > 0 ? 
                    landData.credit_notes.length > 1 ?
                      <Dropdown overlay={
                        <Menu>
                        {
                          landData.credit_notes.map((credit_note, index) => {
                            return(
                              <Menu.Item key={index}>
                                <a href={credit_note.url} target="_blank">Credit Note {index + 1}</a>
                              </Menu.Item>
                            )
                          })
                        }
                        </Menu>
                      }>
                        <Button type="primary">
                          DOWNLOAD CREDIT NOTES <Icon type="down" />
                        </Button>
                      </Dropdown> : 
                      <Button type="primary">
                        <a href={landData.credit_notes[0].url} target="_blank">DOWNLOAD CREDIT NOTES</a>
                      </Button> 
                  : ''
                }
              </span>
          }
        >
          {
            modalData.length === 0 ? null :
              <div className="table_order">
                <h2>Order Information</h2>
                <div className="responsive_sroll">
                  <table className="booking_modal_table">
                    <tr>
                      <th>Order No.</th>
                      <td>{landData.order_no}</td>
                      <th>Hotel Name</th>
                      <td>{landData.hotel_category}</td>
                    </tr>
                    <tr>
                      <th>Package</th>
                      <td>{landData.code}</td>
                      <th>Destination</th>
                      <td>{landData.destination}</td>
                    </tr>
                    <tr>
                      <th>Tour Guide</th>
                      <td>{landData.language}</td>
                      <th>Departure Date</th>
                      <td>{landData.departure_date}</td>
                    </tr>
                    <tr>
                      <th>Status</th>
                      <td>
                        <Tag 
                          color={
                            landData.payment_status == 'Paid' ? 'green' : 
                            landData.payment_status == 'Partial Paid' ? 'blue' : 
                            landData.payment_status == 'Awaiting' ? 'geekblue' : 
                            landData.payment_status == 'Pending' ? 'orange' :
                            landData.payment_status == 'Refunded' || landData.payment_status == 'Cancelled' ? '' : 
                            ''
                          } 
                          key={0}>
                          {landData.payment_status}
                        </Tag>
                      </td>
                      <th>Rooms</th>
                      <td>{landData.rooms}</td>
                    </tr>
                    <tr> 
                      <th>Price</th>
                      <td>{landData.total_price}</td>
                      <th>Paid Amount</th>
                      <td>{landData.paid_amount}</td>
                    </tr>
                    <tr> 
                      <th>Surcharge</th>
                      <td>{landData.total_service_fee}</td>
                      <th></th>
                      <td></td>
                    </tr>
                    <tr>
                      <th colspan="1">Remark</th>
                      <td colspan="3">{landData.special_request}</td>
                    </tr>
                  </table>
                  <br/>
                  <Form {...formItemLayout} onSubmit={this.handleSubmit}>
                    { 
                      landData ? (
                        <React.Fragment>
                          <p className="title">Flight Details</p>
                          <p className="line"></p>
                          <div className="input_warp">
                            {/*************************** Flight Departure Details ***************************/}
                            <Tag color="purple">Departure Details</Tag><br/>
                            <div className="formItemWarp20">
                              <Form.Item label="">
                                {
                                  getFieldDecorator(`flight_departure_date_${landData.id}`, {
                                    rules: [
                                      {
                                        required: true,
                                        message: 'Please select departure date!',
                                      },
                                    ],
                                    initialValue: moment(landData.flight_departure_date),
                                  })(
                                    <DatePicker 
                                      placeholder="Select date" 
                                      onChange={(value) => this.handleBookingGroupDataChange('flight_departure_date', moment(value).format(dateFormat))} 
                                      disabledDate={current => { return ( (current && current < moment().add(1, "day")) || (current < moment(landData.departure_date)) ); }}
                                      disabled={!this.state.isEditMode}
                                    />
                                  )
                                }
                              </Form.Item>
                            </div>

                            <div className="formItemWarp20">
                              <Form.Item label="">
                                {
                                  getFieldDecorator(`depart_origin_destination_${landData.id}`, {
                                    rules: [
                                      {
                                        required: true,
                                        message: 'Please input origin and destination!',
                                      },
                                    ],
                                    initialValue: landData.depart_origin_destination,
                                  })(
                                    <Input placeholder="KUL-SIN" onChange={(e) => this.handleBookingGroupDataChange('depart_origin_destination', e.target.value)} disabled={!this.state.isEditMode} />
                                  )
                                }
                              </Form.Item>
                            </div>

                            <div className="formItemWarp20">
                              <Form.Item label="">
                                {
                                  getFieldDecorator(`departure_flight_no_${landData.id}`, {
                                    rules: [
                                      {
                                        required: true,
                                        message: 'Please input flight no!',
                                      },
                                    ],
                                    initialValue: landData.departure_flight_no,
                                  })(
                                    <Input placeholder="D7322" onChange={(e) => this.handleBookingGroupDataChange('departure_flight_no', e.target.value)} disabled={!this.state.isEditMode} />
                                  )
                                }
                              </Form.Item>
                            </div>

                            <div className="formItemWarp20">
                              <Form.Item label="">
                                {
                                  getFieldDecorator(`depart_dept_arrival_time_${landData.id}`, {
                                    rules: [
                                      {
                                        required: true,
                                        message: 'Please input departure and arrival time!',
                                      },
                                    ],
                                    initialValue: landData.depart_dept_arrival_time,
                                  })(
                                    <Input placeholder="1030-1130" onChange={(e) => this.handleBookingGroupDataChange('depart_dept_arrival_time', e.target.value)} disabled={!this.state.isEditMode} />
                                  )
                                }
                              </Form.Item>
                            </div>

                            {/*************************** Flight Return Details ***************************/}
                            <Tag color="purple">Return Details</Tag><br/>
                            <div className="formItemWarp20">
                              <Form.Item label="">
                                {
                                  getFieldDecorator(`flight_return_date_${landData.id}`, {
                                    rules: [
                                      {
                                        required: true,
                                        message: 'Please input date!',
                                      },
                                    ],
                                    initialValue: moment(landData.flight_return_date),
                                  })(
                                    <DatePicker 
                                      placeholder="Select date" 
                                      onChange={(value) => this.handleBookingGroupDataChange('flight_return_date', moment(value).format(dateFormat))} 
                                      disabledDate={current => { return (current && landData.flight_departure_date && current < moment(landData.flight_departure_date)); }}
                                      disabled={!this.state.isEditMode}
                                    />
                                  )
                                }
                              </Form.Item>
                            </div>
                            <div className="formItemWarp20">
                              <Form.Item label="">
                                {
                                  getFieldDecorator(`return_origin_destination_${landData.id}`, {
                                    rules: [
                                      {
                                        required: true,
                                        message: 'Please input origin and destination!',
                                      },
                                    ],
                                    initialValue: landData.return_origin_destination,
                                  })(
                                    <Input placeholder="SIN-KUL" onChange={(e) => this.handleBookingGroupDataChange('return_origin_destination', e.target.value)} disabled={!this.state.isEditMode} />
                                  )
                                }
                              </Form.Item>
                            </div>

                            <div className="formItemWarp20">
                              <Form.Item label="">
                                {
                                  getFieldDecorator(`return_flight_no_${landData.id}`, {
                                    rules: [
                                      {
                                        required: true,
                                        message: 'Please input flight no!',
                                      },
                                    ],
                                    initialValue: landData.return_flight_no,
                                  })(
                                    <Input placeholder="D4322" onChange={(e) => this.handleBookingGroupDataChange('return_flight_no', e.target.value)} disabled={!this.state.isEditMode} />
                                  )
                                }
                              </Form.Item>
                            </div>

                            <div className="formItemWarp20">
                              <Form.Item label="">
                                {
                                  getFieldDecorator(`return_departure_arrv_time_${landData.id}`, {
                                    rules: [
                                      {
                                        required: true,
                                        message: 'Please input departure and arrival time!',
                                      },
                                    ],
                                    initialValue: landData.return_departure_arrv_time,
                                  })(
                                    <Input placeholder="1230-1330" onChange={(e) => this.handleBookingGroupDataChange('return_departure_arrv_time', e.target.value)} disabled={!this.state.isEditMode} />
                                  )
                                }
                              </Form.Item>
                            </div>

                          </div>
                          <br/>
                          {
                            landData.bookings.map((booking, index) => 
                              <div>
                                <p className="title">Guest {index+1}, ref: {booking.id}</p>
                                <p className="line"></p>
                                <div className="input_warp">
                                  {/*************************** Price ***************************/}
                                  <div className="formItemWarp50">
                                    <Form.Item label="Price">
                                      <Input disabled placeholder="Price" value={booking.total_price}/>
                                    </Form.Item>
                                  </div>

                                  {/*************************** Category ***************************/}
                                  <div className="formItemWarp50">
                                    <Form.Item label="Category">
                                      {getFieldDecorator('category' + index, {
                                        initialValue: booking.category,
                                      })(
                                        <Select
                                          showSearch
                                          placeholder="Select Category"
                                          onChange={onChange}
                                          disabled
                                        >
                                          {
                                            this.state.categories.map((category) => {
                                              return (
                                                <Option key={category}>{category}</Option>
                                              )
                                            })
                                          }
                                        </Select>
                                      )}
                                    </Form.Item>
                                  </div>
                                  {/*************************** Designation ***************************/}
                                  <div className="formItemWarp50">
                                    <Form.Item label="Designation">
                                      {getFieldDecorator('designation' + index, {
                                        initialValue: booking.designation,
                                      })(
                                        <Select
                                          showSearch
                                          placeholder="Select Designation"
                                          onChange={onChange}
                                          disabled={!this.state.isEditMode}
                                        >
                                          {
                                            this.state.designations.map((designation) => {
                                              return (
                                                <Option key={designation}>{designation}</Option>
                                              )
                                            })
                                          }
                                        </Select>
                                      )}
                                    </Form.Item>
                                  </div>

                                  {/*************************** Name ***************************/}
                                  <div className="formItemWarp50">
                                    <Form.Item label="Name">
                                      <Input disabled={!this.state.isEditMode} placeholder="Name" value={booking.name}/>
                                    </Form.Item>
                                  </div>

                                  {/*************************** Date of birth ***************************/}
                                  <div className="formItemWarp50 date_sty">
                                    <Form.Item label="Date of birth">
                                      {getFieldDecorator('Date_birth' + index, {
                                        initialValue: booking.date_of_birth !== '' ? moment(booking.date_of_birth) : '',
                                      })(
                                        <DatePicker onChange={(date, dateString) => this.handleBookingDataChange(index, 'date_of_birth', dateString)} disabled={!this.state.isEditMode} />
                                      )}
                                    </Form.Item>
                                  </div>
                                
                                  {/*************************** Mobile ***************************/}
                                  <div className="formItemWarp50">
                                    <Form.Item label="Mobile">
                                      {getFieldDecorator('Mobile' + index, {
                                        initialValue: booking.mobile,
                                      })(
                                        <Input onChange={(e) => this.handleBookingDataChange(index, 'mobile', e.target.value)} placeholder="Mobile" disabled={!this.state.isEditMode} />
                                      )}
                                    </Form.Item>
                                  </div>

                                  {/*************************** Nationality ***************************/}
                                  <div className="formItemWarp50">
                                    <Form.Item label="Nationality">
                                      <Input disabled={!this.state.isEditMode} placeholder="Nationality" value={booking.nationality}/>
                                    </Form.Item>
                                  </div>

                                  {/*************************** Voucher code ***************************/}
                                  <div className="formItemWarp50">
                                    <Form.Item label="Voucher code">
                                      {getFieldDecorator('voucher_code' + index, {
                                        initialValue: booking.voucher_code,
                                      })(
                                        <Input placeholder="Voucher code" onChange={(e) => this.handleBookingDataChange(index, 'voucher_code', e.target.value)} disabled />
                                      )}
                                    </Form.Item>
                                  </div>

                                  {/*************************** Agent remark ***************************/}
                                  <div className="formItemWarp100">
                                    <Form.Item label="Agent remark">
                                      {getFieldDecorator('remark' + index, {
                                        initialValue: booking.remark,
                                      })(
                                        <Input placeholder="Agent remark" onChange={(e) => this.handleBookingDataChange(index, 'remark', e.target.value)} disabled={!this.state.isEditMode} />
                                      )}
                                    </Form.Item>
                                  </div>
                                </div>

                              </div>
                            )
                          }
                        </React.Fragment>
                      ) : (null)
                    }
                  </Form>
                </div>
              </div>
          }
        </Modal>
      </div>
    );
  }
}

const GroundTourBooking = Form.create({ name: 'LandTour' })(LandTourBooking);
export default withRouter(GroundTourBooking);