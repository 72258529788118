/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)


import React, { Component } from 'react';
import ReactDOM from 'react-dom'
import { HashRouter, Route, Switch } from 'react-router-dom';
// import createHistory from 'history/createBrowserHistory'
import { createBrowserHistory } from 'history';
import Homepage from '../src/components/pages/home';
import Partner from '../src/components/pages/home/partners';
import ListingFlight from '../src/components/pages/listing/flight';
import ListingHotel from '../src/components/pages/listing/hotel';
import ListingActivity from '../src/components/pages/listing/activity';
import ListingWifi from '../src/components/pages/listing/wifi';
import ListingSeries from '../src/components/pages/listing/series';
import ListingLandTour from '../src/components/pages/listing/land_tour';
import ListingVoucher from '../src/components/pages/listing/voucher';
import ShowFlight from '../src/components/pages/show/flight';
import ShowHotel from '../src/components/pages/show/hotel';
import ShowActivity from '../src/components/pages/show/activity';
import ShowWifi from '../src/components/pages/show/wifi';
import ShowSeries from '../src/components/pages/show/series';
import ShowLandTour from '../src/components/pages/show/land_tour';
import ShowVoucher from '../src/components/pages/show/voucher';
import "antd/dist/antd.css";
import '../src/styles.css';

import FlightBooking from '../src/components/pages/booking/flight';
import FlightBookingAddon from '../src/components/pages/booking/flight/addon';
import TourBooking from '../src/components/pages/booking/series';
import LandTourBooking from '../src/components/pages/booking/land_tour';
import ActivityBooking from '../src/components/pages/booking/activity';
import WifiBooking from '../src/components/pages/booking/wifi';
import HotelBooking from '../src/components/pages/booking/hotel';
import VoucherBooking from '../src/components/pages/booking/voucher_group';
import VoucherListing from '../src/components/pages/booking/voucher';
import CreditUsage from '../src/components/pages/booking/credit_usage';
import DedicatedPayment from '../src/components/pages/booking/dedicated_payment';
import DueInvoice from '../src/components/pages/booking/due_invoice';
import DuePayment from '../src/components/pages/booking/due_payment';
import Profile from '../src/components/pages/user/profile';
import Payment from '../src/components/pages/booking/payment';
import PurchaseCredit from  '../src/components/pages/booking/purchase_credit';
import ThirdPartyOrderPayment from  '../src/components/pages/third_party_order/payment';

import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faAngleLeft,
  faBaby,
  faBed,
  faBus,
  faBolt,
  faCalendarAlt,
  faCalendarCheck,
  faCameraRetro,
  faCheck,
  faCheckCircle,
  faChild,
  faClipboard,
  faClipboardList,
  faClock,
  faCoins,
  faDollarSign,
  faEnvelope,
  faGlobe,
  faHandHoldingUsd,
  faHistory,
  faHotel,
  faIdBadge,
  faInfoCircle,
  faLongArrowAltRight,
  faMale,
  faMapMarkerAlt,
  faMoneyBillAlt,
  faMobile,
  faPlaneDeparture,
  faPlus,
  faPhone,
  faQuestionCircle,
  faReceipt,
  faShip,
  faShoppingBag,
  faShuttleVan,
  faSuitcase,
  faTags,
  faTicketAlt,
  faTimes,
  faUserCheck,
  faUserFriends,
  faUserShield,
  faUsers,
  faUserTag,
  faUtensils,
  faWalking,
  faTimesCircle,
  faDoorOpen,
  faWifi
} from '@fortawesome/free-solid-svg-icons'

import {
  faWhatsapp
} from '@fortawesome/free-brands-svg-icons'
import ListingStatistic from '../src/components/pages/listing/statistics';

library.add(
  faAngleLeft,
  faBaby,
  faBed,
  faBus,
  faBolt,
  faCalendarAlt,
  faCalendarCheck,
  faCameraRetro,
  faCheck,
  faCheckCircle,
  faChild,
  faClipboard,
  faClipboardList,
  faClock,
  faCoins,
  faDollarSign,
  faEnvelope,
  faGlobe,
  faHandHoldingUsd,
  faHistory,
  faHotel,
  faIdBadge,
  faInfoCircle,
  faLongArrowAltRight,
  faMale,
  faMapMarkerAlt,
  faMoneyBillAlt,
  faMobile,
  faPlaneDeparture,
  faPlus,
  faPhone,
  faQuestionCircle,
  faReceipt,
  faShip,
  faShoppingBag,
  faShuttleVan,
  faSuitcase,
  faTags,
  faTicketAlt,
  faTimes,
  faUserCheck,
  faUserFriends,
  faUserShield,
  faUsers,
  faUserTag,
  faUtensils,
  faWalking,
  faWhatsapp,
  faTimesCircle,
  faDoorOpen,
  faWifi
)

// const history = createHistory()
const history = createBrowserHistory();

const App = props => (

  <HashRouter history={history}>
    {
      !history.location.pathname.includes("p_app") && !history.location.hash.includes("tpo") ? (
        <Switch>
          <Route exact path="/" component={Homepage} />
          <Route exact path="/partners" component={Partner} />
          <Route exact path="/users/purchase_credit" component={PurchaseCredit} />
          <Route exact path="/bookings/due_invoices" component={DueInvoice} />
          <Route exact path="/bookings/due_payments" component={DuePayment} />
          <Route exact path="/bookings/statistics" component={ListingStatistic} />
          <Route exact path="/payments/:id" component={Payment} />
          <Route path="/flights/:id" component={ShowFlight} />
          <Route path="/hotels/:id" component={ShowHotel} />
          <Route path="/activities/:id" component={ShowActivity} />
          <Route path="/wifis/:id" component={ShowWifi} />
          <Route path="/series/:id" component={ShowSeries} />
          <Route path="/land_tours/:id" component={ShowLandTour} />
          <Route path="/vouchers/:id" component={ShowVoucher} />
          <Route path="/flights" component={ListingFlight} />
          <Route path="/hotels" component={ListingHotel} />
          <Route path="/activities" component={ListingActivity} />
          <Route path="/wifis" component={ListingWifi} />
          <Route path="/series" component={ListingSeries} />
          <Route path="/land_tours" component={ListingLandTour} />
          <Route path="/vouchers" component={ListingVoucher} />
          <Route path="/bookings/series/:id/payment" component={DedicatedPayment} />
          <Route path="/bookings/activities/:id/payment" component={DedicatedPayment} />
          <Route path="/bookings/land_tours/:id/payment" component={DedicatedPayment} />
          <Route path="/bookings/hotels/:id/payment" component={DedicatedPayment} />
          <Route path="/bookings/wifis/:id/payment" component={DedicatedPayment} />
          <Route path="/bookings/flights/:id/addon" component={FlightBookingAddon} />
          <Route path="/bookings/flights" component={FlightBooking} />
          <Route path="/bookings/series" component={TourBooking} />
          <Route path="/bookings/land_tours" component={LandTourBooking} />
          <Route path="/bookings/activities" component={ActivityBooking} />
          <Route path="/bookings/wifis" component={WifiBooking} />
          <Route path="/bookings/hotels" component={HotelBooking} />
          <Route path="/bookings/voucher_groups" component={VoucherBooking} />
          <Route path="/bookings/vouchers" component={VoucherListing} />
          <Route path="/users/credit_usage" component={CreditUsage} />
          <Route path="/users/profile" component={Profile} />
        </Switch>
      ) : (
        <Switch>
          <Route path="/tpo/:id/payment" component={ThirdPartyOrderPayment} />
        </Switch>
      )
    }
    
  </HashRouter>

)

document.addEventListener('DOMContentLoaded', () => {
  ReactDOM.render(
    <App />,
    document.body.appendChild(document.createElement('div')),
  )
})
