import React, { Component } from 'react';
import { Menu, Dropdown, Icon, Tabs, Button, Spin } from 'antd';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import Series from './series';
import Flight from './flight';
import Hotel from './hotel';
import Sightseeing from './sightseeing';
import Attraction from './attraction';
import Wifi from './wifi';
import LandTour from './land_tour';
import Voucher from './voucher';
import Cruise from './cruise';
import pdpa from '../../../../../assets/images/pdpa.pdf'
import manual from '../../../../../assets/images/manual.pdf'
import logo from '../../../../images/hk.png';
import ice from '../../../../images/logo.png';
import API from '../../../api'
import { withRouter, Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Slider from "react-slick";

import './index.css'

const { TabPane } = Tabs;


function callback(key) {
  localStorage.setItem('SearchTabKey', key);
  //console.log(key);
}

class Search extends Component {

  constructor(props) {
    super(props);
    const prevTab = localStorage.getItem('SearchTabKey');
    this.state = {
      prevTab: prevTab === undefined || prevTab === null ? 'series' : prevTab,
      currentTab: 'series',
      user: { username: '', credits: 0, access_flight: false, access_wifi: true, access_series: false, access_activity: false, access_hotel: false, access_busferry: false, busferry_link: '', access_land_tour: false, access_voucher: true, referral_link: '', access_admin: false, access_third_party: false },
      isSearching: false,
      mobileTab: false,
      copied: false,
      user_status: false,
      services: []
    }
  }

  resize() {
    let currentHideNav = window.innerWidth;
    if (currentHideNav < 624) {
      this.setState({ mobileTab: true });
    } else {
      this.setState({ mobileTab: false });
    }
  }


  componentDidMount() {
    window.addEventListener("resize", this.resize.bind(this));
    this.resize();
    this.userFetch();
  }

  userFetch() {
    let url = './api/v1/users/details';
    var that = this;

    API.get(url)
      .then(function (response) {
        console.log(response, '------------res');
        that.setState({
          user: { username: response.data.username, credits: response.data.credits, access_flight: response.data.access_flight, access_wifi: response.data.access_wifi, access_series: response.data.access_series, access_activity: response.data.access_activity, access_hotel: response.data.access_hotel, access_busferry: response.data.access_busferry, busferry_link: response.data.busferry_link, access_land_tour: response.data.access_land_tour, access_voucher: response.data.access_voucher, referral_link: response.data.voucher_referral_link, access_admin: response.data.access_admin, access_third_party: response.data.access_third_party, access_cruise: response.data.access_cruise },
          currentTab: (that.state.prevTab === "series" || that.state.prevTab === "cruise") ? that.state.prevTab : "series",
          isLoading: false,
          user_status: response.data.status,
          services: response.data.services,
          service_tab: ""
        })

        if(!response.data.access_flight &&
          !response.data.access_wifi &&
          !response.data.access_series &&
          !response.data.access_activity &&
          !response.data.access_hotel &&
          !response.data.access_busferry &&
          !response.data.access_land_tour &&
          !response.data.access_voucher &&
          !response.data.access_third_party &&
          !response.data.access_cruise
        ){
          window.location = './users/sign_in'
        }
      })
      .catch(function (error) {
        console.log(error);
      })
      .then(function () {
        // always executed
      });
  }

  handleChange = (key) => {
    if (key === 'bus_ferry') {
      ;
    } else {
      localStorage.setItem('SearchTabKey', key);
      //console.log(key);

      let found_service = this.state.services.find(x => x.name === key);
      if (found_service !== undefined) {
        this.setState({
          service_tab: found_service.name,
          isLoading: true
        }, () => { this.handleServices(found_service); });
      }
      else {
        this.setState({
          currentTab: key,
        });
      }
    }
  }

  handleServices = (service) => {
    let outputJson = {
      service_id: service.id
    }
    console.log("This is the outputJson", outputJson);

    let url = './api/v1/users/third_party_auth/';
    var that = this;

    API.post(url, outputJson)
      .then(function (response) {
        console.log(response,'------------res');
        if (response.data.redirect_url !== null){
          if (response.data.redirect_method == "post"){
            that.redirectService(response.data.redirect_url, response.data.token)
          }
          else {
            window.location = response.data.redirect_url;
          }
        }
        else {
          console.log("redirect_url return null");
            that.setState({
              service_tab: "",
              isLoading: false
            });
        }
    })
    .catch(function (error) {
      console.log(error);
      that.setState({
        service_tab: "",
        isLoading: false
      });
    })
    .then(function () {
      // always executed
    });
  };

  redirectService = (redirect_url, token) => {
    const data = {
      token: token,
    }

    const headers = {
      'Content-Type': 'application/json'
    }

    API.post(redirect_url, data, { headers : headers, credentials: 'include' })
      .then(function (response) {
        console.log(response,'------------res');
        window.location = redirect_url;
    })
    .catch(function (error) {
      console.log(error);
    })
    .then(function () {
      // always executed
    });   
  };

  onCopy = () => {
    this.setState({
      copied: true
    });
  };

  render() {
    const { currentTab, mobileTab, isLoading } = this.state;

    const settings = {
      arrows: false,
      dots: false,
      infinite: true,
      autoplay: true,
      autoplaySpeed: 5000,
      slidesToShow: 1,
      slidesToScroll: 1,
      pauseOnHover: false,
    };

    // console.log('mobileTab---', mobileTab)
    // console.log('this.state.user_status---', this.state.user_status)

    const menu = (
      is_logged_in && this.state.user_status && this.state.user.username !== "ktic" ?
        (<Menu>
          <Menu.Item disabled={true}>Credit: {this.state.user.credits}</Menu.Item>
          {
            this.state.user.referral_link != "" ?
              <Menu.Item>
                <CopyToClipboard
                  onCopy={this.onCopy}
                  options={{message: 'Link Copied!'}}
                  text={this.state.user.referral_link}
                >
                  <Link to="#">Copy Voucher Referral Link</Link>
                </CopyToClipboard>
              </Menu.Item>
            : ""
          }
          <Menu.Divider></Menu.Divider>
          {
            this.state.user.access_admin ?
              <Menu.Item><a href="./admin/dashboard">Admin Dashboard</a></Menu.Item>
            : null
          }
          {
            this.state.user.access_series ?
              (<Menu.Item><Link to="/bookings/series">My Bookings</Link></Menu.Item>) :
              this.state.user.access_wifi ?
                (<Menu.Item><Link to="/bookings/wifis">My Bookings</Link></Menu.Item>) :
                this.state.user.access_flight ?
                  (<Menu.Item><Link to="/bookings/flight">My Bookings</Link></Menu.Item>) :
                  this.state.user.access_activity ?
                    (<Menu.Item><Link to="/bookings/activity">My Bookings</Link></Menu.Item>) : ""
          }
          {
            homepage_new.suka === 'false' ?
              <Menu.Item><Link to="/users/credit_usage">Credit Usage</Link></Menu.Item>
            : null
          }
          <Menu.Item><a href={homepage_new.pdpa} target="_blank">PDPA</a></Menu.Item>
          <Menu.Item><Link to="/users/profile">Edit Profile</Link></Menu.Item>
          <Menu.Item><a href="./user/edit">Change Password</a></Menu.Item>
          {
            homepage_new.suka === 'false' ?
              <Menu.Item><a href={homepage_new.memo} target="_blank">Memo</a></Menu.Item>
            : null
          }
          <Menu.Item><a href="./users/sign_out" >Logout</a></Menu.Item>
        </Menu>)
        :
        <Menu>
          <Menu.Item><a href={homepage_new.pdpa} target="_blank">PDPA</a></Menu.Item>
        </Menu>
    );

    const pathUrl = this.props.location.pathname;
    console.log(pathUrl, '-----pathUrl')

    return (
      <div className={homepage_new.suka === 'false' ? 'Search' : 'Search suka'}>
        {/************************ Top Banner ************************/}
        <div className="img_warp">
          {/* <img src={this.props.cover[0].img} alt="cover" /> */}
          <div className={homepage_new.suka === 'false' ? 'slider-top' : 'suka-slider-top'}>
            <Slider {...settings} >
              {
                this.props.covers.map((cover) => {
                  return (
                    <img src={cover.img} />
                  )
                })
              }
            </Slider>
          </div>
        </div>

        {/************************ Top Title ************************/}
        <div className="top_pannel">
          {/* <Button type="primary" icon="login" size={30}>
              LOGIN
          </Button> */}
          {/* <div>
              <Avatar style={{ backgroundColor: '#f9ab25' }} icon="user" />
              <span className="name_user">Jason Lim</span>
          </div> */}
          <span className= {homepage_new.suka === 'false' ? "logo_warp logo"  : "logo_warp logo_suka"}>

            {
              pathUrl === "/" ? (
                <div className="logo_warp_div"><img src={homepage_new.app_logo} alt={homepage_new.app_name} /><span>{homepage_new.suka === 'false' ? homepage_new.app_name : ''}</span></div>
              ) : (
                  <Link to="/" className="a_link">
                    <img src={homepage_new.app_logo} alt={homepage_new.app_name} /><span>{homepage_new.suka === 'false' ? homepage_new.app_name : ''}</span>
                  </Link>

                )
            }
          </span>
          {
            is_logged_in && this.state.user_status ? (
              <Dropdown overlay={menu} trigger={['click']}>
                <a className="ant-dropdown-link drop_btn" href="#">
                  {this.state.user.username} <Icon type="down" />
                </a>
              </Dropdown>
            ) : (
              <div className="nav_warp_div">
                <Link to="/partners">{is_logged_in ? '' : [ homepage_new.suka === 'false' ? 'Contact Agents' : 'Hubungi Ejen']}</Link>
              </div>
            )
          }
          
        </div>
        {/************************ Top Title ************************/}
        <div className="title">
          <p>{this.props.tagline}</p>
        </div>

        <div className={homepage_new.suka === 'false' ? "tab_warp"  : "tab_warp suka"}>
          {
            <Spin size="large" spinning={isLoading}>
              <Tabs onChange={this.handleChange} type="card" activeKey={this.state.currentTab} swipeable={true}>
                {/************************ Series Tab ************************/}
                {
                  this.state.user.access_series ? 
                    mobileTab === false ?
                    <TabPane tab={<span><FontAwesomeIcon icon="clipboard" className="tab_icon" />{homepage_new.suka === 'false' ? "SERIES TOUR" : "Pakej SUKA Kembara"}</span>} key="series">
                      <Series activeTab={this.state.currentTab} />
                    </TabPane>
                    :
                    <TabPane tab={<div><FontAwesomeIcon icon="clipboard" className="tab_icon mobile_view" /><div className="textTab">{homepage_new.suka === 'false' ? "SERIES TOUR" : "Pakej SUKA Kembara"}</div></div>} key="series">
                      <Series activeTab={this.state.currentTab} />
                    </TabPane>
                    : ''
                }

                {/************************ Cruise Tab ************************/}
                {
                  this.state.user.access_cruise ? 
                    mobileTab === false ?
                    <TabPane tab={<span><FontAwesomeIcon icon="ship" className="tab_icon" />{homepage_new.suka === 'false' ? "CRUISE TOUR" : "Pakej SUKA Cruise"}</span>} key="cruise">
                      <Cruise activeTab={this.state.currentTab} />
                    </TabPane>
                    :
                    <TabPane tab={<div><FontAwesomeIcon icon="ship" className="tab_icon mobile_view" /><div className="textTab">{homepage_new.suka === 'false' ? "CRUISE TOUR" : "Pakej SUKA Cruise"}</div></div>} key="cruise">
                      <Cruise activeTab={this.state.currentTab} />
                    </TabPane>
                    : ''
                }

                {/************************ 3rd Party Services Tab ************************/}
                {
                  homepage_new.suka === 'false' && this.state.services.length > 0 && this.state.user.access_third_party ? 
                    mobileTab === false ?
                      this.state.services.map((service) => 
                        <TabPane tab={<span>{service.logo_url != '' ? <img className="service_logo" src={service.logo_url} /> : <FontAwesomeIcon icon="ticket-alt" className="tab_icon" />}{service.name}</span>} key={service.name} disabled={this.state.service_tab == service.name }>
                        </TabPane>
                      )
                    :
                      this.state.services.map((service) => 
                        <TabPane tab={<div>{service.logo_url != '' ? <img className="service_logo" src={service.logo_url} /> : <FontAwesomeIcon icon="ticket-alt" className="tab_icon mobile_view" />}<div className="textTab">{service.name}</div></div>} key={service.name} disabled={this.state.service_tab == service.name } >
                        </TabPane>
                      )
                  : ''
                }

                {/************************ Flight Tab ************************/}
                {/*{
                  homepage_new.suka === 'false' && this.state.user.access_flight ? 
                    mobileTab === false ?
                    <TabPane tab={<span><FontAwesomeIcon icon='plane-departure' className="tab_icon" />FLIGHT</span>} key="flight">
                      <Flight activeTab={this.state.currentTab} />
                    </TabPane>
                    :
                    <TabPane tab={<div><FontAwesomeIcon icon='plane-departure' className="tab_icon mobile_view" /><div className="textTab">FLIGHT</div></div>} key="flight">
                      <Flight activeTab={this.state.currentTab} />
                    </TabPane>
                    : ''
                }*/}

                {/************************ LandTour Tab ************************/}
                {/*{
                  homepage_new.suka === 'false' && this.state.user.access_land_tour ? 
                    mobileTab === false ?
                    <TabPane tab={<span><FontAwesomeIcon icon='map-marker-alt' className="tab_icon" />GROUND TOUR</span>} key="land_tour">
                      <LandTour activeTab={this.state.currentTab} />
                    </TabPane>
                    :
                    <TabPane tab={<div><FontAwesomeIcon icon='map-marker-alt' className="tab_icon mobile_view" /><div className="textTab">GROUND TOUR</div></div>} key="land_tour">
                      <LandTour activeTab={this.state.currentTab} />
                    </TabPane>
                    : ''
                }*/}

                {/************************ Bus & Ferry Tab ************************/}
                {/*{
                  homepage_new.suka === 'false' && this.state.user.access_busferry ? 
                    mobileTab === false ?
                    <TabPane tab={<a href={this.state.user.busferry_link} ><span><FontAwesomeIcon icon='bus' className="tab_icon" /> <FontAwesomeIcon icon='ship' className="tab_icon" />BUS & Ferry</span></a>} key="bus_ferry">
                    </TabPane>
                    :
                    <TabPane tab={<a href={this.state.user.busferry_link} ><div><FontAwesomeIcon icon='bus' className="tab_icon mobile_view" /> <FontAwesomeIcon icon='ship' className="tab_icon mobile_view" /><div className="textTab">BUS & Ferry</div></div></a>} key="bus_ferry">
                    </TabPane>
                    : ''
                }*/}

                {/************************ Sightseeing Tab ************************/}
                {/*{
                  homepage_new.suka === 'false' && this.state.user.access_activity ? 
                    mobileTab === false ?
                    <TabPane tab={<span><FontAwesomeIcon icon='camera-retro' className="tab_icon" />SIGHTSEEING</span>} key="sightseeing">
                      <Sightseeing />
                    </TabPane>
                    :
                    <TabPane tab={<div><FontAwesomeIcon icon='camera-retro' className="tab_icon mobile_view" /><div className="textTab">SIGHTSEEING</div></div>} key="sightseeing">
                      <Sightseeing />
                    </TabPane>
                    : ''
                }*/}
                {/************************ Attraction Tab ************************/}
                {/*{
                  homepage_new.suka === 'false' && this.state.user.access_activity ? 
                    mobileTab === false ?
                    <TabPane tab={<span><FontAwesomeIcon icon='ticket-alt' className="tab_icon" />ATTRACTION TICKET</span>} key="attraction">
                      <Attraction />
                    </TabPane>
                    :
                    <TabPane tab={<div><FontAwesomeIcon icon='ticket-alt' className="tab_icon mobile_view" /><div className="textTab">ATTRACTION TICKET</div></div>} key="attraction">
                      <Attraction />
                    </TabPane>
                    : ''
                }*/}

                {/************************ Wifi Tab ************************/}
                {/*{
                  homepage_new.suka === 'false' && this.state.user.access_wifi ? 
                    mobileTab === false ?
                    <TabPane tab={<span><FontAwesomeIcon icon='wifi' className="tab_icon" />WIFI</span>} key="wifi">
                      <Wifi />
                    </TabPane>
                    :
                    <TabPane tab={<div><FontAwesomeIcon icon='wifi' className="tab_icon mobile_view" /><div className="textTab">WIFI</div></div>} key="wifi">
                      <Wifi />
                    </TabPane>
                    : ''
                }*/}

                {/************************ Hotel Tab ************************/}
                {/*{
                  homepage_new.suka === 'false' && this.state.user.access_hotel ? 
                    mobileTab === false ?
                    <TabPane tab={<span><FontAwesomeIcon icon='hotel' className="tab_icon" />HOTEL</span>} key="hotel">
                      <Hotel activeTab={this.state.currentTab} />
                    </TabPane>
                    :
                    <TabPane tab={<div><FontAwesomeIcon icon='hotel' className="tab_icon mobile_view" /><div className="textTab">HOTEL</div></div>} key="hotel">
                      <Hotel activeTab={this.state.currentTab} />
                    </TabPane>
                    : ''
                }*/}
                
                {/************************ Voucher Tab ************************/}
                {/*{
                  this.state.user.access_voucher ? 
                    mobileTab === false ?
                    <TabPane tab={<span><FontAwesomeIcon icon='tags' className="tab_icon" />VOUCHER</span>} key="voucher">
                      <Voucher activeTab={this.state.currentTab} />
                    </TabPane>
                    :
                    <TabPane tab={<div><FontAwesomeIcon icon='tags' className="tab_icon mobile_view" /><div className="textTab">VOUCHER</div></div>} key="voucher">
                      <Voucher activeTab={this.state.currentTab} />
                    </TabPane>
                    : ''
                }*/}
                {/* {

                  <TabPane tab={<span className="textTab_icon"><FontAwesomeIcon icon='plane-departure' className="tab_icon mobile_view" /> <FontAwesomeIcon icon='hotel' className="tab_icon mobile_view" /> <FontAwesomeIcon icon='bus' className="tab_icon mobile_view" /> <FontAwesomeIcon icon='ship' className="tab_icon" /><span className="textTab2" >COMING SOON</span></span>} disabled key="comingsoon">
                  </TabPane>
                } */}

              </Tabs>
            </Spin>
          }

        </div>

      </div>
    );
  }
}

export default withRouter(Search);
