import React, { Component } from 'react';
import { Form, Tooltip, Modal, Row, Col, PageHeader, Icon, Tag, Rate, Card, List, Avatar, Collapse, Input, Select, Button, Divider, Checkbox, Empty, DatePicker, AutoComplete, InputNumber, Skeleton, notification, Typography, Tabs, TimePicker } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './index.css';
import moment from 'moment';
import ReactMarkdown from 'react-markdown/with-html';
import master from '../../../../../images/payment/master.png';
import instant from '../../../../../images/payment/instant.png'
import fpx from '../../../../../images/payment/fpx.png';
import warning from '../../../../../images/warning.png';
import { Link, withRouter } from 'react-router-dom';
import countryList from 'react-select-country-list';
import qs from 'qs';
import API from '../../../../api';
import flightImg from '../../../../../images/flight.png';
import landImg from '../../../../../images/land.png';
import PaymentMethod from '../payment';
import PriceDetails from '../priceDetails';
import terms from '../../../../../images/series_terms.pdf';
import Fuse from 'fuse.js';

const { TabPane } = Tabs;
const { Option } = Select;
const { TextArea } = Input;
const monthFormat = 'YYYY-MM';
const dateFormat = 'YYYY-MM-DD';
const timeFormat = 'YYYY-MM-DD HH:mm';
const { Panel } = Collapse;

function onFocus() {
  console.log('focus');
}

function onBlur() {
  console.log('blur');
}

function renderOption(item) {
  return (
    <Option key={item.iata} value={item.iata + ', ' + item.name + ', ' + item.city + ', ' + item.country}>
      {item.iata + ', ' + item.name + ', ' + item.city + ', ' + item.country}
    </Option>
  );
}

class LeftPanel extends Component {

  constructor(props) {
    super(props);
    this.state = {
      // Modal state
      moreInfoModal: false,
      moreInfoModalcontent: '',
      bookingConfirmModal: false,
      confirmLoading: false,
      confirmCheck: [],
      bookingErrorModal: false,
      bookingErrorMessage: '',
      validationErrors: [],
      depart_date_disabled: true,
      rooms_disabled: true,
      pax_disabled: true,
      confirm_button_disabled: true,
      // Payment state
      method_visible: false,
      payment_type: '',
      payment_method: null,
      banklist: [],
      pbb_banklist: [],
      offline_banklist: '',
      fpxb2b_banklist: [],
      b2b_offline_banklist: '',
      banklist_select: [],
      designations: '',
      quantity: 1,
      rooms_list: [],
      rooms: '',
      language: '',
      agree: '',
      depart_date: null,
      cut_off_date: '',
      price_category: '',
      category_id: '',
      contacts: [],
      all_prices: [],
      dates_available: [],
      detail: {},
      total_adult_twin: 0,
      total_child_with_bed: 0,
      total_child_no_bed: 0,
      total_single_supp: 0,
      prices: {},
      dates_pricing: {},
      airports: [],
      fuse: null,
      flights: [],
      date_max_booking_arr: [],
      date_max_booking: 0,
      available_voucher_category: [],
      available_voucher_dates: [],
      single_supp: 0,
      room_err: '',
      room_status: '',
      paylater_disabled: true,
      sale_rep: '',
      bank_type: null,
      redirect_url: null,
      dobw_banklist: [],
    }
    this.submitForm = this.submitForm.bind(this);
    this.handlePaymentMethodChange = this.handlePaymentMethodChange.bind(this);
  };

  priceFormat(value) {
    return value === undefined ? 0 : value.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') // this is for RM X,XXX.XX format
    // return value === undefined ? 0 : value.toFixed().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') // this is for RM X,XXX format
  }

  getAge(dateString) {
    // change to depart date
    var today = new Date();
    var birthDate = new Date(dateString);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())){
      age--;
    }
    return age;
  };

  componentDidMount() {
    this.banklistFetch();
    this.designationFetch();
    this.fetchAirports();
  };

  componentDidUpdate() {
    if (this.props.dataList != this.state.detail) {
      var contacts = [];

      if(this.state.contacts.length === 0){
        for (var i = 0; i < this.props.dataList.max_booking; i++) {
          contacts.push({
            designation: 'Mr',
            name: '',
            date_of_birth: '',
            mobile: '',
            nationality: 'Malaysia',
            // passport_number: '',
            category: 'adult', 
            remark: '', 
            voucher_code: '', 
            voucher_status: '', 
            voucher_message: '', 
            voucher_prices: { price: 0, paid: 0, dta_type: '', dta_adult:0 , dta_child: 0 },
            price_types: [
                { name: 'adult', visible: true },
                { name: 'single_supplement', visible: true },
                { name: 'child_twin', visible: true },
                { name: 'child_with_bed', visible: true },
                { name: 'child_without_bed', visible: true },
                { name: 'infant', visible: true }
            ],
            visible: false
          })
        }
      } 
      else {
        contacts = this.state.contacts;
      }

      // let max_booking = this.props.dataList && this.props.dataList.max_booking;
      let max_booking = this.state.date_max_booking
      let quantity = this.state.quantity > max_booking ? max_booking : this.state.quantity

      for (var i = 0; i < contacts.length; i++) {
        if ((i < quantity) || (i < this.props.dataList.min_booking)) {
          contacts[i]['visible'] = true
        } else {
          contacts[i]['visible'] = false
        }
      }

      // Validation of category dropdown select
      if (contacts[i] && contacts[i].visible === true) {
        // for (var k = 0; k < contacts[i].price_types.length; k++) {
        //   if(contacts[i].category == "adult" || contacts[i].category == "child_twin") {
        //     total_adult_twin = this.state.total_adult_twin - 1
        //   } else {
        //     total_adult_twin = this.state.total_adult_twin
        //   }

        //   let left_child_no_bed = Math.floor(total_adult_twin / 2)
        //   let left_total_child_with_bed = 0

        //   if(total_adult_twin % 2 == 1){
        //     left_total_child_with_bed = Math.floor((total_adult_twin - 3)/2)
        //   } else {
        //     left_total_child_with_bed = Math.floor(total_adult_twin/2)
        //   }

        //   if (contacts[i].price_types[k].name == "child_without_bed" || contacts[i].price_types[k].name == "infant"){
        //     contacts[i].price_types[k].visible = (contacts[i].category == "child_without_bed") || (contacts[i].category == "infant") || ((left_total_child_with_bed - this.state.total_child_with_bed) >= 0 && (left_child_no_bed - this.state.total_child_no_bed) > 0)
        //   } else if(contacts[i].price_types[k].name == "child_with_bed"){
        //     contacts[i].price_types[k].visible = (contacts[i].category == "child_with_bed") || ((left_total_child_with_bed - this.state.total_child_with_bed) > 0 &&  (left_child_no_bed - this.state.total_child_no_bed) >= 0)
        //   } else {
        //     contacts[i].price_types[k].visible =  (contacts[i].category != "child_without_bed") || (contacts[i].category != "infant") || this.state.total_child_with_bed == 0 || (left_total_child_with_bed - this.state.total_child_with_bed) > 0
        //   }
        // }

        let child_options = contacts[i].price_types.filter(x => x.name.includes("child"))
        for (var j = 0; j < child_options.length; j++) {
          // if date_of_birth is more than 12 years old, then disable the child options
          child_options[j].visible &= (this.getAge(contacts[i].date_of_birth) <= 12)
        }

        let infant_options = contacts[i].price_types.filter(x => x.name.includes("infant"))
        for (var j = 0; j < infant_options.length; j++) {
          // if date_of_birth is more than 2 years old, then disable the infant options
          infant_options[j].visible &= (this.getAge(contacts[i].date_of_birth) <= 2)
        }
      } 

      // Set flight details
      var flights = []
      flights.push({
        flight_departure_date: '',
        depart_origin_destination: '',
        // departure_destination: '',
        departure_flight_no: '',
        depart_dept_arrival_time: '',
        // departure_arrival_time: '', 
        flight_return_date: '',
        return_origin_destination: '',
        // return_destination: '',
        return_flight_no: '',
        return_departure_arrv_time: '',
        // return_arrive_time: '', 
        // flight_departure_details: '',
        // flight_return_details: ''
      })

      this.setState({
        detail: this.props.dataList,
        contacts: contacts,
        flights: flights,
        quantity: this.props.dataList.min_booking,
      });
    }
  };

  designationFetch() {
    let url = './api/v1/land_tours/designation_list';
    var that = this;

    API.get(url)
      .then(function (response) {
        that.setState({
          designations: response.data.designations
        })
      })
      .catch(function (error) {
          console.log(error);
      })
      .then(function () {
          // always executed
      });
  };

  banklistFetch() {
    let url = './api/v1/payments/payment_method';
    var that = this;
    
    API.get(url)
      .then(function (response) {
        // console.log(response, '------------res banklist');
        that.setState({
          banklist: response.data.banklist,
          pbb_banklist: response.data.pbb_banklist,
          offline_banklist: response.data.offline_banklist,
          fpxb2b_banklist: response.data.fpxb2b_banklist,
          b2b_offline_banklist: response.data.b2b_offline_banklist,
          dobw_banklist: response.data.dobw_banklist,
        })
        that.props.getCredits(response.data.credits)
      })
      .catch(function (error) {
        console.log(error);
      })
      .then(function () {
        // always executed
      });
  };

  fetchAirports() {
    let url = homepage_new.airports_url;
    var that = this;
    // console.log(params);

    API.get(url)
    .then(function (response) {
      // console.log(response, '------------res airports');
      that.setState({
        fuse: new Fuse(response.data, {
          shouldSort: true,
          threshold: 0.3,
          location: 0,
          distance: 10,
          maxPatternLength: 32,
          minMatchCharLength: 1,
          keys: [
            {
              name: "iata",
              weight: 0.4
            },
            {
              name: "name",
              weight: 0.3
            },
            {
              name: "city",
              weight: 0.2
            },
            {
              name: "country",
              weight: 0.1
            }
          ]
        })
      })
    })
    .catch(function (error) {
        console.log(error);
    })
    .then(function () {
        // always executed
    });
  }

  /****************************** handle Search autocomplate *******************************/
  handleSearch = value => {
    this.setState({
      airports: this.state.fuse.search(value).slice(0, 20)
    });
  };

  /****************************** onOpenModal *******************************/
  onOpenModal = (modal, key, content) => {
    this.setState({
      moreInfoModal: true,
      moreInfoModalcontent: content
    });
  };

  /****************************** onCloseModal *******************************/
  onCloseModal = (modal) => {
    this.setState({
      bookingErrorModal: false,
      bookingConfirmModal: false,
      moreInfoModal: false
    });
  };

  /****************************** handlePaymentMethodChange *******************************/
  handlePaymentMethodChange(value, option) {
    let payment_method = value;
    let bank_type = ""
    let redirect_url = ""

    if (this.state.payment_type === "dobw"){
      var found_bank = this.state.banklist_select.find((val) => val[1] === payment_method);
      redirect_url = found_bank[2]
      const splitString = payment_method.split(" - ");
      payment_method = splitString[0];
      bank_type = splitString[1];
    }

    this.setState({
      payment_method: payment_method,
      bank_type: bank_type,
      redirect_url: redirect_url
    })
    // console.log("card method",value)
  };

  /****************************** handlePaymentChange *******************************/
  handlePaymentChange(event) {
    console.log(event.target.value);
    if (event.target.value === 'public_bank') {
      this.setState({
        method_visible: true,
        banklist_select: this.state.pbb_banklist,
        payment_method: null
      })
    }
    else if (event.target.value === 'public_bank_2') {
      this.setState({
        method_visible: true,
        banklist_select: this.state.pbb_banklist,
        payment_method: null
      })
    }
    else if (event.target.value === "fpx_b2c") {
      this.banklistFetch();
      this.setState({
        method_visible: true,
        banklist_select: this.state.banklist,
        payment_method: null
      })
    }
    else if (event.target.value === "fpx_b2b") {
      this.banklistFetch();
      this.setState({
        method_visible: true,
        banklist_select: this.state.fpxb2b_banklist,
        payment_method: null
      })
    }
    else if (event.target.value === "dobw") {
      this.banklistFetch();
      this.setState({
        method_visible: true,
        banklist_select: this.state.dobw_banklist,
        payment_method: null
      })
    }
    else /** if (event.target.value === 'credit_transaction') **/ {
      this.setState({
        banklist_select: [],
        method_visible: false,
        payment_method: null
      })
    }

    this.setState({
      payment_type: event.target.value
    });

    this.props.handlePaymentType(event.target.value);
  };

  /****************************** onChangePaymentCheckBox *******************************/
  onChangePaymentCheckBox(e) {
    let isChecked = e.target.checked === true ? "agree" : "";
    console.log(`checked = ${isChecked}`);
    this.setState({
      agree: isChecked
    })
  };

  /****************************** handleSubmit *******************************/
  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        console.log('Received values of form: ', values);
      }
    });
  };

  /****************************** onChangeInputNumber *******************************/
  onChangeInputNumber(value) {
    // console.log('changed', value);
  }

  handleTotalQty = (prices_value, voucher_value) => {
    this.props.handleTotalQty(prices_value, voucher_value)
  };

  handleQuantity() {
    let prices = {}
    let max_booking_arr = []
    let max_booking = 0
    let single_supp = 0
    let cut_off_date = moment().format(dateFormat);
    let child_count = this.state.contacts.filter(x => x.visible && x.category.includes("child")).length;
    let infant_count = this.state.contacts.filter(x => x.visible && x.category.includes("infant")).length;

    if (this.state.depart_date !== '' && this.state.dates_pricing && this.state.dates_pricing.length > 0) {
      let pricing = this.state.dates_pricing.find(x => x.dates.find(y => y.date.includes(this.state.depart_date)))
      if (pricing === undefined) {
        prices = {};
        cut_off_date = '';
        max_booking_arr = [];
        max_booking = 0;
        this.props.form.setFieldsValue({
          ['Departure_Date']: '',
        });
        this.props.handleDepartDate();
      }
      else{
        single_supp = parseInt(pricing.single_supplement_price)
        let pricing_date = pricing.dates.find(y => y.date.includes(this.state.depart_date));
        cut_off_date = moment(this.state.depart_date).subtract(this.state.detail.cut_off_day, 'days');
        let full_date = pricing_date.full_date;
        let quantities = pricing_date.quantities;
        max_booking_arr = pricing_date.max_booking_arr;
        max_booking = pricing_date.max_booking;
        // get prices to adult qty only
        prices = quantities.find(x => x.quantity.includes(this.state.quantity - child_count - infant_count)); 
        this.props.handleCutOffDate(cut_off_date.format(dateFormat));
        this.props.handleDepartDate(this.state.depart_date);
      }
    }
    else {
      prices = {}
    }

    this.setState({
      prices: prices,
      single_supp: single_supp,
      cut_off_date: cut_off_date,
      date_max_booking_arr: max_booking_arr,
      date_max_booking: max_booking
    }, () => { this.props.handleSingleSupp(single_supp) });
    // console.log(prices, "===== prices")

    if (prices.hasOwnProperty("quantity")) {
      // let max_booking = this.props.dataList && this.props.dataList.max_booking;
      let max_booking = this.state.date_max_booking
      let quantity = this.state.quantity > max_booking ? max_booking : this.state.quantity
      let all_prices = []
      let all_vouchers = []
      
      for (var i = 0; i < max_booking; i++) {
        if (i < quantity) {
          if (this.state.contacts[i] && this.state.contacts[i].visible === true) {
            let price_types = this.state.contacts[i]['price_types'];
            let unit_price = 0; 
            let voucher_price = 0; 
            let voucher_paid = 0; 
            let dta = 0;
            // let dta_voucher = 0;

            // Validation of category dropdown select
            // for (var k = 0; k < this.state.contacts[i].price_types.length; k++) {
            //   if(this.state.contacts[i].category == "adult" || this.state.contacts[i].category == "child_twin") {
            //     total_adult_twin = this.state.total_adult_twin - 1
            //   } else {
            //     total_adult_twin = this.state.total_adult_twin
            //   }

            //   let left_child_no_bed = Math.floor(total_adult_twin / 2)
            //   let left_total_child_with_bed = 0

            //   if(total_adult_twin % 2 == 1){
            //     left_total_child_with_bed = Math.floor((total_adult_twin - 3)/2)
            //   } else {
            //     left_total_child_with_bed = Math.floor(total_adult_twin/2)
            //   }

            //   if(this.state.contacts[i].price_types[k].name == "child_without_bed" || this.state.contacts[i].price_types[k].name == "infant"){
            //     this.state.contacts[i].price_types[k].visible = (this.state.contacts[i].category == "child_without_bed") || (this.state.contacts[i].category == "infant") || ((left_total_child_with_bed - this.state.total_child_with_bed) >= 0 &&  (left_child_no_bed - this.state.total_child_no_bed) > 0)
            //   } else if(this.state.contacts[i].price_types[k].name == "child_with_bed"){
            //     this.state.contacts[i].price_types[k].visible = (this.state.contacts[i].category == "child_with_bed") || ((left_total_child_with_bed - this.state.total_child_with_bed) > 0 &&  (left_child_no_bed - this.state.total_child_no_bed) >= 0)
            //   } else {
            //     this.state.contacts[i].price_types[k].visible =  (this.state.contacts[i].category != "child_without_bed") || (this.state.contacts[i].category != "infant") || this.state.total_child_with_bed == 0 || (left_total_child_with_bed - this.state.total_child_with_bed) > 0
            //   }
            // }

            let child_options = this.state.contacts[i].price_types.filter(x => x.name.includes("child"))
            for (var j = 0; j < child_options.length; j++) {
              // if date_of_birth is more than 12 years old, then disable the child options
              child_options[j].visible = (this.getAge(this.state.contacts[i].date_of_birth) <= 12)
            } 

            let infant_options = this.state.contacts[i].price_types.filter(x => x.name.includes("infant"))
            for (var j = 0; j < infant_options.length; j++) {
              // if date_of_birth is more than 2 years old, then disable the infant options
              infant_options[j].visible = (this.getAge(this.state.contacts[i].date_of_birth) <= 2)
            }

            price_types.map((price_type, j) => {
              if (price_type.name === "adult") {
                price_type.price = (+prices.adult);
              }
              else if (price_type.name === "single_supplement") {
                price_type.price = (+prices.adult + this.state.single_supp);
              }
              else {
                price_type.price = (prices.child_prices.find(x => x.type === price_type.name)).price
              }
            });

            // get unit price and calculate DTA based on voucher redeem success
            if (this.state.contacts[i].voucher_status == "success") {
              unit_price += (+prices.adult);
              voucher_price += (+this.state.contacts[i].voucher_prices.price);
              voucher_paid += (+this.state.contacts[i].voucher_prices.paid);

              if (this.state.contacts[i].category === "adult" || this.state.contacts[i].category === "single_supplement"){
                if (this.state.contacts[i].voucher_prices.dta_type === "Fixed") {
                  dta += this.state.contacts[i].voucher_prices.dta_adult
                }
                else {
                  dta += (this.state.contacts[i].voucher_prices.dta_adult / 100) * voucher_price
                }
              }
              else {
                if (this.state.contacts[i].voucher_prices.dta_type === "Fixed") {
                  dta += (this.state.contacts[i].voucher_prices.dta_child)
                }
                else {
                  dta += (this.state.contacts[i].voucher_prices.dta_child / 100) * voucher_price
                }
              }
            }
            else {
              if (this.state.contacts[i].category === "adult" || this.state.contacts[i].category === "single_supplement"){
                if (this.state.contacts[i].category === "adult") {
                  unit_price += (+prices.adult);
                }
                else {
                  unit_price += (+prices.adult + this.state.single_supp);
                }
                
                if (this.props.dataList.dta_prices.dta_type === "Fixed") {
                  dta += this.props.dataList.dta_prices.dta_adult
                }
                else {
                  dta += (this.props.dataList.dta_prices.dta_adult / 100) * unit_price
                }
              }
              else {
                unit_price += (prices.child_prices.find(x => x.type === this.state.contacts[i].category)).price;
                if (this.props.dataList.dta_prices.dta_type === "Fixed") {
                  dta += (this.props.dataList.dta_prices.dta_child)
                }
                else {
                  dta += (this.props.dataList.dta_prices.dta_child / 100) * unit_price
                }
              }
            }  
            
            if (this.state.contacts[i].voucher_status == "success") {
              let found_vprice = all_vouchers.find(x => x.name === this.state.contacts[i].category)
              if (found_vprice === undefined) {
                all_vouchers.push({
                  name: this.state.contacts[i].category,
                  quantity: 1,
                  price: voucher_price,
                  paid: voucher_paid,
                  dta: dta,
                });
              }
              else {
                found_vprice.quantity += 1;
              }
            }
            else {
              let found_price = all_prices.find(x => x.name === this.state.contacts[i].category)
              if (found_price === undefined) {
                all_prices.push({
                  name: this.state.contacts[i].category,
                  quantity: 1,
                  price: unit_price,
                  dta: dta,
                });
              } else {
                found_price.quantity += 1;
              }
            }
          }
        }
      } 

      let total_single_supp = 0;
      let total_adult_twin = 0;
      let total_child_with_bed = 0;
      let total_child_no_bed = 0;
      all_prices.filter(x => x.name.includes('adult') || x.name.includes('child_twin')).map((price) => { total_adult_twin += price.quantity })
      all_vouchers.filter(x => x.name.includes('adult') || x.name.includes('child_twin')).map((price) => { total_adult_twin += price.quantity })
      all_prices.filter(x => x.name.includes('child_with_bed')).map((price) => { total_child_with_bed += price.quantity })
      all_vouchers.filter(x => x.name.includes('child_with_bed')).map((price) => { total_child_with_bed += price.quantity })
      all_prices.filter(x => x.name.includes('child_without_bed') || x.name.includes('infant')).map((price) => { total_child_no_bed += price.quantity })
      all_vouchers.filter(x => x.name.includes('child_without_bed') || x.name.includes('infant')).map((price) => { total_child_no_bed += price.quantity })
      all_prices.filter(x => x.name.includes('single_supplement')).map((price) => { total_single_supp += price.quantity })
      all_vouchers.filter(x => x.name.includes('single_supplement')).map((price) => { total_single_supp += price.quantity })

      this.setState({
        all_prices: all_prices,
        all_vouchers: all_vouchers,
        contacts: this.state.contacts,
        total_adult_twin: total_adult_twin,
        total_child_with_bed: total_child_with_bed,
        total_child_no_bed: total_child_no_bed,
        total_single_supp: total_single_supp,
      }, () => { this.handleTotalQty(all_prices, all_vouchers), this.props.handleTotalSupp(total_single_supp) })
    }   
  }

  isEven(n) {
    return n % 2 == 0;
  }

  isOdd(n) {
    return Math.abs(n % 2) == 1;
  }

  handleRooms() {
    if (this.state.prices.hasOwnProperty("quantity")) {
      let contacts = this.state.contacts;
      let total_adult_twin = 0;
      let total_single_supp = 0;
      let total_child_with_bed = 0;
      let total_child_no_bed = 0;
      let double = 0;
      let rooms_arr = []
      let room_err = ""

      contacts.filter(x => (x.category.includes('adult') || x.category.includes('child_twin')) && x.visible).map((price) => { total_adult_twin += 1 })
      contacts.filter(x => (x.category.includes('single_supplement')) && x.visible).map((price) => { total_single_supp += 1 })
      contacts.filter(x => (x.name.includes('child_without_bed') || x.name.includes('infant')) && x.visible).map((price) => { total_child_no_bed += 1 })
      contacts.filter(x => (x.category.includes('child_with_bed')) && x.visible).map((price) => { total_child_with_bed += 1 })

      if (total_adult_twin > 1) {
        for (var i = 0; i <= (total_adult_twin / 3); i++) {
          let room_text = []
          double = (total_adult_twin - (3 * i)) / 2
          let left_child_without_bed = total_child_no_bed - i
          if ((this.isEven(total_adult_twin) && this.isEven(i)) || (this.isOdd(total_adult_twin) && this.isOdd(i)) && ((left_child_without_bed >= 0 && double >= (left_child_without_bed + total_child_with_bed)) || (left_child_without_bed < 0 && double >= total_child_with_bed))){
            if (i > 0) {
              room_text.push(i+" TRIPLE")
            }
            if (double > 0) {
              room_text.push(double+" TWIN/DOUBLE")
            }
            if (total_single_supp > 0){
              room_text.push(total_single_supp+" SINGLE")
            }
            rooms_arr.push(room_text.join(", "))
          }
          else {
            continue;
          }  
        }
      }

      if (rooms_arr.length == 0 || total_adult_twin == 0) {
        room_err = "Please change pax/child bed category to have a valid rooms combination";
      }

      // if (total_adult_twin > 1) {
      //   for (var i = 0; i <= (total_adult_twin / 3); i++) {
      //     let room_text = []
      //     double = (total_adult_twin - (3 * i)) / 2
      //     if ((this.isEven(total_adult_twin) && this.isEven(i)) || (this.isOdd(total_adult_twin) && this.isOdd(i))){
      //       if (i > 0) {
      //         room_text.push(i+" TRIPLE")
      //       }
      //       if (double > 0) {
      //         room_text.push(double+" TWIN/DOUBLE")
      //       }
      //       rooms_arr.push(room_text.join(", "))
      //     }
      //     else {
      //       continue;
      //     }  
      //   }
      // }

      this.props.form.setFieldsValue({
        ['Rooms']: null,
      });
      this.setState({
        rooms_list: rooms_arr,
        rooms_disabled: false,
        room_err: room_err,
        room_status: room_err != "" ? "error" : "",
      });  
    }
  }

  handleEachVoucher() {
    for (var k = 0; k < this.state.contacts.length; k++) {
      if (this.state.contacts[k] && this.state.contacts[k].visible === true && this.state.contacts[k].voucher_code !== "") {
        let found_voucher = []
        found_voucher.push(this.state.contacts.filter(x => x.voucher_code === this.state.contacts[k].voucher_code))
        if (found_voucher.flat().length === 1) {
          this.handleVoucher(this.state.contacts[k], k)
          .then(([response, index]) => {
            this.state.contacts[index]["voucher_status"] = "success";
            this.state.contacts[index]["voucher_message"] = response.success;
            this.state.contacts[index]["voucher_prices"]["price"] = Number(response.voucher_entitlement.discounted_price);
            this.state.contacts[index]["voucher_prices"]["paid"] = Number(response.voucher_price);
            this.state.contacts[index]["voucher_prices"]["dta_type"] = response.voucher_entitlement.dta_prices.dta_type;
            this.state.contacts[index]["voucher_prices"]["dta_adult"] = Number(response.voucher_entitlement.dta_prices.dta_adult);
            this.state.contacts[index]["voucher_prices"]["dta_child"] = Number(response.voucher_entitlement.dta_prices.dta_child);
            this.state.available_voucher_category.push(response.voucher_entitlement.specifiable_id)
            this.state.available_voucher_dates.push(response.voucher_entitlement.available_dates)

            this.setState({ 
              contacts: this.state.contacts,
              available_voucher_category: this.state.available_voucher_category,
              available_voucher_dates: this.state.available_voucher_dates,
            }, () => { this.handleQuantity() });
            console.log("isValid", response)
          })
          .catch(([response, index]) => {
            this.state.contacts[index]["voucher_status"] = "error";
            this.state.contacts[index]["voucher_message"] = response.error;
            this.state.contacts[index]["voucher_prices"] = { price: 0, paid: 0, dta_type: '', dta_adult:0 , dta_child: 0 };
            if (response.voucher_entitlements !== undefined) {
              this.state.available_voucher_category.push(response.voucher_entitlements.map(function (ve) { return ve.specifiable_id }))
              let av_dates = response.voucher_entitlements.filter(x => x.specifiable_id === this.state.category_id);
              this.state.available_voucher_dates.push(av_dates.map(function (ve) { return ve.available_dates }))
            }

            this.setState({
              depart_date: null,
              contacts: this.state.contacts,
              available_voucher_category: this.state.available_voucher_category,
              available_voucher_dates: this.state.available_voucher_dates,
            }, () => { this.handleQuantity() });
          })
        }
        else {
          // for duplicate voucher, the first entered voucher will be validated above, the rest will show error
          this.state.contacts[k+1]["voucher_status"] = "error";
          this.state.contacts[k+1]["voucher_message"] = "Voucher duplicated. Please enter another voucher.";
          this.state.contacts[k+1]["voucher_prices"] = { price: 0, paid: 0, dta_type: '', dta_adult:0 , dta_child: 0 };
          this.setState({ 
            contacts: this.state.contacts,
            available_voucher_category: [],
            available_voucher_dates: [],
          }, () => { this.handleQuantity() });
        }
      }
      else {
        this.state.contacts[k]["voucher_status"] = "";
        this.state.contacts[k]["voucher_message"] = "";
        this.state.contacts[k]["voucher_prices"] = { price: 0, paid: 0, dta_type: '', dta_adult:0 , dta_child: 0 };
        this.setState({ 
          contacts: this.state.contacts,
          available_voucher_category: [],
          available_voucher_dates: [],
        }, () => { this.handleQuantity() });
      }
    }
  }

  handleVoucher(contact, index) {
    const { category_id, depart_date } = this.state;

    let outputJson = {
      voucher_code: contact.voucher_code,
      category_id: category_id,
      depart_date: depart_date
    };

    let url = './api/v1/land_tours/' + this.props.dataList.id + '/check_voucher';
    var that = this;
    
    return new Promise((resolve, reject) => {
      API.post(url, outputJson)
      .then(function (response) {
        // console.log(response, "=====response")
        if(response.data.success){
          resolve([response.data, index])
        }
        else{
          reject([response.data, index])
        }
      })
      .catch(function (error) {
        console.log(error);
        reject(error)
      })
    })
  }

  handleMaxBooking() {
    const { category_id, depart_date } = this.state;

    let outputJson = {
      category_id: category_id,
      depart_date: depart_date
    };

    let url = './api/v1/land_tours/' + this.props.dataList.id + '/check_max_booking';
    var that = this;

    API.post(url, outputJson)
      .then(function (response) {
        // console.log(response.data, "====response")
        if(response.data.success){
          that.setState({
            date_max_booking_arr: response.data.max_booking_arr,
            date_max_booking: response.data.max_booking,
          })
        }
        else{
          that.setState({
            date_max_booking_arr: [],
            date_max_booking: 0,
          })
        }
      })
      .catch(function (error) {
        that.setState({
          date_max_booking_arr: [],
          date_max_booking: 0,
        })
      })
      .then(function () {
          // always executed
      });
  }

  handlePayLater() {
    const { depart_date } = this.state;

    let outputJson = {
      depart_date: depart_date
    };

    let url = './api/v1/land_tours/' + this.props.dataList.id + '/check_pay_later';
    var that = this;

    API.post(url, outputJson)
      .then(function (response) {
        // console.log(response.data, "====response")
        if(response.data.success){
          that.setState({
            paylater_disabled: false,
          })
        }
        else{
          that.setState({
            paylater_disabled: true,
          })
        }
      })
      .catch(function (error) {
        that.setState({
          paylater_disabled: true,
        })
      })
      .then(function () {
          // always executed
      });
  }

  onChangeFlightData = (index, type, value, e = '') => {
    this.state.flights[index][type] = value;
    
    this.setState({
      flights: this.state.flights,
    });
  };

  onChangeData = (index, type, value, e = '') => {
    if (type == "sale_rep") {
      this.setState({
        sale_rep: value
      });
    }
    else if (type == "quantity") {
      // let max_booking = this.props.dataList && this.props.dataList.max_booking;
      let max_booking = this.state.date_max_booking;
      let quantity = this.state.quantity > max_booking ? max_booking : this.state.quantity

      for (var i = 0; i < max_booking; i++) {
        if (i < value) {
          this.state.contacts[i]['visible'] = true
          // Reset category to "adult" if the selected option is not "adult" or "child_twin"
          if (this.state.contacts[i]['category'] === "adult" || this.state.contacts[i]['category'] === "child_twin" || this.state.contacts[i]['category'] === "single_supplement") {
            this.state.contacts[i]['category']
          } else {
            this.state.contacts[i]['category'] = "adult"
          }
        } else {
          this.state.contacts[i]['visible'] = false
          // Reset category to "adult" if the selected option is not "adult" or "child_twin"
          if (this.state.contacts[i]['category'] === "adult" || this.state.contacts[i]['category'] === "child_twin" || this.state.contacts[i]['category'] === "single_supplement") {
            this.state.contacts[i]['category']
          } else {
            this.state.contacts[i]['category'] = "adult"
          }
        }
      } 

      this.setState({
        quantity: value,
      }, () => { this.handleQuantity(), this.handleRooms(), this.handleEachVoucher(), this.handleMaxBooking() });
    }
    else if (type == "departure_date") {
      // var depart_date = value.split("-");
      this.setState({
        depart_date: value,
        pax_disabled: false,
      }, () => { this.handleQuantity(), this.handleEachVoucher(), this.handleMaxBooking(), this.handlePayLater() });
    }
    else if (type == "price_category") {
      let categories = this.props.dataList && this.props.dataList.pricing_categories;
      let dates = categories.find(x => x.category === value);
      this.setState({
        category_id: dates.id,
        price_category: value,
        dates_pricing: dates.price_dates,
        dates_available: dates.departure_dates,
        depart_date_disabled: false
      }, () => { this.handleQuantity(), this.handleEachVoucher(), this.handleMaxBooking()});
      this.props.handlePriceCategory(value);
    }
    else if (type == "rooms") {
      this.setState({
        rooms: value,
      });
    }
    else if (type == "language") {
      this.setState({
        language: value,
      });
    }
    else {
      this.state.contacts[index][type] = value;
      this.setState({ 
        contacts: this.state.contacts,
      }, () => { this.handleQuantity(), this.handleEachVoucher(), this.handleMaxBooking() });

      if (type == "category") {
        this.handleRooms();
      }
    }
  };

  onConfirmBooking = event => {
    event.preventDefault();

    let prices = this.state.prices
    let all_prices = []
    for(var i = 0; i < this.state.contacts.length; i ++){
      if (this.state.contacts[i]['visible']) {

        if (this.state.contacts[i] && this.state.contacts[i].visible === true) {
          let unit_price = 0; 
          let dta = 0;
          // get unit price and calculate DTA
          if (this.state.contacts[i].category === "adult" || this.state.contacts[i].category === "single_supplement"){
            if (this.state.contacts[i].category === "adult") {
              unit_price += (+prices.adult);
            }
            else {
              unit_price += (+prices.adult + this.state.single_supp);
            }

            if (this.props.dataList.dta_prices.dta_type === "Fixed") {
              dta += this.props.dataList.dta_prices.dta_adult
            }
            else {
              dta += (this.props.dataList.dta_prices.dta_adult / 100) * unit_price
            }
          }
          else {
            unit_price += (prices.child_prices.find(x => x.type === this.state.contacts[i].category)).price;
            if (this.props.dataList.dta_prices.dta_type === "Fixed") {
              dta += (this.props.dataList.dta_prices.dta_child)
            }
            else {
              dta += (this.props.dataList.dta_prices.dta_child / 100) * unit_price
            }
          }
          
          let found_price = all_prices.find(x => x.name === this.state.contacts[i].category)
          if (found_price === undefined) {
            all_prices.push({
              name: this.state.contacts[i].category,
              quantity: 1,
              price: unit_price,
              dta: dta,
            })
          } else {
            found_price.quantity += 1;
          }
        }
      }
    }
    this.setState({ 
      contacts: this.state.contacts,
      confirm_button_disabled: false
    })

    // setTimeout(() => {
    const { quantity, tour_id, category_id, contacts, payment_type, payment_method, validationErrors, agree, depart_date, cut_off_date, rooms, language, flights, date_max_booking, sale_rep, redirect_url, bank_type } = this.state;
    
    let outputJson = {
      booking_prices: all_prices,
      tour_id: this.props.dataList && this.props.dataList.id,
      category_id: category_id,
      depart_date: depart_date,
      rooms: rooms,
      language: language,
      sale_rep: sale_rep,
      bookings: contacts.filter(x => x.visible === true),
      flights: flights,
      payment: { gateway: payment_type, method: payment_method, bank_type: bank_type, redirect_url: redirect_url },
      fare_type: !moment().isAfter(moment(cut_off_date).subtract(2, 'days').format("YYYY-MM-DD")) ? "deposit" : "full fare",
      agree: agree
    };
    console.log("This is the outputJson", outputJson);
    
    let contactValidate = contacts.filter(x => x.visible === true)
    console.log("contactValidate", contactValidate);
    console.log("validationErrors", validationErrors);

    this.props.form.validateFieldsAndScroll((err, values) => {
      if(payment_type === "" || (payment_type === 'credit_transaction' || payment_type === 'pay_later' ? "" : payment_method === null) || agree === "" || !err === false) {
          if (!err) {
            console.log('Received values of form: ', values);
          }
          if (payment_type === "") {
              this.openNotificationWithIcon('warning','payment_method_error');
          }
          if (payment_method === null) {
              validationErrors.payment_method = "*Please select a payment method";
              this.setState({ validationErrors: validationErrors })
          } else {
              validationErrors.payment_method = null;
              this.setState({ validationErrors: validationErrors })
          }
          if (agree === "") {
              validationErrors.agree = "*Please agreed the Terms of Use and Privacy Statement";
              this.setState({ validationErrors: validationErrors })
          } else {
              validationErrors.agree = "";
              this.setState({ validationErrors: validationErrors })
          }
      } else {
        this.setState({
          bookingConfirmModal: true,
        });
      }
    });
    console.log("Check validationerros",this.state.validationErrors)
    // },100);
  };

  submitForm = event => {
    event.preventDefault();
    this.banklistFetch();

    this.setState({
      confirmLoading: true,
      confirm_button_disabled: true,
    });

    let prices = this.state.prices
    let all_prices = []
    for(var i = 0; i < this.state.contacts.length; i ++){
      // if (this.state.contacts[i]['visible']) {
        if (this.state.contacts[i] && this.state.contacts[i].visible === true) {
          let unit_price = 0; 
          let dta = 0;

          // get unit price and calculate DTA
          if (this.state.contacts[i].category === "adult" || this.state.contacts[i].category === "single_supplement"){
            if (this.state.contacts[i].category === "adult") {
              unit_price += (+prices.adult);
            }
            else {
              unit_price += (+prices.adult + this.state.single_supp);
            }
          
            if (this.props.dataList.dta_prices.dta_type === "Fixed") {
              dta += this.props.dataList.dta_prices.dta_adult
            }
            else {
              dta += (this.props.dataList.dta_prices.dta_adult / 100) * prices.adult
            }
          }
          else {
            unit_price += (prices.child_prices.find(x => x.type === this.state.contacts[i].category)).price;
            if (this.props.dataList.dta_prices.dta_type === "Fixed") {
              dta += (this.props.dataList.dta_prices.dta_child)
            }
            else {
              dta += (this.props.dataList.dta_prices.dta_child / 100) * unit_price
            }
          }
          
          let found_price = all_prices.find(x => x.name === this.state.contacts[i].category)
          if (found_price === undefined) {
            all_prices.push({
              name: this.state.contacts[i].category,
              quantity: 1,
              price: unit_price,
              dta: dta,
            })
          } else {
            found_price.quantity += 1;
          }
        }
      // }
    }
    this.setState({ 
      contacts: this.state.contacts
    })

    setTimeout(() => {
      const { quantity, tour_id, category_id, contacts, payment_type, payment_method, validationErrors, agree, depart_date,cut_off_date, rooms, language, flights, sale_rep, redirect_url, bank_type } = this.state;
      
      let outputJson = {
        booking_prices: all_prices,
        tour_id: this.props.dataList && this.props.dataList.id,
        category_id: category_id,
        depart_date: depart_date,
        rooms: rooms,
        language: language,
        sale_rep: sale_rep,
        bookings: contacts.filter(x => x.visible === true),
        flights: flights,
        payment: { gateway: payment_type, method: payment_method, bank_type: bank_type, redirect_url: redirect_url },
        fare_type: !moment().isAfter(moment(cut_off_date).subtract(2, 'days').format("YYYY-MM-DD")) ? "deposit" : "full fare",
        agree: agree
      };
      console.log("This is the outputJson", outputJson);
      
      let contactValidate = contacts.filter(x => x.visible === true)
      console.log("contactValidate", contactValidate);
      console.log("validationErrors", validationErrors);

      let url = './api/v1/land_tours/' + this.props.dataList.id + '/create_booking/';
      let that = this;

      API.post(url, outputJson)
        .then(function (response) {
            console.log(response,'------------res');
            console.log(response.data.redirect_to,'------------redirect');
            if(response.data.redirect_to){
              window.location = response.data.redirect_to
            } else if (response.data.credits_error) {
              that.openNotificationWithIcon('error','credits_error');
              that.setState({ payment_type: '', bookingConfirmModal: false, confirm_button_disabled: false })
            }
            else{
              that.setState({
                check_out_disabled: true,
                bookingErrorModal: true,
                bookingErrorMessage: response.data.error,
                confirm_button_disabled: false
              })
            }
            that.setState({
              loading: false,
              confirmLoading: false
            })
      })
      .catch(function (error) {
        console.log(error);
        that.setState({
          visible: true,
        })
      })
      .then(function () {
        // always executed
      });

    },100);
  };

  render() {
    const { bookingErrorModal, bookingErrorMessage, bookingConfirmModal, confirmLoading, confirmCheck, quantity, designations, method_visible, contacts, banklist_select, offline_banklist, payment_type, payment_method, validationErrors, agree, detail, depart_date, dates_available, rooms, language, rooms_list, airports, flights, cut_off_date, date_max_booking_arr, available_voucher_category, available_voucher_dates, paylater_disabled, sale_rep, bank_type } = this.state;
    const { getFieldDecorator } = this.props.form;
    // console.log(contacts, ">>>> land tour contacts")
    
    /********************************* formItemLayout ******************************/
    const formItemLayout = {
      labelCol: { xl: { span: 24 }, xl: { span: 24 }, },
      wrapperCol: { xl: { span: 24 }, xl: { span: 24 }, },
    };

    const voucher_categories = [...new Set(available_voucher_category.flat())]
    const voucher_dates = [...new Set(available_voucher_dates.flat(2))]

    const price_columns = [{
      title: 'Departure Dates',
      colSpan: 1,
      dataIndex: 'date',
      width: 200
    }];
    const price_datas = [];

    if (detail.pricing_qty && detail.pricing_qty.length > 0) {
      detail.pricing_qty.map((q, i) => {
        price_columns.push({
          title: q.quantity.includes("..") ? (q.quantity.replace("..", "-") + " Pax") : (q.quantity + " Pax"),
          dataIndex: q.quantity.includes("..") ? q.quantity.replace("..", "-") : q.quantity,
          align: 'center',
          width: 100,
        })
      })
    }

    if (detail.pricing_categories && detail.pricing_categories.length > 0) {
      let index = 0;
      detail.pricing_categories.map((category, i) => {
        let price_rows = category.price_dates.filter(x => x.dates.length > 0);
        price_rows.map((price_date, j) => {
          let dates = []
          let qty_arr = [{}]
          let quantities = price_date.dates[0].quantities; 

          price_date.dates.map((date, k) => {
            dates.push(date.full_date)
          })

          quantities.map((qty, m) => {
            qty_arr[qty.quantity_str.replace("..", "-")] = "RM "+qty.adult
            qty_arr["dta_"+qty.quantity_str.replace("..", "-")] = "RM "+this.priceFormat(qty.dta_adult)
          })
          
          let all_datas = [{
            key: index,
            rowSpan: j == 0 ? price_rows.length : 0,
            name: category.category,
            date: Object.values(dates)
          }]
          price_datas.push(Object.assign(all_datas[0], qty_arr))
          index++;
        })
      })
    }
    // console.log(price_datas, "==========price_datas")

    return (
      <div className="LeftPanel">
        <div className="hotel_details">
          {/**************************** header title ****************************/}
          <div className="header_title">
            <h1>{detail.title}</h1>
            <Tag color="orange">Code : <strong>{detail.code}</strong></Tag> 
            <Tag color="orange">Category : <strong>{detail.category}</strong></Tag>
            <br/><br/>
          </div>

          {/**************************** Things to know ****************************/}
          <Card className="things">
            <div className="quick-info">
              <p className="title">Things to know</p>
              <Row gutter={2}>
                {
                  detail.cut_off_day === "" ? (
                    null
                  ) : (
                    <Col className="gutter-row" md={8} lg={24}>
                      <div className="gutter-box" align="left">
                        <p><FontAwesomeIcon fixedWidth icon="clock" />&nbsp; <span className="cutoff">Balance Payment Deadline :&nbsp;</span>{detail.cut_off_day} day(s)</p>
                      </div>
                    </Col>
                  )
                }
                {
                  detail.guide_languages === "" ? (
                    null
                  ) : (
                    <Col className="gutter-row" md={12} lg={8}>
                      <div className="gutter-box" align="left">
                        <p><FontAwesomeIcon fixedWidth icon="walking" />&nbsp; Tour Guide : {detail.guide_languages}</p>
                      </div>
                    </Col>
                  )
                }
                {
                  detail.free_of_charge === 0 ? (
                    null
                  ) : (
                    <Col className="gutter-row" md={12} lg={8}>
                      <div className="gutter-box" align="left">
                        <p><FontAwesomeIcon fixedWidth icon="user-tag" />&nbsp; {detail.free_of_charge > 1 ? detail.free_of_charge - 1 : detail.free_of_charge} + 1 Half Twin <strong>FOC</strong></p>
                      </div>
                    </Col>
                  )
                }
                {
                  detail.deposit === "" || detail.deposit === 0 ? (
                    null
                  ) : (
                    <Col className="gutter-row" md={12} lg={8}>
                      <div className="gutter-box" align="left">
                        <p><FontAwesomeIcon fixedWidth icon="money-bill-alt" />&nbsp; <strong>Deposit</strong>: {detail.deposit_text}</p>
                      </div>
                    </Col>
                  )
                }
                {
                  this.state.paylater_disabled === true ? (
                    null
                  ) : (
                  <Col className="gutter-row" md={12} lg={8}>
                      <div className="gutter-box" align="left">
                        <span className="info_icon"><FontAwesomeIcon fixedWidth icon="coins" /></span>
                        <span onClick={() => this.onOpenModal('moreInfoModal', 0, 'This booking is accepted "Book Now, Pay Later". You are able to hold your booking at the same fare and make payment later up to 72 hours, booking will be auto-released if no payment received within 72 hours.')} className="content_text">&nbsp; <a>Book Now, Pay Later</a></span>
                      </div>
                  </Col>
                  )
                }
                {
                  detail.inc_hotel === false ? (
                    null
                  ) : (
                    <Col className="gutter-row" md={12} lg={8}>
                      <div className="gutter-box" align="left">
                        <p><FontAwesomeIcon fixedWidth icon="bed" />&nbsp; Hotel(s) Included</p>
                      </div>
                    </Col>
                  )
                }
                {
                  detail.inc_flight === false ? (
                    null
                  ) : (
                    <Col className="gutter-row" md={12} lg={8}>
                      <div className="gutter-box" align="left">
                        <p><FontAwesomeIcon fixedWidth icon="plane-departure" />&nbsp; Flight(s) Included</p>
                      </div>
                    </Col>
                  )
                }
                {
                  detail.inc_half_board_meals === false ? (
                    null
                  ) : (
                    <Col className="gutter-row" md={12} lg={8}>
                      <div className="gutter-box" align="left">
                        <p><FontAwesomeIcon fixedWidth icon="utensils" />&nbsp; Half board Meal(s) Included</p>
                      </div>
                    </Col>
                  )
                }
                {
                  detail.inc_full_board_meals === false ? (
                    null
                  ) : (
                    <Col className="gutter-row" md={12} lg={8}>
                      <div className="gutter-box" align="left">
                        <p><FontAwesomeIcon fixedWidth icon="utensils" />&nbsp; Full board Meal(s) Included</p>
                      </div>
                    </Col>
                  )
                }
                {
                  detail.inc_entrance_ticket === false ? (
                    null
                  ) : (
                    <Col className="gutter-row" md={12} lg={8}>
                      <div className="gutter-box" align="left">
                        <p><FontAwesomeIcon fixedWidth icon="ticket-alt" />&nbsp; Entrance Ticket(s) Included</p>
                      </div>
                    </Col>
                  )
                }
                {
                  detail.inc_transportation === false ? (
                    null
                  ) : (
                    <Col className="gutter-row" md={12} lg={8}>
                      <div className="gutter-box" align="left">
                        <p><FontAwesomeIcon fixedWidth icon="bus" />&nbsp; Transportation Included</p>
                      </div>
                    </Col>
                  )
                }
                {
                  detail.inc_shopping_stop === false ? (
                    null
                  ) : (
                    <Col className="gutter-row" md={12} lg={8}>
                      <div className="gutter-box" align="left">
                        <p><FontAwesomeIcon fixedWidth icon="shopping-bag" />&nbsp; Shopping Stop Included</p>
                      </div>
                    </Col>
                  )
                }
                <Col className="gutter-row" md={12} lg={8}>
                  <div className="gutter-box" align="left">
                    <p><FontAwesomeIcon fixedWidth icon="bolt" />&nbsp; Instant Confirmation</p>
                  </div>
                </Col>  
              </Row>
            </div>
          </Card>

          {/**************************** Description ****************************/}
          {
            detail.highlight === undefined || detail.highlight === "" ? (
              null
            ) : (
              <Card className="things">
                <p className="title">Highlight</p>
                <div dangerouslySetInnerHTML={{ __html: detail.highlight.replace(/\n/g, '<br />') }} />
              </Card>
            )
          }
          {
            detail.description === undefined || detail.description === "" ? (
              null
            ) : (
              <Card className="things">
                <p className="title">Description</p>
                <div dangerouslySetInnerHTML={{ __html: detail.description.replace(/\n/g, '<br />') }} />
                <br/>
                {
                  detail.cn_description === undefined || detail.cn_description === null ? (
                    null
                  ) : (
                   <div dangerouslySetInnerHTML={{ __html: detail.cn_description.replace(/\n/g, '<br />') }} />
                  )
                }
              </Card>
            )
          }
          {/**************************** Terms & Conditions ****************************/}
          {
            detail.terms_and_conditions === undefined || detail.terms_and_conditions === "" ? (
              null
            ) : (
              <Card className="things">
                <p className="title">Remarks</p>
                <div dangerouslySetInnerHTML={{ __html: detail.terms_and_conditions.replace(/\n/g, '<br />') }} />
              </Card>
            )
          } 

          {/**************************** Itinerary ****************************/}
          {
            detail.itinerary === undefined || detail.itinerary === "" ? (
              null
            ) : (
              <Card className="things">
                <p className="title">Itinerary</p>
                <Collapse accordion expandIconPosition="right" >
                {
                  detail.itinerary.map((item, i) => {
                    let iti_title = item.day_no+" "+item.title;
                    return (
                      <Panel header={iti_title} key={i} className="itinerary_title">
                        <div dangerouslySetInnerHTML={{ __html: item.description.replace(/\n/g, '<br />') }} />
                        <br/>
                        <Row>
                          {
                            item.cities.length > 0 ? (
                              <Col span={8}>
                                <strong>Hotel In</strong>
                                {
                                  item.cities.map((city, i) => 
                                    (                            
                                      <p key={i}>{city.city}</p>
                                    )
                                  )
                                }
                              </Col>
                            ) : (null)
                          }
                          {
                            item.inclusion !== "" ? (
                              <Col span={12}>
                                <strong>Meal(s)</strong>
                                <p>{item.inclusion}</p>
                              </Col>
                            ) : (null)
                          }
                          
                        </Row>
                      </Panel>
                    )
                  })
                }
                </Collapse>
                <br/><p>*** Final sequences of the itinerary is subject to local ground final arrangement. ***</p>
              </Card>
            )
          }   

          {/************************************************ Form ************************************************/}
          <Form {...formItemLayout} onSubmit={this.onConfirmBooking} className="form_sty_select" >
            {/**************************** Your Booking ****************************/}
            <div className="header_title">
              <h2>Your Booking</h2>
            </div>
            <Card title="Booking Selection" className="booking">
              <p className="title_sub">Price Details</p>
              <PriceDetails columns={price_columns} data={price_datas} title={<React.Fragment>Hotel Category <br/>Or Similar Class</React.Fragment>} />
              <br/><p>Prices are quoted per person in Ringgit Malaysia (MYR).</p>

              <Divider />

              <Row type="flex">
                <div className="form_booking land_booking_form p_r">
                  <Form.Item label="Category" >
                    {getFieldDecorator('Category', {
                      rules: [{ required: true, message: '*Please fill in the category', }],
                    })(
                      <Select placeholder="Select Category" onChange={(value) => this.onChangeData(0, 'price_category', value)}>
                        {
                          detail.pricing_categories === undefined ? (
                            null
                          ) : (
                            detail.pricing_categories.map((item) => {
                              return (
                                <Option key={item.category} value={item.category} disabled={voucher_categories.length > 0 && !voucher_categories.includes(item.id)}>{item.category}</Option>
                              )
                            })
                          )
                        }
                      </Select>
                    )}
                  </Form.Item>
                </div>

                <div className="form_booking land_booking_form p_l">
                  <Form.Item label="Travelling Period" >
                    { getFieldDecorator('Departure_Date', {
                      // initialValue: moment(detail.departure_dates && detail.departure_dates[0], dateFormat),
                      // initialValue: moment(detail && detail.default_depart_date, dateFormat),
                      rules: [{ required: true, message: '*Please select the travelling period', }],
                    })(
                      <DatePicker 
                        defaultPickerValue={moment().isAfter(moment.min(dates_available.map(d => moment(d)))) ? moment().add(7, "day") : moment.min(dates_available.map(d => moment(d)))}
                        placeholder="Select travelling period" 
                        onChange={(value) => this.onChangeData(0, 'departure_date', moment(value).format(dateFormat))} 
                        disabledDate={current => { return ( (current && current < moment().add(7, "day")) || (current && dates_available && (( typeof dates_available.find(bd=>current.isSame(bd, 'day')) != "undefined" ) ? false : true )) || (current && voucher_dates.length > 0 && (( typeof voucher_dates.find(vd=>current.isSame(vd, 'day')) != "undefined" ) ? false : true )) ); }}
                        disabled={this.state.depart_date_disabled}
                      />
                    )}
                  </Form.Item>
                </div>

                <div className="form_booking land_booking_form p_r">
                  <Form.Item label="No of Pax" >
                    {getFieldDecorator('quantity', {
                      // initialValue: quantity,
                      rules: [{ required: true, message: '*Please select no of pax', }],
                    })(
                      <Select placeholder="Select Pax" onChange={(value) => this.onChangeData(0, 'quantity', value)} disabled={this.state.pax_disabled} >
                        {
                          date_max_booking_arr.length === undefined ? (
                            null
                          ) : (
                            date_max_booking_arr.map((item, i) => {
                              return (
                                <Option key={item} value={item}>{item} pax</Option>
                              )
                            })
                          )
                        }
                      </Select>
                    )}
                  </Form.Item>
                </div>
                
                <div className="form_booking land_booking_form p_l">
                  <Form.Item label="Language" >
                    {getFieldDecorator('Language', {
                      rules: [{ required: true, message: '*Please select language', }],
                    })(
                      <Select placeholder="Select Language" onChange={(value) => this.onChangeData(0, 'language', value)}>
                        {
                          detail.languages === undefined ? (
                            null
                          ) : (
                            detail.languages.map((item, j) => {
                              return (
                                <Option key={j} value={item}>{item}</Option>
                              )
                            })
                          )
                        }
                      </Select>
                    )}
                  </Form.Item>
                </div>

                <div className="form_booking land_booking_form p_r">
                  <Form.Item label="Sales person" >
                    {getFieldDecorator('Sales person', {
                      rules: [{ required: true, pattern: new RegExp("^[A-Za-z]{1}[A-Za-z0-9\\s\\-\\.]{2,}$"), message: '*Please fill in the sales person', }],
                    })(
                      <Input onChange={(event) => this.onChangeData(0, 'sale_rep', event.target.value)} placeholder="Sales person" />
                    )}
                  </Form.Item>
                </div>
              </Row>

              <Divider />

              <p className="title_sub">Flight Detail(s)</p>
              <Tag color="purple">Departure Detail(s)</Tag><br/>
              <Row type="flex">
                {/*<div className="form_booking land_booking_form_full">
                  <Form.Item label="Departure Detail(s)" >
                    {getFieldDecorator('flight_departure_details', {
                      rules: [{ required: true, message: '*Please enter flight departure details.', }],
                    })(
                      <Input onChange={(event) => this.onChangeFlightData(0, 'flight_departure_details', event.target.value)} placeholder="13 MAR 2020 KUL-SIN D7 678 1240-1340" />
                    )}
                  </Form.Item>
                </div>*/}

                <div className="form_booking land_flight_form p_r">
                  <Form.Item label="" >
                    {getFieldDecorator('depart_date', {
                      // initialValue: moment(this.state.depart_date, dateFormat),
                      // rules: [{ required: true, message: '*Please select date', }],
                    })(
                      <DatePicker 
                        placeholder="Select date" 
                        onChange={(value) => this.onChangeFlightData(0, 'flight_departure_date', moment(value).format(dateFormat))} 
                        disabledDate={current => { return ( (current && current < moment().add(1, "day")) || (current && dates_available && dates_available.every((bd)=>{ return current.isSame(bd, 'day') ? !(moment(bd).diff(moment().startOf('day'), 'weeks') >= 1) : true; }) ) ); }}
                      />
                    )}
                  </Form.Item>
                </div>
                <div className="form_booking land_flight_form p_r">
                  <Form.Item label="" >
                    {getFieldDecorator('depart_origin_destination', {
                      // rules: [{ required: true, message: '*Please enter origin and destination', }],
                    })(
                      <Input onChange={(event) => this.onChangeFlightData(0, 'depart_origin_destination', event.target.value)} placeholder="SIN-KUL" />
                    )}
                  </Form.Item>
                </div>
                <div className="form_booking land_flight_form p_r">
                  <Form.Item label="" >
                    {getFieldDecorator('depart_flight_no', {
                      // rules: [{ required: true, message: '*Please enter flight no.', }],
                    })(
                      <Input onChange={(event) => this.onChangeFlightData(0, 'departure_flight_no', event.target.value)} placeholder="D7 673" />
                    )}
                  </Form.Item>
                </div>
                <div className="form_booking land_flight_form p_r">
                  <Form.Item label="" >
                    {getFieldDecorator('depart_dept_arrival_time', {
                      // rules: [{ required: true, message: '*Please enter arrival time', }],
                    })(
                      <Input onChange={(event) => this.onChangeFlightData(0, 'depart_dept_arrival_time', event.target.value)} placeholder="1030-1130" />
                    )}
                  </Form.Item>
                </div>
              </Row>

              <Tag color="purple">Return Detail(s)</Tag><br/>
              <Row type="flex">
                {/*<div className="form_booking land_booking_form_full">
                  <Form.Item label="Return Detail(s)" >
                    {getFieldDecorator('flight_return_details', {
                      // rules: [{ required: true, message: '*Please enter flight return details.', }],
                    })(
                      <Input onChange={(event) => this.onChangeFlightData(0, 'flight_return_details', event.target.value)} placeholder="15 MAR 2020 SIN-KUL D7 673 1030-1130" />
                    )}
                  </Form.Item>
                </div>*/}
                <div className="form_booking land_flight_form p_r">
                  <Form.Item label="" >
                    {getFieldDecorator('return_date', {
                      // rules: [{ required: true, message: '*Please select date', }],
                    })(
                      <DatePicker 
                        placeholder="Select date" 
                        onChange={(value) => this.onChangeFlightData(0, 'flight_return_date', moment(value).format(dateFormat))} 
                        disabledDate={current => { return (current && flights[0].flight_departure_date && current < moment(flights[0].flight_departure_date)); }}
                      />
                    )}
                  </Form.Item>
                </div>
                <div className="form_booking land_flight_form p_r">
                  <Form.Item label="" >
                    {getFieldDecorator('return_origin_destination', {
                      // rules: [{ required: true, message: '*Please enter origin and destination', }],
                    })(
                      <Input onChange={(event) => this.onChangeFlightData(0, 'return_origin_destination', event.target.value)} placeholder="KUL-SIN" />
                    )}
                  </Form.Item>
                </div>
                <div className="form_booking land_flight_form p_r">
                  <Form.Item label="" >
                    {getFieldDecorator('return_flight_no', {
                      // rules: [{ required: true, message: '*Please enter flight no.', }],
                    })(
                      <Input onChange={(event) => this.onChangeFlightData(0, 'return_flight_no', event.target.value)} placeholder="D5 673" />
                    )}
                  </Form.Item>
                </div>
                <div className="form_booking land_flight_form p_r">
                  <Form.Item label="" >
                    {getFieldDecorator('return_departure_arrv_time', {
                      // rules: [{ required: true, message: '*Please enter departure time', }],
                    })(
                      <Input onChange={(event) => this.onChangeFlightData(0, 'return_departure_arrv_time', event.target.value)} placeholder="0930-1030" />
                    )}
                  </Form.Item>
                </div>
              </Row>

              <div className="clear"></div>
            </Card>

            {/**************************** Passenger ****************************/}
            <div className="header_title">
              <h2>Contact Info</h2>
            </div>
            <Card className="passenger">
            {
              contacts.map((contact, contact_index) => {
                return (
                contact.visible === true ?
                  <div key={contact_index} className="passenger_warp">
                    <div className="form_passenger ">
                      <PageHeader
                        tags={<Tag color="#1890ff">Contact {contact_index + 1}</Tag>}
                      >
                        <Row type="flex">
                          <div className="form_control pandding_right">
                            <Form.Item label="Designation" >
                              {getFieldDecorator('designation' + contact_index, {
                                initialValue: 'Mr',
                                rules: [{ required: contact_index == 0 ? true : false, message: '*Please select the designation', }],
                              })(
                                <Select setFieldsValue={this.state.contacts[contact_index]['designation'] !== '' ? this.state.contacts[contact_index]['designation'] : ''} /* className={validationErrors.designation ? 'error_border' : ''} */ placeholder="Select designation" optionFilterProp="children"
                                  onChange={(value) => this.onChangeData(contact_index, 'designation', value)}
                                  filterOption={(input, option) =>
                                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                  }
                                >
                                  {
                                    designations && designations.map((items, i) => {
                                      return (
                                        <Option key={i} value={items}>{items}</Option>
                                      )
                                    })
                                  }
                                </Select>
                              )}
                            </Form.Item>
                          </div>

                          <div className="form_control pandding_left">
                            <Form.Item label="Name on passport" >
                              {getFieldDecorator('name' + contact_index, {
                                setFieldsValue: this.state.contacts[contact_index]['name'] !== '' ? this.state.contacts[contact_index]['name'] : '',
                                rules: [{ required: contact_index == 0 ? true : false, message: '*Please fill in the name', }],
                              })(
                                <Input /* className={validationErrors.name ? 'error_border' : ''} */ onChange={(event) => this.onChangeData(contact_index, 'name', event.target.value)} placeholder=" Passport name" />
                              )}
                            </Form.Item>
                          </div>

                          <div className="form_control pandding_right">
                            <Form.Item label="Date of birth" >
                              {getFieldDecorator('date_of_birth' + contact_index, {
                                initialValue: this.state.contacts[contact_index]['date_of_birth'] !== '' ? moment(this.state.contacts[contact_index]['date_of_birth']) : '',
                                rules: [{ required: contact_index == 0 ? true : false, message: '*Please select the date of birth', }],
                              })(
                                <DatePicker disabledDate={ current => { return current < moment().add(-100,'year').startOf('day') || current > moment(depart_date, 'DD/MM/YYYY').toDate() }} /* className={validationErrors.date_of_birth ? 'error_border' : ''} */ onChange={(value) => this.onChangeData(contact_index, 'date_of_birth', moment(value).format('YYYY/MM/DD'))} placeholder="Select date of birth" />
                              )}
                            </Form.Item>
                          </div>

                          <div className="form_control pandding_left">
                            <Form.Item label="Mobile number" >
                              {getFieldDecorator('mobile' + contact_index, {
                                setFieldsValue: this.state.contacts[contact_index]['mobile'] !== '' ? moment(this.state.contacts[contact_index]['mobile']) : '',
                                rules: [{ required: contact_index == 0 ? true : false, message: '*Please fill in the mobile number', }],
                              })(
                                <Input onChange={(event) => this.onChangeData(contact_index, 'mobile', event.target.value)} placeholder="Mobile number" />
                              )}
                            </Form.Item>
                          </div>

                          <div className="form_control pandding_right">
                            <Form.Item label="Nationality" >
                              {getFieldDecorator('nationality' + contact_index, {
                                initialValue: this.state.contacts[contact_index]['nationality'],
                                rules: [{ required: contact_index == 0 ? true : false, message: '*Please select nationality', }],
                              })(
                                <Select showSearch placeholder="Select Nationality"
                                  onChange={(value) => this.onChangeData(contact_index, 'nationality', value)}
                                  filterOption={(input, option) =>
                                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                  }
                                >
                                  {
                                    countryList().getData().map((ctry) => {
                                      return (
                                        <Option key={ctry.label} value={ctry.label}>{ctry.label}</Option>
                                      )
                                    })
                                  }
                                </Select>
                              )}
                            </Form.Item>
                          </div>

                          <div className="form_control pandding_left">
                            <Form.Item label="Category" >
                              {getFieldDecorator('category' + contact_index, {
                                initialValue: this.state.contacts[contact_index]['category'],
                                rules: [{ required: contact_index == 0 ? true : false, message: '*Please select category', }],
                              })(
                                <Select placeholder="Select" optionFilterProp="children"
                                  onChange={(value) => this.onChangeData(contact_index, 'category', value)} 
                                  /* onFocus={onFocus} onBlur={onBlur}*/ 
                                  filterOption={(input, option) =>
                                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                  }
                                >
                                  {
                                    contact && contact.price_types.map((item, i) => {
                                      return (
                                        <Option disabled={!item.visible} key={i} value={item.name}>{item.name === "child_with_bed" ? ("Child with extra bed") : (item.name.charAt(0).toUpperCase() + item.name.substr(1).toLowerCase().replace(/_/g, " "))} (RM {this.priceFormat(item.price)})</Option>
                                      )
                                    })
                                  }
                                </Select>
                              )}
                            </Form.Item>
                          </div>

                          <div className="form_control pandding_right">
                            <Form.Item label="Remark">
                              {getFieldDecorator('remark' + contact_index)(
                                <TextArea
                                  setfieldsvalue={this.state.contacts[contact_index]['remark'] !== '' ? this.state.contacts[contact_index]['remark'] : ''}
                                  onChange={(event) => this.onChangeData(contact_index, 'remark', event.target.value)}
                                  placeholder="Remark - vegetarian"
                                  autoSize={{ minRows: 1, maxRows: 3 }}
                                />
                              )}
                            </Form.Item>
                          </div>

                          <div className="form_control pandding_left voucher_field">
                            <Form.Item label="Voucher Code" 
                              hasFeedback
                              validateStatus={this.state.contacts[contact_index]['voucher_status']}
                              help={this.state.contacts[contact_index]['voucher_message']}
                            >
                              {getFieldDecorator('voucher_code' + contact_index, {
                                setFieldsValue: this.state.contacts[contact_index]['voucher_code'] !== '' ? this.state.contacts[contact_index]['voucher_code'] : ''
                              })(
                                <Input onChange={(event) => this.onChangeData(contact_index, 'voucher_code', event.target.value)} placeholder=" Voucher Code" />
                              )}
                            </Form.Item>
                          </div>
                        </Row>
                      </PageHeader>
                    </div>
                  </div> : ''
                  )
              })
            }
            </Card>

            {/**************************** Room Selection ****************************/}
            <div className="header_title">
              <h2>Rooms</h2>
            </div>
            <Card title="Room Selection" className="booking">
              <div className="form_booking land_booking_form p_r voucher_field">
                <Form.Item label="Rooms" hasFeedback validateStatus={this.state.room_status} help={this.state.room_err} >
                  {getFieldDecorator('Rooms', {
                    // initialValue: '',
                    rules: [{ required: true, message: '*Please select rooms', }],
                  })(
                    <Select placeholder="Select Room" onChange={(value) => this.onChangeData(0, 'rooms', value)} disabled={this.state.rooms_disabled}>
                      {
                        rooms_list === undefined ? (
                          null
                        ) : (
                          rooms_list.map((item, i) => {
                            return (
                              <Option key={i} value={item}>{item}</Option>
                            )
                          })
                        )
                      }
                    </Select>
                  )}
                </Form.Item>
              </div>
              <div className="clear"></div>
            </Card>

            {/**************************** Payment Method ****************************/}
            <div className="header_title">
              <h2>Your Payment Method</h2>
            </div>
            {/* fill in the api data in PaymentMethod component  */}
            <PaymentMethod handlePaymentChange={this.handlePaymentChange.bind(this)} no_credit_payment={true} credit_disabled={this.props.credit_disabled} fpx_disabled={false} paymentTypeAvailable={4} paylater_disabled={paylater_disabled} service_fee={this.props.service_fee} final_price={this.props.final_price}
              payment_type={payment_type} payment_method={payment_method} credits={this.props.credits} method_visible={method_visible} validationErrors={validationErrors} banklist_select={banklist_select}
              offline_banklist={offline_banklist} handlePaymentMethodChange={(value, option) => this.handlePaymentMethodChange(value, option)}
              onChangePaymentCheckBox={(e) => this.onChangePaymentCheckBox(e)} terms={homepage_new.ground_terms} fpxb2b_disabled={!detail.fpxb2b_available} allow_fpxb2c_overlimit={true} dobw_disabled={!detail.dobw_available} fpxb2c_disabled={!detail.fpxb2c_available} bank_type={bank_type} />
            <div className="after_select">
              <Button htmlType="submit" type="primary">CHECK OUT</Button>
            </div>
          </Form>

          <Modal
            title="Important Notice"
            style={{ top: 20 }}
            width={720}
            visible={bookingConfirmModal}
            closable={false}
            footer={[
              <Button key="no" onClick={() => this.onCloseModal('bookingConfirmModal')}>
                No
              </Button>,
              <Button key="yes" type="primary" loading={confirmLoading} /* disabled={confirmCheck['checkbox1'] == true && confirmCheck['checkbox2'] == true ? false : true} */ onClick={this.submitForm} disabled={this.state.confirm_button_disabled}  >
                Yes
              </Button>,
            ]}
          >
            <h3><strong>Attention</strong></h3>

            <p>Agreed and confirm to submit this booking transaction.</p>
          </Modal>

          <Modal
            title={<React.Fragment><FontAwesomeIcon fixedWidth icon="question-circle" />&nbsp; More</React.Fragment>}
            closable={false}
            visible={this.state.moreInfoModal}
            footer={[
              <Button type="primary" key="close" onClick={() => this.onCloseModal('moreInfoModal')}>
                Close
              </Button>,
            ]}
          >
            <p>
              <ReactMarkdown source={this.state.moreInfoModalcontent} escapeHtml={false} />
            </p>
          </Modal>

          <Modal
            visible={bookingErrorModal}
            title={null}
            footer={null}
            closable={false}
            destroyOnClose={() => this.onCloseModal('bookingErrorModal')}
          >
            <Empty
              image={warning}
              imageStyle={{
                height: 100,
              }}
              description={
                <p className="popup_text">{bookingErrorMessage}</p>
              }
              className="popup_footer"
            >
              <Button type="primary" onClick={() => this.onCloseModal('bookingErrorModal')}>Close</Button>
            </Empty>
          </Modal>
        </div>
      </div>
    );
  }
}

const LandTourShowPage = Form.create({ name: 'land_tour' })(LeftPanel);
export default withRouter(LandTourShowPage);
